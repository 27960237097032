import React from 'react';
import '../styles/SaleStatusBadge.css';

function SaleStatusBadge(props) {
    const text = props.text;
    const marginRight = props.marginRight;
    return (
        <div className={"sale-status-badge-outer-container " + (marginRight === true ? "subtext-margin-right-large" : "")}>
            <span className="label-semibold-caps colour-white">
                {text}
            </span>
        </div>
    )
};

export default SaleStatusBadge;