import React from 'react';
import { StyleSheet, Document, Page, Text, Image, View } from '@react-pdf/renderer';
import { colour, formatterLong, percentage } from '../styles/GlobalStyles';
import { SingleFamily, MultiFamilyBlack, Units, Bedrooms, Bathrooms, SQFootage, Construction, PriceSqFoot, LotSize, AirConditioning, Heating, Parking } from '../assets';

function PropertyPDF(props) {
    const property = props.property;
    const propertySearch = props.propertySearch;
    const partner = props.partner;
    const highLevelProfits = props.highLevelProfits;
    
    const arvPrice = property.arvComps === true ? property.arvCompsStats.arvPrice : null;
    const purchasePrice = property.price;
    const arvSpread = property.arvComps === true ? (arvPrice - purchasePrice) / purchasePrice : null;

    const icons = [
		{
			icon: property.propertyTypeDimension === "Single Family" ? SingleFamily : property.propertyTypeDimension === "Multi Family" ? MultiFamilyBlack : "",
			text: property.propertyTypeDimension === "Single Family" ? "Single Family" : property.propertyTypeDimension === "Multi Family" ? "Multi-family" : property.propertyTypeDimension === "Townhouse" ? "Townhouse" : property.propertyTypeDimension === "Condo" ? "Condo" : property.propertyTypeDimension === "Manufactured" ? "Manufactured Home" : "",
		},
		{
			icon: property.propertyTypeDimension !== "Multi Family" ? null : Units,
			text: property.propertyTypeDimension !== "Multi Family" ? null : `${property.units} Unit`,
		},
		{
			icon: Bedrooms,
			text: `${property.bedrooms} bed`,
		},
		{
			icon: Bathrooms,
			text: `${property.bathrooms} bath`,
		},
		{
			icon: SQFootage,
			text: `${property.livingArea !== null ? property.livingArea.toLocaleString() : "0"} sqft`,
		}
	];

    const leftGrid = [
		{
			icon: Construction,
			text: `Built in ${property.yearBuilt}`
		},
		{
			icon: PriceSqFoot,
			text: `${property.resoFacts.pricePerSqFoot !== null ? formatterLong.format(property.resoFacts.pricePerSqFoot).replace(".00", "") : "$0"}/sqft`,
		},
		{
			icon: LotSize,
			text: `${property.resoFacts.lotSize} lot`
		}
	];

	const rightGrid = [
		{
			icon: Parking,
			text: `${property.resoFacts.garageSpaces === null ? "0" : property.resoFacts.garageSpaces} garage spaces`
		},
		{
			icon: AirConditioning,
			text: `${property.resoFacts.cooling === null ? "None" : Object.keys(property.resoFacts.cooling).length === 0 ? "None" : property.resoFacts.cooling === null ? "None" : property.resoFacts.cooling.join(', ')}`
		},
		{
			icon: Heating,
			text: `${property.resoFacts.heating === null ? "None" : Object.keys(property.resoFacts.heating).length === 0 ? "None" : property.resoFacts.heating !== null ? property.resoFacts.heating.join(', ') : "None"}`,
		},
	];

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.body}>
                    <View style={styles.stickyRoiContainer}>
                        <View style={styles.stickRoiInnerContainer}>
                            <View style={styles.stickyRoiElement}>
                                <Text style={styles.stickyRoiLabel}>
                                    INITIAL COSTS
                                </Text>
                                <Text style={styles.stickyRoiElementRed}>
                                    {formatterLong.format(highLevelProfits.totalCosts).replace(".00", "")}
                                </Text>
                            </View>
                            <View style={styles.stickyRoiElement}>
                                <Text style={styles.stickyRoiLabel}>
                                    MONTHLY PROFIT
                                </Text>
                                <Text style={highLevelProfits.cashOnCash > 5 ? styles.stickyRoiElementGreen : highLevelProfits.cashOnCash < 0 ? styles.stickyRoiElementRed : styles.stickyRoiElementYellow}>
                                    {formatterLong.format(highLevelProfits.monthlyProfit.toFixed(0)).replace(".00", "")}
                                </Text>
                            </View>
                            <View style={styles.stickyRoiElement}>
                                <Text style={styles.stickyRoiLabel}>
                                    CASH ON CASH
                                </Text>
                                <Text style={highLevelProfits.cashOnCash > 5 ? styles.stickyRoiElementGreen : highLevelProfits.cashOnCash < 0 ? styles.stickyRoiElementRed : styles.stickyRoiElementYellow}>
                                    {highLevelProfits.cashOnCash.toFixed(2)}%
                                </Text>
                            </View>
                        </View>
                    </View>
                    {
                        property.imgSrc !== null && property.imgSrc !== undefined ?
                        <View style={styles.propertyImageContainer}>
                            <Image
                                style={styles.featuredImage}
                                src={property.imgSrc}
                            />
                        </View>
                        :
                        null
                    }
                    <View style={styles.column}>
                        <View style={styles.marginLarge}>
                            <Text style={styles.headingLargeSemiBold}>{formatterLong.format(property.price).replace(".00", "")}</Text>
                        </View>
                        {
                            property.arvComps === true && ((property.fixerUpper === true && propertySearch === false) || propertySearch === true || partner === true) && property.propertyTypeDimension === "Single Family" ?
                            <View style={styles.arvOuterContainer}>
                                <View style={styles.whiteBadge}>
                                    <Text style={styles.bodySemiboldMarginRight}>
                                        ARV
                                    </Text>
                                    <Text style={styles.bodyRegular}>
                                        {formatterLong.format(arvPrice).replace(".00", "")}
                                    </Text>
                                </View>
                                <View style={arvSpread >= 0 ? styles.greenBadge : styles.redBadge}>
                                    <Text style={styles.bodySemiboldMarginRight}>
                                        ARV Spread
                                    </Text>
                                    <Text style={styles.bodyRegular}>
                                        {percentage.format(arvSpread)}
                                    </Text>
                                </View>
                            </View>
                            :
                            null
                        }
                        <View style={styles.marginLarge}>
                            <Text style={styles.headingSmallSemibold}>
                                {property.address.streetAddress}, {property.address.city}, {property.address.state} {property.address.zipcode}
                            </Text>
                        </View>
                        <View style={styles.iconsOuterContainer}>
                            {
                                icons.map((item, index) =>
                                    <View
                                        style={styles.iconsInnerContainer}
                                        key={index}
                                    >
                                        {/* <Image
                                            style={styles.icon}
                                            src={item.icon}
                                        /> */}
                                        <Text>
                                            {item.text}
                                        </Text>
                                    </View>
                                )
                            }
                        </View>
                        <View style={styles.propertyDetailsContainer}>
                            <Text style={styles.headingSmallSemibold}>
                                Property
                            </Text>
                            <View style={styles.iconsGrid}>
                                <View style={styles.widthHalf}>
                                    {
                                        leftGrid.map((item, index) =>
                                            <View 
                                                style={styles.propertyDetailsElement}
                                                key={index}
                                            >
                                                {/* <Image
                                                    src={item.icon}
                                                    style={styles.icon}
                                                /> */}
                                                <Text style={styles.bodyRegular}>
                                                    {item.text}
                                                </Text>
                                            </View>
                                        )
                                    }
                                </View>
                                <View style={styles.widthHalf}>
                                    {
                                        rightGrid.map((item, index) =>
                                            <View 
                                                style={styles.propertyDetailsElement}
                                                key={index}
                                            >
                                                <Text style={styles.bodyRegular}>
                                                    {item.text}
                                                </Text>
                                            </View>
                                        )
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};

// Font.register({ family: 'Rubik', src: "http://fonts.gstatic.com/s/rubik/v4/2AfMVb-218AAzRWsLqegwg.ttf" });
  
const styles = StyleSheet.create({
    stickyRoiContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: 'rgba(255, 254, 252, 0.7)'
    },
    stickRoiInnerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        padding: '8px'
    },
    stickyRoiElement: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginRight: '16px',
        marginTop: '0px',
        marginBottom: '0px'
    },
    stickyRoiLabel: {
        // // fontFamily: 'Rubik',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        marginBottom: '8px',
        color: colour.textPrimary
    },
    stickyRoiElementRed: {
        // fontFamily: 'Rubik',
        fontSize: 28,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px',
        color: colour.redRed02
    },
    stickyRoiElementGreen: {
        // fontFamily: 'Rubik',
        fontSize: 28,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px',
        color: colour.greenGreen01
    },
    stickyRoiElementYellow: {
        // fontFamily: 'Rubik',
        fontSize: 28,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px',
        color: colour.yellowYellow01
    },
    body: {
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        position: 'relative'
    },
    propertyImageContainer: {
        width: '100%',
        marginBottom: '24px'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '90%',
        margin: '0px auto'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    marginRightXSmall: {
        marginRight: '4px'
    },
    marginRight: {
        marginRight: '8px'
    },
    marginRightMedium: {
        marginRight: '16px'
    },
    featuredImage: {
        width: '100%',
        maxWidth: '500px',
    },
    headingLargeSemiBold: {
        // fontFamily: 'Rubik',
        fontSize: 28,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px'
    },
    headingLargeRegular: {
        // fontFamily: 'Rubik',
        fontSize: 28,
        fontStyle: 'normal',
        fontWeight: 400,
        margin: '0px'
    },
    headingSmallSemibold: {
        // fontFamily: 'Rubik',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px'
    },
    bodySemibold: {
        // fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px'
    },
    bodyRegular: {
        // fontFamily: 'Rubik',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        margin: '0px',
        color: colour.textPrimary
    },
    labelSemibold: {
        // fontFamily: 'Rubik',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px'
    },
    labelRegular: {
        // fontFamily: 'Rubik',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 400,
        margin: '0px'
    },
    marginXXSmall: {
        marginBottom: '4px'
    },
    marginXSmall: {
        marginBottom: '8px'
    },
    marginMedium: {
        marginBottom: '16px'
    },
    marginLarge: {
        marginBottom: '24px'
    },
    marginXLarge: {
        marginBottom: '32px'
    },
    bodySemiboldMarginRight: {
        // fontFamily: 'Rubik',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '0px',
        marginRight: '8px'
    },
    whiteBadge: {
        padding: '5px 6px',
        borderRadius: '3px',
        marginRight: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: colour.grayScaleWhite,
    },
    greenBadge: {
        padding: '5px 6px',
        borderRadius: '3px',
        marginRight: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: colour.greenGreen01
    },
    redBadge: {
        padding: '5px 6px',
        borderRadius: '3px',
        marginRight: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: colour.redRed01
    },
    whiteBg: {
        backgroundColor: colour.grayScaleWhite,
    },
    greenBg: {
        backgroundColor: colour.greenGreen01
    },
    redBg: {
        backgroundColor: colour.redRed01
    },
    separator: {
        borderTop: `0.5px solid ${colour.grayScaleGray02}`
    },
    paddingLarge: {
        padding: '48px 0px'
    },
    paddingLargeBottom: {
        paddingBottom: '24px'
    },
    colorPrimary: {
        color: colour.textPrimary
    },
    colorSecondary: {
        color: colour.textSecondary
    },
    widthFull: {
        width: '100%'
    },
    arvOuterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '24px'
    },
    iconsOuterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '24px'
    },
    iconsInnerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: '8px'
    },
    propertyDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '48px 0px',
        borderTop: `0.5px solid ${colour.grayScaleGray02}`,
        width: '100%'
    },
    iconsGrid: {
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%'
    },
    widthHalf: {
        width: '50%'
    },
    propertyDetailsElement: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '24px'
    },
    icon: {
        width: '16px',
        marginRight: '8px'
    }
});

export default PropertyPDF;