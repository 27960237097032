import React from 'react';
import '../styles/NeighbourhoodData.css';
import { neighbourhoodColours, neighbourhoodValues } from '../styles/GlobalStyles';

function NeighbourhoodData(props) {
    const neighbourhoodData = props.neighbourhoodData;
    const property = props.property;
    const neighbourhoodIndex = neighbourhoodData.findIndex(e => e.region.tractId === property.censusTract);
    const chosenTract = neighbourhoodIndex !== -1 ? neighbourhoodData[neighbourhoodIndex] : null;

    const moreInfo = () => {
        window.open("https://coffeeclozers.notion.site/Coffee-Clozers-Maps-1541acce14b642ee8791b94c6c1cfccf", "_blank");
    };

    const checkNeighbourhoodGrade = (grade) => {
        const text = grade === 8 ? "A Neighborhood" :
        grade === 7 ? "B+ Neighborhood" :
        grade === 6 ? "B Neighborhood" :
        grade === 5 ? "B- Neighborhood" :
        grade === 4 ? "C+ Neighborhood" :
        grade === 3 ? "C Neighborhood" :
        grade === 2 ? "C- Neighborhood" :
         "D Neighborhood";
        return text;
    };

    const styles = {
        first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    return (
        chosenTract !== null ?
        <div className="neighbourhood-data-outer-container">
            <div className="neighbourhood-data-inner-container">
                <div className="neighbourhood-score-element-container margin-x-large relative-container">
                    <div className="neighbourhood-score-title-container margin-large">
                        <h2 className="heading-small-semibold colour-primary margin-x-small">
                            Neighborhood Score
                        </h2>
                        <span className="body-regular colour-secondary">
                            The Neighborhood Score is based on a combination of micro and macro economic factors, 
                            amenities, and other market data. <span onClick={() => moreInfo()} className="underline colour-link">More info</span>
                        </span>
                    </div>
                    {
                        chosenTract.neighborhoodGrade !== undefined ?
                        <div 
                            className="neighbourhood-data-badge margin-medium"
                            style={
                                chosenTract.neighborhoodGrade === 8 ? styles.first :
                                chosenTract.neighborhoodGrade === 7 ? styles.second :
                                chosenTract.neighborhoodGrade === 6 ? styles.third :
                                chosenTract.neighborhoodGrade === 5 ? styles.fourth :
                                chosenTract.neighborhoodGrade === 4 ? styles.fifth :
                                chosenTract.neighborhoodGrade === 3 ? styles.sixth :
                                chosenTract.neighborhoodGrade === 2 ? styles.seventh :
                                styles.eighth
                            }
                        >
                            <span className={"body-regular " + (chosenTract.neighborhoodGrade === 8 ? "colour-primary" : "colour-white")}>
                                {neighbourhoodValues[chosenTract.neighborhoodGrade - 1].label}
                            </span>
                        </div>
                        :
                        property.neighborhoodGradeCensus !== undefined && property.neighborhoodGradeCensus !== null ?
                        <div 
                            className="neighbourhood-data-badge margin-medium"
                            style={
                                property.neighborhoodGradeCensus === 8 ? styles.first :
                                property.neighborhoodGradeCensus === 7 ? styles.second :
                                property.neighborhoodGradeCensus === 6 ? styles.third :
                                property.neighborhoodGradeCensus === 5 ? styles.fourth :
                                property.neighborhoodGradeCensus === 4 ? styles.fifth :
                                property.neighborhoodGradeCensus === 3 ? styles.sixth :
                                property.neighborhoodGradeCensus === 2 ? styles.seventh :
                                styles.eighth
                            }
                        >
                            <span className="body-regular colour-white">
                                {checkNeighbourhoodGrade(property.neighborhoodGradeCensus)}
                            </span>
                        </div>
                        :
                        <div 
                            className="neighbourhood-data-badge margin-medium blurred"
                            style={styles.fourth}
                        >
                            <span className="body-regular colour-white">
                                B- Neighborhood
                            </span>
                        </div>
                    }
                    {/* {
                        chosenTract.neighborhoodGrade !== undefined && chosenTract.neighborhoodGrade !== null ?
                        <span className="body-regular colour-primary block-text">
                            {neighbourhoodValues[chosenTract.neighborhoodGrade - 1].description}
                        </span>
                        :
                        property.neighborhoodGradeCensus !== undefined && property.neighborhoodGradeCensus !== null ?
                        <span className="body-regular colour-primary block-text">
                            {neighbourhoodValues[property.neighborhoodGradeCensus - 1].description}
                        </span>
                        :
                        <span className="body-regular colour-primary block-text blurred">
                            👨‍👩‍👧‍👦 Good tenants, maintained homes
                        </span>
                    } */}
                </div>
                {/* <div className="neighbourhood-data-row-container margin-large">
                    {
                        topLevelStats.map((item, index) =>
                            <div 
                                className="neighbourhood-data-beige-element"
                                key={index}
                            >
                                <div className="neighbourhood-data-beige-inner-element">
                                    <span className="label-regular colour-primary block-text margin-x-x-small">
                                        {item.label}
                                    </span>
                                    <span className="body-semibold colour-primary">
                                        {item.value}
                                    </span>
                                </div>
                            </div>   
                        )
                    }
                </div>
                <div className="neighbourhood-data-economics-container">
                    <h3 className="body-semibold colour-primary margin-x-small">
                        Economics
                    </h3>
                    <span className="body-regular colour-secondary block-text margin-large">
                        How this neighborhood compares to the rest of the state.
                    </span>
                    <div className="neighbourhood-data-economics-grid-container margin-large">
                        {
                            economicsVals.map((item, index) =>
                                <div 
                                    className="economics-vals-element-container"
                                    key={index}
                                >
                                    <div className="neighbourhood-data-grid-element-title-row">
                                        <span className="label-regular colour-primary block-text margin-x-x-small">
                                            {item.label}
                                        </span>
                                        {
                                            item.tooltip === true ?
                                            <div 
                                                className="neighbourhood-data-tooltip-icon-container relative-container"
                                                onMouseEnter={() => setEconomicsTooltipOpen(index)}
                                                onMouseLeave={() => setEconomicsTooltipOpen(null)}
                                            >
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="16" 
                                                    height="16" 
                                                    viewBox="0 0 16 16" 
                                                    fill="none"
                                                    className="neighbourhood-data-tooltip-icon"
                                                >
                                                    <path 
                                                        d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                        fill="#716E69"
                                                    />
                                                    <path 
                                                        d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                        fill="#716E69"
                                                    />
                                                    <path 
                                                        fillRule="evenodd" 
                                                        clipRule="evenodd" 
                                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                        fill="#716E69"
                                                    />
                                                </svg>
                                                {
                                                    economicsTooltipOpen === index ?
                                                    <SidePanelTooltip
                                                        title={item.title}
                                                        description={item.description}
                                                        link={item.link}
                                                        index={index}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <span className="body-semibold colour-primary block-text margin-x-small">
                                        {item.value}
                                    </span>
                                    <div className={"economics-vals-comparison-container " + (item.percentage === true && item.difference < 0.01 ? "bg-colour-medium" : item.positive === true ? "bg-colour-green-6" : "bg-colour-red-3")}>
                                        <div className="economics-vals-inner-comparison-container">
                                            {
                                                item.percentage === true && item.difference < 0.01 ?
                                                null
                                                :
                                                <div className={"economics-vals-comparison-icon-container subtext-margin-right " + (item.reverse === true ? "reverse" : "")}>
                                                    {
                                                        item.positive === false ?
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="8" 
                                                            height="8" 
                                                            viewBox="0 0 8 8" 
                                                            fill="none"
                                                        >
                                                            <path 
                                                                d="M4.36274 7.84168L7.80272 4.4017C8.01381 4.19061 8.01381 3.84835 7.80272 3.63726C7.59162 3.42617 7.24937 3.42617 7.03828 3.63726L4.52106 6.15448L4.52106 0.540541C4.52106 0.242008 4.27905 0 3.98052 0C3.68199 0 3.43998 0.242008 3.43998 0.540541L3.43998 6.15448L0.922761 3.63726C0.711666 3.42617 0.369415 3.42617 0.158321 3.63726C-0.0527736 3.84836 -0.0527735 4.19061 0.158321 4.4017L3.5983 7.84168C3.80939 8.05277 4.15165 8.05277 4.36274 7.84168Z" 
                                                                fill="#CC564B"
                                                            />
                                                        </svg>
                                                        :
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="8" 
                                                            height="8" 
                                                            viewBox="0 0 8 8" 
                                                            fill="none"
                                                        >
                                                            <path 
                                                                d="M4.36274 0.15832L7.80272 3.5983C8.01381 3.80939 8.01381 4.15165 7.80272 4.36274C7.59162 4.57383 7.24937 4.57383 7.03828 4.36274L4.52106 1.84552L4.52106 7.45946C4.52106 7.75799 4.27905 8 3.98052 8C3.68199 8 3.43998 7.75799 3.43998 7.45946L3.43998 1.84552L0.922761 4.36274C0.711666 4.57383 0.369415 4.57383 0.158321 4.36274C-0.0527736 4.15164 -0.0527735 3.80939 0.158321 3.5983L3.5983 0.15832C3.80939 -0.0527735 4.15165 -0.0527735 4.36274 0.15832Z" 
                                                                fill="#1C8557"
                                                            />
                                                        </svg>
                                                    }
                                                </div>
                                            }
                                            <span className={"label-semibold " + (item.percentage === true && item.difference < 0.01 ? "colour-primary" : item.positive === true ? "colour-green-7" : "colour-error")}>
                                                {
                                                    item.percentage === true && item.difference < 0.01 ?
                                                    `About the same as state average`
                                                    :
                                                    `${calcPercentage(item)} ${item.positive === true && item.reverse === false ? "above" : item.positive === false && item.reverse === false ? "below" : item.positive === true && item.reverse === true ? "below" : "above"} state average`
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="neighbourhood-data-market-container">
                    <h3 className="body-semibold colour-primary margin-x-small">
                        Market
                    </h3>
                    <span className="body-regular colour-secondary block-text margin-large">
                        Stats on this specific neighborhood.
                    </span>
                    <div className="neighbourhood-data-grid-container margin-large">
                        {
                            hasUndefinedNeighborhoodGrade === true ?
                            <FakeNeighbourhoodData
                                type="market"
                                property={property}
                            />
                            :
                            marketVals.map((item, index) =>
                                <div 
                                    className="neighbourhood-data-grid-element"
                                    key={index}
                                >
                                    <div className="neighbourhood-data-grid-element-title-row">
                                        <span className="label-regular colour-primary block-text margin-x-x-small">
                                            {item.label}
                                        </span>
                                        {
                                            item.tooltip === true ?
                                            <div 
                                                className="neighbourhood-data-tooltip-icon-container relative-container"
                                                onMouseEnter={() => setMarketTooltipOpen(index)}
                                                onMouseLeave={() => setMarketTooltipOpen(null)}
                                            >
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="16" 
                                                    height="16" 
                                                    viewBox="0 0 16 16" 
                                                    fill="none"
                                                    className="neighbourhood-data-tooltip-icon"
                                                >
                                                    <path 
                                                        d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                        fill="#716E69"
                                                    />
                                                    <path 
                                                        d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                        fill="#716E69"
                                                    />
                                                    <path 
                                                        fillRule="evenodd" 
                                                        clipRule="evenodd" 
                                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                        fill="#716E69"
                                                    />
                                                </svg>
                                                {
                                                    marketTooltipOpen === index ?
                                                    <SidePanelTooltip
                                                        title={item.title}
                                                        description={item.description}
                                                        link={item.link}
                                                        index={index}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <span className="body-semibold colour-primary">
                                        {item.value}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    {
                        hasUndefinedNeighborhoodGrade === true ?
                        <div className="neighbourhood-data-upgrade-button">
                            <ActionButton
                                onClick={() => upgradeCity()}
                            >
                                <div className="neighbourhood-data-upgrade-lock-container subtext-margin-right">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="16" 
                                        height="16" 
                                        viewBox="0 0 16 16" 
                                        fill="none"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M3.14387 5.70336C3.19648 2.11915 5.44553 4.65982e-07 7.99966 0C10.5538 -5.26632e-07 12.8028 2.11915 12.8554 5.70336H13.7891C14.0023 5.70336 14.1751 5.87624 14.1751 6.08949V14.6164C14.1751 15.3805 13.5559 16 12.7921 16H3.20726C2.44343 16 1.82422 15.3805 1.82422 14.6164V6.08949C1.82422 5.87624 1.99704 5.70336 2.21018 5.70336H3.14387ZM5.20257 5.70336C5.25051 3.05864 6.77638 2.05933 7.99966 2.05933C9.22293 2.05933 10.7488 3.05864 10.7967 5.70336H5.20257ZM8.80645 10.9138C9.15545 10.6626 9.3827 10.2528 9.3827 9.78984C9.3827 9.02569 8.76348 8.40623 7.99966 8.40623C7.23583 8.40623 6.61662 9.02569 6.61662 9.78984C6.61662 10.2396 6.83112 10.6392 7.16334 10.8919L6.90914 12.9764C6.89042 13.1298 7.01006 13.265 7.16453 13.265H8.80262C8.95709 13.265 9.07674 13.1298 9.05801 12.9764L8.80645 10.9138Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                </div>
                                Unlock {property.address.city}, {property.address.state}
                            </ActionButton>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="neighbourhood-data-metrics-container">
                    <h3 className="body-semibold colour-primary margin-x-small">
                        Metrics
                    </h3>
                    <span className="body-regular colour-secondary block-text margin-large">
                        Property performance metrics in this neighborhood.
                    </span>
                    <div className="neighbourhood-data-grid-container margin-large">
                        {
                            hasUndefinedNeighborhoodGrade === true ?
                            <FakeNeighbourhoodData
                                type="metrics"
                                property={property}
                            />
                            :
                            metricsVals.map((item, index) =>
                                <div 
                                    className="neighbourhood-data-grid-element"
                                    key={index}
                                >
                                    <div className="neighbourhood-data-grid-element-title-row">
                                        <span className="label-regular colour-primary block-text margin-x-x-small">
                                            {item.label}
                                        </span>
                                        {
                                            item.tooltip === true ?
                                            <div 
                                                className="neighbourhood-data-tooltip-icon-container relative-container"
                                                onMouseEnter={() => setMetricsTooltipOpen(index)}
                                                onMouseLeave={() => setMetricsTooltipOpen(null)}
                                            >
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="16" 
                                                    height="16" 
                                                    viewBox="0 0 16 16" 
                                                    fill="none"
                                                    className="neighbourhood-data-tooltip-icon"
                                                >
                                                    <path 
                                                        d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                        fill="#716E69"
                                                    />
                                                    <path 
                                                        d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                        fill="#716E69"
                                                    />
                                                    <path 
                                                        fillRule="evenodd" 
                                                        clipRule="evenodd" 
                                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                        fill="#716E69"
                                                    />
                                                </svg>
                                                {
                                                    metricsTooltipOpen === index ?
                                                    <SidePanelTooltip
                                                        title={item.title}
                                                        description={item.description}
                                                        link={item.link}
                                                        index={index}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <span className="body-semibold colour-primary">
                                        {item.value}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    {
                        hasUndefinedNeighborhoodGrade === true ?
                        <div className="neighbourhood-data-upgrade-button">
                            <ActionButton
                                onClick={() => upgradeCity()}
                            >
                                <div className="neighbourhood-data-upgrade-lock-container subtext-margin-right">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="16" 
                                        height="16" 
                                        viewBox="0 0 16 16" 
                                        fill="none"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M3.14387 5.70336C3.19648 2.11915 5.44553 4.65982e-07 7.99966 0C10.5538 -5.26632e-07 12.8028 2.11915 12.8554 5.70336H13.7891C14.0023 5.70336 14.1751 5.87624 14.1751 6.08949V14.6164C14.1751 15.3805 13.5559 16 12.7921 16H3.20726C2.44343 16 1.82422 15.3805 1.82422 14.6164V6.08949C1.82422 5.87624 1.99704 5.70336 2.21018 5.70336H3.14387ZM5.20257 5.70336C5.25051 3.05864 6.77638 2.05933 7.99966 2.05933C9.22293 2.05933 10.7488 3.05864 10.7967 5.70336H5.20257ZM8.80645 10.9138C9.15545 10.6626 9.3827 10.2528 9.3827 9.78984C9.3827 9.02569 8.76348 8.40623 7.99966 8.40623C7.23583 8.40623 6.61662 9.02569 6.61662 9.78984C6.61662 10.2396 6.83112 10.6392 7.16334 10.8919L6.90914 12.9764C6.89042 13.1298 7.01006 13.265 7.16453 13.265H8.80262C8.95709 13.265 9.07674 13.1298 9.05801 12.9764L8.80645 10.9138Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                </div>
                                Unlock {property.address.city}, {property.address.state}
                            </ActionButton>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="neighbourhood-data-property-attributes-container">
                    <h3 className="body-semibold colour-primary margin-x-small">
                        Property attributes
                    </h3>
                    <span className="body-regular colour-secondary block-text margin-large">
                        What the average property looks like in this neighborhood.
                    </span>
                    <div className="neighbourhood-data-grid-container margin-large">
                        {
                            hasUndefinedNeighborhoodGrade === true ?
                            <FakeNeighbourhoodData
                                type="attributes"
                                property={property}
                            />
                            :
                            propertyAttributes.map((item, index) =>
                                <div 
                                    className="neighbourhood-data-grid-element"
                                    key={index}
                                >
                                    <div className="neighbourhood-data-grid-element-title-row">
                                        <span className="label-regular colour-primary block-text margin-x-x-small">
                                            {item.label}
                                        </span>
                                        {
                                            item.tooltip === true ?
                                            <div 
                                                className="neighbourhood-data-tooltip-icon-container relative-container"
                                                onMouseEnter={() => setPropertyAttributesTooltipOpen(index)}
                                                onMouseLeave={() => setPropertyAttributesTooltipOpen(null)}
                                            >
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="16" 
                                                    height="16" 
                                                    viewBox="0 0 16 16" 
                                                    fill="none"
                                                    className="neighbourhood-data-tooltip-icon"
                                                >
                                                    <path 
                                                        d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" 
                                                        fill="#716E69"
                                                    />
                                                    <path 
                                                        d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" 
                                                        fill="#716E69"
                                                    />
                                                    <path 
                                                        fillRule="evenodd" 
                                                        clipRule="evenodd" 
                                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" 
                                                        fill="#716E69"
                                                    />
                                                </svg>
                                                {
                                                    propertyAttributesTooltipOpen === index ?
                                                    <SidePanelTooltip
                                                        title={item.title}
                                                        description={item.description}
                                                        link={item.link}
                                                        index={index}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <span className="body-semibold colour-primary">
                                        {item.value}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    {
                        hasUndefinedNeighborhoodGrade === true ?
                        <div className="neighbourhood-data-upgrade-button">
                            <ActionButton
                                onClick={() => upgradeCity()}
                            >
                                <div className="neighbourhood-data-upgrade-lock-container subtext-margin-right">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="16" 
                                        height="16" 
                                        viewBox="0 0 16 16" 
                                        fill="none"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            clipRule="evenodd" 
                                            d="M3.14387 5.70336C3.19648 2.11915 5.44553 4.65982e-07 7.99966 0C10.5538 -5.26632e-07 12.8028 2.11915 12.8554 5.70336H13.7891C14.0023 5.70336 14.1751 5.87624 14.1751 6.08949V14.6164C14.1751 15.3805 13.5559 16 12.7921 16H3.20726C2.44343 16 1.82422 15.3805 1.82422 14.6164V6.08949C1.82422 5.87624 1.99704 5.70336 2.21018 5.70336H3.14387ZM5.20257 5.70336C5.25051 3.05864 6.77638 2.05933 7.99966 2.05933C9.22293 2.05933 10.7488 3.05864 10.7967 5.70336H5.20257ZM8.80645 10.9138C9.15545 10.6626 9.3827 10.2528 9.3827 9.78984C9.3827 9.02569 8.76348 8.40623 7.99966 8.40623C7.23583 8.40623 6.61662 9.02569 6.61662 9.78984C6.61662 10.2396 6.83112 10.6392 7.16334 10.8919L6.90914 12.9764C6.89042 13.1298 7.01006 13.265 7.16453 13.265H8.80262C8.95709 13.265 9.07674 13.1298 9.05801 12.9764L8.80645 10.9138Z" 
                                            fill="#FFFEFC"
                                        />
                                    </svg>
                                </div>
                                Unlock {property.address.city}, {property.address.state}
                            </ActionButton>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="neighbourhood-data-divider-line margin-tops">
                </div> */}
            </div>
        </div>
        :
        null
    )
};

export default NeighbourhoodData;