import React from 'react';
import '../styles/DeleteCardModal.css';
import { ActionButton, WhiteActionButton } from '../styles/GlobalStyles';
import { NewExit } from '../assets';
import { recordEvent } from '../functions';

function CancelSubscriptionModal(props) {
    const setCancelSubscriptionModal = props.setCancelSubscriptionModal;
    const cancelSubscription = props.cancelSubscription;
    const loading = props.loading;

    const closeModal = () => {
        setCancelSubscriptionModal(false);
        recordEvent("Close Cancel Subscription Modal", {});
    };

    return (
        <div className="delete-card-modal-outer-container bg-colour-light">
            <div className="delete-card-modal-inner-container">
                <div className="delete-card-modal-exit-container">
                    <img
                        src={NewExit}
                        className="delete-card-modal-exit"
                        alt="Close modal"
                        onClick={() => closeModal()}
                    />
                </div>
                <h2 className="heading-large-semibold margin-large">
                    Are you sure?
                </h2>
                <span className="body-regular colour-primary block-text margin-large">
                    Are you sure you want to cancel 
                    your subscription?
                </span>
                <div className="delete-card-modal-body-container margin-x-x-large">
                    
                </div>
                <div className="delete-card-modal-buttons-container">
                    <div className="delete-card-modal-white-button">
                        <WhiteActionButton
                            onClick={() => closeModal()}
                            disabled={loading}
                        >
                            Cancel
                        </WhiteActionButton>
                    </div>
                    <div className="delete-card-modal-action-button">
                        <ActionButton
                            onClick={() => cancelSubscription()}
                            disabled={loading}
                        >
                            Delete
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelSubscriptionModal;