import height from './height';
import width from './width';
import where from './where';
import fixerUpper from './fixerUpper';
import specificLocation from './specificLocation';
import paymentType from './paymentType';
import downPayment from './downPayment';
import interestRate from './interestRate';
import budgetRange from './budgetRange';
import loanType from './loanType';
import email from './email';
import password from './password';
import firstName from './firstName';
import lastName from './lastName';
import localLocation from './localLocation';
import coordinates from './coordinates';
import pennyThoughts from './pennyThoughts';
import properties from './properties';
import filterList from './filterList';
import propertyRange from './propertyRange';
import allProperties from './allProperties';
import logoClick from './logoClick';
import allUserDetails from './allUserDetails';
import percentage from './percentage';
import locations from './locations';
import favouritedProperties from './favouritedProperties';
import propertyTypes from './propertyTypes';
import profit from './profit';
import purchaseCosts from './purchaseCosts';
import editedProperty from './editedProperty';
import marketData from './marketData';
import originalRecurringCosts from './originalRecurringCosts';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    height: height,
	width: width,
	where: where,
	fixerUpper: fixerUpper,
	specificLocation: specificLocation,
	paymentType: paymentType,
	downPayment: downPayment,
	interestRate: interestRate,
	budgetRange: budgetRange,
	loanType: loanType,
	email: email,
	password: password,
	firstName: firstName,
	lastName: lastName,
	localLocation: localLocation,
	coordinates: coordinates,
	pennyThoughts: pennyThoughts,
	properties: properties,
	filterList: filterList,
	propertyRange: propertyRange,
	allProperties: allProperties,
	logoClick: logoClick,
	allUserDetails: allUserDetails,
	percentage: percentage,
	favouritedProperties: favouritedProperties,
	locations: locations,
	propertyTypes: propertyTypes,
	profit: profit,
	purchaseCosts: purchaseCosts,
	editedProperty: editedProperty,
	marketData: marketData,
	originalRecurringCosts: originalRecurringCosts
});

export default allReducers