import React from 'react';
import '../styles/DeleteModal.css';
import { ActionButton, WhiteActionButton } from '../styles/GlobalStyles';
import { NewExit } from '../assets';
import { recordEvent } from '../functions';

function DeleteModal(props) {
    const deleteFunction = props.deleteFunction;
    const setDeleteModal = props.setDeleteModal;
    const description = props.description;
    const deleteParam = props.deleteParam;

    const closeModal = () => {
        setDeleteModal(false);
        recordEvent("Close Delete Listing Modal", {});
    };

    return (
        <div className="delete-modal-outer-container bg-colour-light">
            <div className="delete-modal-inner-container">
                <div className="delete-modal-exit-container">
                    <img
                        src={NewExit}
                        className="delete-modal-exit"
                        alt="Close modal"
                        onClick={() => closeModal()}
                    />
                </div>
                <h2 className="heading-large-semibold margin-large">
                    Are you sure?
                </h2>
                <span className="body-regular colour-primary block-text margin-large">
                    {description}
                </span>
                <div className="delete-modal-buttons-container">
                    <div className="delete-modal-white-button">
                        <WhiteActionButton
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </WhiteActionButton>
                    </div>
                    <div className="delete-modal-action-button">
                        <ActionButton
                            onClick={() => deleteParam === null ? deleteFunction() : deleteFunction(deleteParam)}
                        >
                            Delete
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal;