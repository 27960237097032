import React from 'react';
import '../styles/EditStrategy.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomRadio } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';

function EditStrategy(props) {
    const strategy = props.strategy;
    const onChangeStrategy = props.onChangeStrategy;
    const strategies = props.strategies;

    return (
        <div className="edit-strategies-outer-container">
            <div className="edit-strategies-inner-container">
                <div className="edit-strategies-title-container margin-large">
                    <span className="body-semibold colour-primary">
                        Strategy
                    </span>
                </div>
                <FormControl className="">
                    <RadioGroup
                        aria-labelledby="fixer-upper-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={strategy}
                        className="profile-buy-box-radio-column"
                        onChange={(val) => onChangeStrategy(val.target.value)}
                    >
                    {
                        strategies.map((item, index) =>
                            <FormControlLabel 
                                value={item.value} 
                                control={<CustomRadio />} 
                                className={index !== 4 ? "margin-large" : ""}
                                label={
                                    <div className="edit-strategies-label-container">
                                        <h3 className="body-semibold colour-primary">
                                            {item.title}
                                        </h3>
                                        <p className="body-regular colour-secondary">
                                            {item.description}
                                        </p>
                                    </div>
                                }
                                key={index}
                            />
                        )}
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    )
};

export default EditStrategy;