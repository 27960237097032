import React, { useEffect } from 'react';
import '../styles/Legal.css';
import { Header, Footer } from '../components';

function TermsAndConditions() {

	useEffect(() => {
		document.title = "Coffee Clozers | Terms & Conditions";
	});

	return (
		<div className="legal-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
			/>
			<div className="legal-inner-container">
				<h1 className="display-xl-semibold colour-primary">
					Terms &amp; Conditions
				</h1>
				<div className="legal-text-container">
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							1. Introduction
						</h2>
						<p className="body-regular colour-primary">
							These Terms and Conditions (the "Terms") govern your use of the <b>Coffee Clozers Inc.</b> software as a service (SaaS) product (the "Service"). By using the Service, you agree to be bound by these Terms.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">2. Definitions</h2>
						<ul>
							<li className="body-regular colour-primary">
								<strong>Account</strong> means a user account created by you for the purpose of using the Service.
							</li>
							<li className="body-regular colour-primary">
								<strong>Content</strong> means any data, information, text, images, or other materials that you upload, post, or otherwise make available through the Service.
							</li>
							<li className="body-regular colour-primary">
								<strong>Intellectual Property Rights</strong> means all patents, copyrights, trademarks, trade secrets, and other intellectual property rights of any kind.
							</li>
							<li className="body-regular colour-primary">
								<strong>Service</strong> means the <b>Coffee Clozers Inc.</b> SaaS product, including all software, data, and documentation made available by us to you through the Service.
							</li>
							<li className="body-regular colour-primary">
								<strong>We</strong> means <b>Coffee Clozers Inc.</b>, the provider of the Service.
							</li>
							<li className="body-regular colour-primary">
								<strong>You</strong> means the individual or entity that is using the Service.
							</li>
						</ul>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							3. Grant of License
						</h2>
						<p className="body-regular colour-primary">
							We grant you a non-exclusive, non-transferable, revocable license to use the Service for your own internal business purposes. You may not modify, copy, distribute, or sell the Service or any of its underlying Intellectual Property Rights.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							4. Your Responsibilities
						</h2>
						<p className="body-regular colour-primary">
							You are responsible for all Content that you upload, post, or otherwise make available through the Service. You represent and warrant that you own or have the necessary rights to all Content that you make available through the Service. You also agree to comply with all applicable laws and regulations when using the Service.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							5. Our Responsibilities
						</h2>
						<p className="body-regular colour-primary margin-medium">
							We will use reasonable efforts to make the Service available to you 24 hours a day, 7 days a week. However, we may need to take the Service down for maintenance or other reasons. We will notify you of any planned downtime in advance, if possible.
						</p>
						<p className="body-regular colour-primary">
							We are not responsible for any loss or damage caused by your use of the Service. We also make no warranties or representations about the accuracy or completeness of the Content that is made available through the Service.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							6. SMS Terms
						</h2>
						<p className="body-regular colour-primary">
							By agreeing to these Terms, you consent to receive a one-time text message from Coffee Clozers Inc. via A2P 10DLC to the phone number you provided during signup or registration. This message will contain an activation code.
							<br/>
							<br/>
							You can stop receiving messages at any time by replying "CANCEL", "END", "QUIT", "UNSUBSCRIBE", "STOP", or "STOPALL" to the message.
							<br/>
							<br/>
							Standard message and data rates may apply.
							<br/>
							<br/>
							Please note: You are not required to consent to receive this message as a condition of using our services. However, opting out will prevent you from receiving the activation code and completing your signup process.
							<br/>
							<br/>
							We will treat your personal information with the utmost respect and will not share it with third parties without your consent.
							<br/>
							<br/>
							This consent is governed by the terms of this Agreement and our Privacy Policy.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							7. Termination
						</h2>
						<p className="body-regular colour-primary">
							We may terminate your access to the Service at any time for any reason, including if you violate these Terms. You may also terminate your access to the Service by deleting your Account.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							8. Governing Law
						</h2>
						<p className="body-regular colour-primary">
							These Terms will be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of laws provisions.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							9. Entire Agreement
						</h2>
						<p className="body-regular colour-primary">
							These Terms constitute the entire agreement between you and us regarding the use of the Service. These Terms supersede any prior or contemporaneous communications, representations, or agreements, whether oral or written.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							10. Severability
						</h2>
						<p className="body-regular colour-primary">
							If any provision of these Terms is held to be invalid or unenforceable, such provision will be struck from these Terms and the remaining provisions will remain in full force and effect.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							11. Waiver
						</h2>
						<p className="body-regular colour-primary">
							Our failure to enforce any provision of these Terms will not constitute a waiver of such provision.
						</p>
					</div>
					<div className="legal-text-element">
						<h2 className="heading-large-semibold colour-primary margin-medium">
							12. Governing Language
						</h2>
						<p className="body-regular colour-primary">
							These Terms will be interpreted in the English language.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default TermsAndConditions;