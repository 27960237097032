import React from 'react';
import '../styles/CompsFilter.css';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';

function CompsFilter(props) {
    const title = props.title;
    const value = props.value;
    const changeValue = props.changeValue;
    const sliderVal = props.sliderVal;
    const step = props.step;
    const min = props.min;
    const max = props.max;

    return (
        <div className="comps-filter-outer-container">
            <div className="comps-filter-inner-container">
                <div className="comps-filter-title-row margin-x-x-small">
                    <span className="body-regular colour-primary block-text subtext-margin-right">
                        {title}
                    </span>
                    <span className="body-semibold colour-primary">
                        {value}
                    </span>
                </div>
                <div className="comps-filter-slider-container">
                    <Stack 
                        spacing={2} 
                        direction="row" 
                        sx={{ mb: 1, px: 1 }} 
                        alignItems="center"
                    >
                        <Slider
                            aria-label="Filter"
                            value={sliderVal}
                            onChange={changeValue}
                            step={step}
                            min={min}
                            max={max}
                            disabled={false}
                            sx={{
                                color: '#CFBCAD',
                                height: '5px',
                                '& .MuiSlider-track': {
                                    border: 'none',
                                },
                                '& .MuiSlider-thumb': {
                                    width: 24,
                                    height: 24,
                                    backgroundColor: '#fff',
                                    '&:before': {
                                        boxShadow: '0px 0px 10px 0px #99959180',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                        boxShadow: 'none',
                                    },
                                }
                            }}
                        />
                    </Stack>
                </div>
            </div>
        </div>
    )
};

export default CompsFilter;