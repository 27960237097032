import React from 'react';
import '../styles/PriceCutBadge.css';
import { formatterCompact } from '../styles/GlobalStyles';
import moment from 'moment';
import { DownArrowWhite, UpArrowWhite } from '../assets';

function PriceCutBadge(props) {
    const priceDrop = props.priceDrop;
    const priceChangeTotalAmount = props.priceChangeTotalAmount;
    const priceChangeLatestDate = props.priceChangeLatestDate;

    const todayDate = moment().format("YYYY-MM-DD");
    let priceChangeDateChecked;

    if ( priceChangeLatestDate === todayDate ) {
        priceChangeDateChecked = "Today";
    }
    else {
        priceChangeDateChecked = moment(priceChangeLatestDate).format("MMM DD");
    }

    return (
        <div className="price-cut-outer-container">
            <div className="price-cut-container">
                <span className="label-semibold-caps colour-white block-text subtext-margin-right">
                    PRICE {
                        priceDrop === true ?
                        "CUT"
                        :
                        "GAIN"
                    }
                </span>
                <div className="price-change-icon-container">
                    <img
                        src={
                                priceDrop === true ?
                                DownArrowWhite
                                :
                                UpArrowWhite
                            }
                        className="price-cut-icon"
                        alt="Price Change"
                    />
                </div>
                <div className="price-change-text-container">
                    <span className="label-semibold block-text subtext-margin-right-small colour-white">
                        {formatterCompact.format(Math.abs(priceChangeTotalAmount)).replace(".00", "")}
                    </span>
                    <span className="label-regular colour-white">
                        {priceChangeDateChecked}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PriceCutBadge;