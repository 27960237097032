import React from 'react';
import '../styles/PendingTag.css';

function PendingTag() {
    return (
        <div className="pending-tag-container">
            <span className="label-semibold-caps colour-primary">
                PENDING
            </span>
        </div>
    )
}

export default PendingTag;