export const widthAction = (width) => {
	return {
		type: 'width',
		payload: width
	}
}

export const heightAction = (height) => {
	return {
		type: 'height',
		payload: height
	}
}

export const whereAction = (where) => {
	return {
		type: 'where',
		payload: where
	}
}

export const fixerUpperAction = (fixerUpper) => {
	return {
		type: 'fixerUpper',
		payload: fixerUpper
	}
}

export const specificLocationAction = (specificLocation) => {
	return {
		type: 'specificLocation',
		payload: specificLocation
	}
}

export const paymentTypeAction = (paymentType) => {
	return {
		type: 'paymentType',
		payload: paymentType
	}
}

export const downPaymentAction = (downPayment) => {
	return {
		type: 'downPayment',
		payload: downPayment
	}
}

export const interestRateAction = (interestRate) => {
	return {
		type: 'interestRate',
		payload: interestRate
	}
}

export const budgetRangeAction = (budgetRange) => {
	return {
		type: 'budgetRange',
		payload: budgetRange
	}
}

export const loanTypeAction = (loanType) => {
	return {
		type: 'loanType',
		payload: loanType
	}
}

export const emailAction = (email) => {
	return {
		type: 'email',
		payload: email
	}
}

export const passwordAction = (password) => {
	return {
		type: 'password',
		payload: password
	}
}

export const firstNameAction = (firstName) => {
	return {
		type: 'firstName',
		payload: firstName
	}
}

export const lastNameAction = (lastName) => {
	return {
		type: 'lastName',
		payload: lastName
	}
}

export const localLocationAction = (localLocation) => {
	return {
		type: 'localLocation',
		payload: localLocation
	}
}

export const coordinatesAction = (coordinates) => {
	return {
		type: 'coordinates',
		payload: coordinates
	}
}

export const pennyThoughtsAction = (pennyThoughts) => {
	return {
		type: 'pennyThoughts',
		payload: pennyThoughts
	}
}

export const propertiesAction = (properties) => {
	return {
		type: 'properties',
		payload: properties
	}
}

export const filterListAction = (filterList) => {
	return {
		type: 'filterList',
		payload: filterList
	}
}

export const propertyRangeAction = (propertyRange) => {
	return {
		type: 'propertyRange',
		payload: propertyRange
	}
}

export const allPropertiesAction = (allProperties) => {
	return {
		type: 'allProperties',
		payload: allProperties
	}
}

export const logoClickAction = (logoClick) => {
	return {
		type: 'logoClick',
		payload: logoClick
	}
}

export const allUserDetailsAction = (allUserDetails) => {
	return {
		type: 'allUserDetails',
		payload: allUserDetails
	}
}

export const percentageAction = (percentage) => {
	return {
		type: 'percentage',
		payload: percentage
	}
}

export const favouritedPropertiesAction = (favouritedProperties) => {
	return {
		type: 'favouritedProperties',
		payload: favouritedProperties
	}
}

export const locationsAction = (locations) => {
	return {
		type: 'locations',
		payload: locations
	}
}

export const propertyTypesAction = (propertyTypes) => {
	return {
		type: 'propertyTypes',
		payload: propertyTypes
	}
}

export const profitAction = (profit) => {
	return {
		type: 'profit',
		payload: profit
	}
}

export const purchaseCostsAction = (purchaseCosts) => {
	return {
		type: 'purchaseCosts',
		payload: purchaseCosts
	}
}

export const editedPropertyAction = (editedProperty) => {
	return {
		type: 'editedProperty',
		payload: editedProperty
	}
}

export const marketDataAction = (marketData) => {
	return {
		type: 'marketData',
		payload: marketData
	}
}

export const originalRecurringCostsAction = (originalRecurringCosts) => {
	return {
		type: 'originalRecurringCosts',
		payload: originalRecurringCosts
	}
}