import React from 'react';
import '../styles/ExitScreen.css';

function ExitScreen(props) {
    const clickScreen = props.clickScreen;
    return (
        <div 
            className="exit-screen-container"
            onClick={() => clickScreen()}
        >
        </div>
    );
};

export default ExitScreen;