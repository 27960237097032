import React, { useEffect, useState } from 'react';
import '../styles/InteractiveMap.css';
import { GoogleMap, useLoadScript, OverlayView, Polygon, OverlayViewF } from '@react-google-maps/api';
import CoffeeIcon from '../assets/coffee-icon.svg';
import { colour, mapColours, neighbourhoodColours } from '../styles/GlobalStyles';
import { MapLegend } from './';

function LocationMap(props) {
	const property = props.property;
	const controls = props.controls;
	const neighbourhoodBounds = props.neighbourhoodBounds;
	const neighbourhoodData = props.neighbourhoodData;
	const neighbourhoodOption = props.neighbourhoodOption;
	const neighbourhoodDataOptions = props.neighbourhoodDataOptions;
	const dataLocation = neighbourhoodOption === 0 ? null : neighbourhoodDataOptions[neighbourhoodOption].location;
    const dataSubLocation = neighbourhoodOption === 0 ? null : neighbourhoodDataOptions[neighbourhoodOption].subLocation;
	const [hoverMapLegend, setHoverMapLegend] = useState(null);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: JSON.parse(process.env.REACT_APP_GOOGLE).key
	});

	const [centre] = useState({ lat: property.latitude, lng: property.longitude });
	const [renderOverlay, setRenderOverlay] = useState(false);

	const options = {
		disableDefaultUI: controls,
		scrollwheel: false,
		mapTypeControl: false,
		streetViewControl: false
	}

	useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}
	}, [renderOverlay]);

	const styles = {
		inactive: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		inactiveHover: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

	const rgbaToHex = (r, g, b, a) => {
        // Convert the RGB to a hexadecimal value
        const rHex = r.toString(16).padStart(2, '0');
        const gHex = g.toString(16).padStart(2, '0');
        const bHex = b.toString(16).padStart(2, '0');
    
        // Convert the alpha value to a hexadecimal value
        const aHex = Math.round(a * 255).toString(16).padStart(2, '0');
    
        // Combine the RGB and alpha values to get the hexadecimal color code
        const hexCode = `#${rHex}${gHex}${bHex}${aHex}`;
    
        return hexCode.toUpperCase(); // Convert to uppercase for consistency
    };

	const generateColor = (darkYellow, lightYellow, percentage) => {
        percentage = Math.max(0, Math.min(1, percentage));
      
        const deltaRed = lightYellow.red - darkYellow.red;
        const deltaGreen = lightYellow.green - darkYellow.green;
        const deltaBlue = lightYellow.blue - darkYellow.blue;
      
        const red = Math.round(darkYellow.red + (deltaRed * percentage));
        const green = Math.round(darkYellow.green + (deltaGreen * percentage));
        const blue = Math.round(darkYellow.blue + (deltaBlue * percentage));
        const getHex = rgbaToHex(red, green, blue, 0.7);
      
        return getHex;
    };

	const checkNeighbourhoodStyles = (index) => {
		if ( hoverMapLegend !== null ) {
            const tractValue = neighbourhoodData[index].neighborhoodGrade;
            const gradeTranslation = [
                8,
                7,
                6,
                5,
                4,
                3,
                2,
                1
            ];

            if ( tractValue === gradeTranslation[hoverMapLegend] ) {
                const newStyle = {
                    fillColor: neighbourhoodColours[hoverMapLegend],
                    fillOpacity: 1,
                    strokeColor: colour.blueBlue02,
                    strokeWeight: 3,
                    zIndex: 2
                }
                
                return newStyle;
            }
            else {
                const newStyle = {
                    fillColor: styles.inactive.fillColor,
                    fillOpacity: 1,
                    strokeColor: '#FFFFFF',
                    strokeWeight: 1,
                    zIndex: 1
                }
                
                return newStyle;
            }
        }
        else if ( neighbourhoodOption === 0 ) {
            const tractValue = neighbourhoodData[index].neighborhoodGrade;
			const getHex = tractValue === 8 ? styles.first.backgroundColor 
			:
			tractValue === 7 ? styles.second.backgroundColor
			:
			tractValue === 6 ? styles.third.backgroundColor
			:
			tractValue === 5 ? styles.fourth.backgroundColor
			:
			tractValue === 4 ? styles.fifth.backgroundColor
			:
			tractValue === 3 ? styles.sixth.backgroundColor
			:
			tractValue === 2 ? styles.seventh.backgroundColor
			:
			styles.eighth.backgroundColor;
			
			const newStyle = {
				fillColor: getHex,
				fillOpacity: 1,
				strokeColor: '#FFFFFF',
				strokeWeight: 1,
				zIndex: 1
			}
			
			return newStyle;
        }
        else {
            const allValues = [];
            for (let i = 0; i < neighbourhoodData.length; i++) {
				if ( neighbourhoodData[i].stats !== undefined ) {
					const element = neighbourhoodData[i].stats[dataLocation];
					if ( element !== null ) {
						const subElement = element[dataSubLocation];
						if ( subElement !== null ) {
							allValues.push(subElement);
						}
					}
				}
            }

			if ( neighbourhoodData[index].stats !== undefined ) {
				const tractElement = neighbourhoodData[index].stats[dataLocation];
				if ( tractElement === null ) {
					return styles.inactive
				}
				else if ( tractElement[dataSubLocation] === null ) {
					return styles.inactive
				}
				else {
					const tractValue = tractElement[dataSubLocation];
					const minVal = Math.min(...allValues);
					const maxVal = Math.max(...allValues);
					const range = maxVal - minVal;
					const distanceFromMin = tractValue - minVal;
					if ( range === 0 ) {
						const newStyle = {
							fillColor: styles.inactive,
							fillOpacity: 1,
							strokeColor: '#FFFFFF',
							strokeWeight: 1,
							zIndex: 1
						}
						
						return newStyle;
					};
					const percentage = (distanceFromMin / range);
					const darkYellow = colour.heatmapHot;
					const lightYellow = colour.heatmapCold;
					const getHex = generateColor(lightYellow, darkYellow, percentage);
				
					const newStyle = {
						fillColor: getHex,
						fillOpacity: 1,
						strokeColor: '#FFFFFF',
						strokeWeight: 1,
						zIndex: 1
					}
					
					return newStyle;
				}
			}
        }
	};

	return (
		<div className="property-location-map-container relative-container">
			<MapLegend
				hoverMapLegend={hoverMapLegend}
				setHoverMapLegend={setHoverMapLegend}
				neighbourhoodOption={neighbourhoodOption}
			/>
			{
				!isLoaded ? "Loading"
				:
				<GoogleMap
					zoom={11}
					center={centre}
					mapContainerClassName="location-map-container"
					options={options}
				>
					{
						renderOverlay === true ?
						neighbourhoodBounds.map((item, index) =>
							<Polygon
								editable={false}
								paths={item}
								options={checkNeighbourhoodStyles(index)}
								key={index}
							/>
						)
						:
						null
					}
					{
						renderOverlay === true ?
							<OverlayViewF
								position={{ lat: property.latitude, lng: property.longitude }}
								mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
							>
								<div 
									className="location-map-overlay"
								>
									<span>
										<img
											src={CoffeeIcon}
											className="interactive-map-coffee-icon"
											alt="Coffee"
										/> 
									</span>
								</div>
							</OverlayViewF>	
						:
						null
					}
				</GoogleMap>
			}
		</div>
	)
}

export default LocationMap;