import React from 'react'
import '../styles/SignUpModal.css';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../styles/GlobalStyles';
import { EmptyCup, Exit } from '../assets';

function SignUpModal(props) {
    const navigate = useNavigate();
    const setSignUpModal = props.setSignUpModal;

    const cta = () => {
        navigate('/sign-up');
    };

    return (
        <div className="sign-up-modal-outer-container">
            <div className="sign-up-modal-inner-container">
                <div
                    className="sign-up-modal-exit-container"
                    onClick={() => setSignUpModal(false)}
                >
                    <img
                        src={Exit}
                        className="sign-up-modal-exit"
                        alt="Close Modal"
                    />
                </div>
                <img
                    src={EmptyCup}
                    className="sign-up-modal-image"
                    alt="Empty Cup"
                />
                <div className="sign-up-modal-text-container">
                    <h2 className="heading-large-semibold colour-primary text-align-center margin-x-x-large">
                        You're not signed up 😔
                    </h2>
                    <span className="label-regular colour-primary block-text margin-x-x-large text-align-center">
                        You'll need to create an account to do what you just did (there's several actions that require signing up and I couldn't be bothered to write 8 different IF statements here with the exact action that you just took, so I don't know what you just clicked on but whatever it was it's one of the sign-up ones.)
                    </span>
                    <h4 className="body-semibold colour-primary text-align-center margin-medium">
                        Ready to keep browsing?
                    </h4>
                    <div className="sign-up-modal-button-container">
                        <ActionButton
                            onClick={() => cta()}
                        >
                            Sign up now
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpModal;