import React from 'react';
import '../styles/TopPickBadge.css';
import { BadgeExplanation }  from './';
import { topPickText } from '../styles/GlobalStyles';

function TopPickBadge() {
	return (
		<div className="top-pick-badge-outer-container">
			<BadgeExplanation
				title="Top Picks"
				description={topPickText}
			/>
			<span className="top-pick-badge-text">
				🔥 Top Pick
			</span>
		</div>
	)
}

export default TopPickBadge;