import React from 'react';
import '../styles/InfoSquare.css';
import { LoadingStatic } from './';

function InfoSquare(props) {
    const label = props.label;
    const value = props.value;
    const subLabel = props.subLabel;
    const loadingBuyBoxes = props.loadingBuyBoxes;
    const item = props.item;
    const id = item.id;
    const loadingIndex = loadingBuyBoxes.indexOf(id);

    return (
        <div className="info-square-container bg-colour-light">
            <div className="info-square-inner-container">
                <h3 className="label-regular-caps colour-secondary margin-x-x-small">
                    {label}
                </h3>
                {
                    loadingIndex !== -1 ?
                    <LoadingStatic />
                    :
                    <h4 className="heading-large-regular colour-primary margin-x-x-small">
                        {value}
                    </h4>
                }
                <span className="label-regular colour-tertiary">
                    {subLabel}
                </span>
            </div>
        </div>
    )
};

export default InfoSquare;