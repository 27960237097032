import React, { Fragment, useState } from 'react';
import '../styles/SFHRentHouseHack.css';
import { FinancialsInput, FinancialsInputNoLabel } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeDot } from '../assets';
import { HUDTooltip, RentAccordion } from './';

function SFHRentHouseHack(props) {
    const houseHackBedroomsRent = props.houseHackBedroomsRent;
    const bedroomLiving = props.bedroomLiving;
    const blurred = props.blurred;
    const onChangeBedroomLiving = props.onChangeBedroomLiving;
    const onChangeRent = props.onChangeRent;
    const rent = props.rent;
    const defaultRent = props.defaultRent;
    const onChangeBedroomRent = props.onChangeBedroomRent;
    const resetRentEstimates = props.resetRentEstimates;
    const property = props.property;
    const [houseHackTooltip, setHouseHackTooltip] = useState(false);
    const [rentAccordion, setRentAccordion] = useState(false);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };

    const clickBedroom = (index, e) => {
        const target = e.target;
        const targetClass = target.classList.value;
        const parentTargetClass = target.parentNode.classList.value;

        if ( targetClass.includes("Mui") || parentTargetClass.includes("Mui") ) {
            return;
        }
        else {
            onChangeBedroomLiving(index);
        }
    };

    return (
        <div className="sfh-rent-house-hack-outer-container">
            <div className="sfh-rent-house-hack-inner-container margin-medium">
                <div className="sfh-rent-house-hack-title-container margin-medium">
                    <span className="body-semibold colour-primary">
                        Which room do you plan to live in?
                    </span>
                </div>
                <div className="sfh-rent-house-hack-grid margin-large">
                    {
                        houseHackBedroomsRent.map((item, index) =>
                            <div 
                                className={"sfh-rent-house-hack-element " + (index === bedroomLiving ? "sfh-rent-house-hack-element-selected" : "")}
                                key={index}
                                onClick={(e) => clickBedroom(index, e)}
                            >
                                <div className="sfh-rent-house-hack-inner-element">
                                    <div className="sfh-rent-house-hack-icon-container">
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="34" 
                                            height="32" 
                                            viewBox="0 0 34 32" 
                                            fill="none"
                                        >
                                            <path 
                                                d="M3.44038 3.79682C3.44038 2.2991 4.65452 1.08496 6.15224 1.08496H27.8472C29.3449 1.08496 30.559 2.2991 30.559 3.79683V12.7955C30.0613 12.5076 29.5135 12.2965 28.9319 12.1784V8.67818C28.9319 7.77955 28.2034 7.05106 27.3048 7.05106H19.1692C18.2706 7.05106 17.5421 7.77955 17.5421 8.67818V12.0699H16.4573V8.67818C16.4573 7.77955 15.7288 7.05106 14.8302 7.05106H6.69462C5.79598 7.05106 5.0675 7.77955 5.0675 8.67818V12.1784C4.48593 12.2965 3.93815 12.5076 3.44038 12.7955V3.79682Z" 
                                                fill="#292621"
                                            />
                                            <path 
                                                d="M6.15224 14.1019C3.1568 14.1019 0.728516 16.5302 0.728516 19.5256V21.6951C0.728516 21.7494 0.729314 21.8036 0.730899 21.8575C0.729315 21.8936 0.728516 21.9298 0.728516 21.9663V28.4748C0.728516 29.8227 1.82124 30.9155 3.16919 30.9155C4.51714 30.9155 5.60987 29.8227 5.60987 28.4748V27.0921C5.78826 27.1098 5.96919 27.1189 6.15225 27.1189H27.8472C28.0302 27.1189 28.2111 27.1098 28.3895 27.0921V28.4748C28.3895 29.8227 29.4823 30.9155 30.8302 30.9155C32.1782 30.9155 33.2709 29.8227 33.2709 28.4748V21.9663C33.2709 21.9298 33.2701 21.8936 33.2685 21.8575C33.2701 21.8036 33.2709 21.7494 33.2709 21.6951V19.5256C33.2709 16.5302 30.8426 14.1019 27.8472 14.1019H6.15224Z" 
                                                fill="#292621"
                                            />
                                        </svg>
                                    </div>
                                    <div className="sfh-rent-house-hack-text-container">
                                        <span className="body-semibold colour-primary block-text margin-x-small">
                                            {item.label}
                                        </span>
                                        <FinancialsInput
                                            value={blurred === true ? "999" : index === bedroomLiving ? 0 : item.value}
                                            type="number"
                                            label={
                                                Number(rent) !== Number(defaultRent) ?
                                                <Fragment>
                                                    {item.label}
                                                    <img
                                                        src={ChangeDot}
                                                        className="financials-panel-change-dot"
                                                        alt="Change"
                                                    />
                                                </Fragment>
                                                :
                                                item.label
                                            }
                                            id={`${item.label}-input`}
                                            placeholder={`${item.value}`}
                                            onWheel={(e) => e.target.blur()}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            disabled={blurred === true ? true : index === bedroomLiving ? true : false}
                                            onChange={(text) => onChangeBedroomRent(text.target.value, index)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="sfh-rent-house-hack-total-container margin-x-large">
                    <div 
                        className="sfh-rent-house-hack-total-label-container margin-x-small"
                    >
                        <span className="body-semibold colour-primary subtext-margin-right">
                            Total rent
                        </span>
                        <div 
                            className="sfh-rent-house-hack-tooltip-container relative-container"
                            onMouseEnter={() => setHouseHackTooltip(true)}
                            onMouseLeave={() => setHouseHackTooltip(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                                <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                            </svg>
                            {
                                houseHackTooltip === true ?
                                <HUDTooltip />
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="button-row strategies-panel-financials-input">
                            <FinancialsInputNoLabel
                                value={blurred === true ? "999" : rent}
                                type="number"
                                label={
                                    Number(rent) !== Number(defaultRent) ?
                                    <Fragment>
                                        <img
                                            src={ChangeDot}
                                            className="financials-panel-change-dot"
                                            alt="Change"
                                        />
                                    </Fragment>
                                    :
                                    null
                                }
                                id={`rent-input`}
                                placeholder={`${rent}`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                disabled={blurred}
                                onChange={(text) => onChangeRent(text.target.value)}
                            />
                        </div>
                        {
                            blurred === false ?
                            <div 
                                className={"new-financials-costs-recurring-costs-reset-container " + (Number(rent) !== Number(defaultRent) ? "" : "view-none")}
                                onClick={() => resetRentEstimates()}
                            >
                                <span className="label-semibold colour-link">
                                    Reset
                                </span>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <RentAccordion
                rentAccordion={rentAccordion}
                handleRentAccordion={handleRentAccordion}
                property={property}
            />
        </div>
    )
};

export default SFHRentHouseHack;