import React from 'react';
import '../styles/ResetButton.css';
import { resetMap } from '../functions';

function ResetButton(props) {
    const allProperties = props.allProperties;
    const setProperties = props.setProperties;
    const setMapProperties = props.setMapProperties;
    const sorting = props.sorting;
    const setPage = props.setPage;
    const setLoading = props.setLoading;
    const setSearchParams = props.setSearchParams;
    const setShowResetButton = props.setShowResetButton;
    const strategy = props.strategy;
    return (
        <div 
            className="reset-button-outer-container"
            onClick={() => resetMap(allProperties, setProperties, setMapProperties, sorting, setPage, setLoading, setSearchParams, setShowResetButton, strategy)}
        >
            <span className="label-semibold-caps text-link">
                Reset
            </span>
        </div>
    )
}

export default ResetButton;