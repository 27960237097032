import React from 'react';
import '../styles/NeighbourhoodBadge.css';
import { neighbourhoodColours, neighbourhoodValues } from '../styles/GlobalStyles';

function NeighbourhoodBadge(props) {
    const item = props.item;
    const neighbourhoodData = props.neighbourhoodData;
    const neighbourhoodIndex = neighbourhoodData.findIndex(e => e.region.tractId === item.censusTract);
    const chosenTract = neighbourhoodIndex === -1 ? null : neighbourhoodData[neighbourhoodIndex];

    const styles = {
        first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    return (
        chosenTract === null ? null :
        <div 
            className="neighbourhood-badge-container"
            style={
                chosenTract.neighborhoodGrade === 8 ? styles.first :
                chosenTract.neighborhoodGrade === 7 ? styles.second :
                chosenTract.neighborhoodGrade === 6 ? styles.third :
                chosenTract.neighborhoodGrade === 5 ? styles.fourth :
                chosenTract.neighborhoodGrade === 4 ? styles.fifth :
                chosenTract.neighborhoodGrade === 3 ? styles.sixth :
                chosenTract.neighborhoodGrade === 2 ? styles.seventh :
                styles.eighth
            }
        >
            <span className={"body-regular " + (chosenTract.neighborhoodGrade === 8 ? "colour-primary" : "colour-white")}>
                {neighbourhoodValues[chosenTract.neighborhoodGrade - 1].label}
            </span>
        </div>
    )
}

export default NeighbourhoodBadge;