import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

function PlaceInput(props) {
    const googleAPIKey = JSON.parse(process.env.REACT_APP_GOOGLE).key;
    const placeholder = props.placeholder;
    const localLocation = props.localLocation;
    const changeLocation = props.changeLocation;
    const selectedLocation = props.selectedLocation;
    const types = props.types;
    const disabled = props.disabled;

    const reloadPage = (err) => {
		console.log("LOAD ERROR = ", err);
		window.open(`${window.location.href}`,"_self");
	}

    return (
        <GooglePlacesAutocomplete
            apiKey={googleAPIKey}
            autocompletionRequest={{
                componentRestrictions: {
                    country: ['us']
                },
                types: types
            }}
            onLoadFailed={(err) => reloadPage(err)}
            selectProps={{
                placeholder: placeholder,
                inputValue: localLocation,
                onInputChange: (text) => changeLocation(text),
                onChange: (place) => selectedLocation(place),
                isClearable: true,
                isDisabled: disabled,
                id: "google-places-input",
                components : {
                    IndicatorSeparator: () => null,
                        DropdownIndicator: () => null
                },
                styles: {
                    control: base => ({
                        ...base,
                        backgroundColor: '#FFFEFC',
                        borderRadius: '3px',
                        border: '0.5px solid #BCCAD4',
                        boxShadow: '0px 0px 8px 0px rgba(152, 151, 147, 0.40)',
                        borderColor: '#BCCAD4 !important'
                    }),
                    input: (provided) => ({
                        ...provided,
                        "input:focus": {
                            boxShadow: "none",
                            border: "none"
                        },
                    })
                },
            }}
        />
    )
}

export default PlaceInput;