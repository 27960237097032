import React from 'react';
import '../styles/Selector.css';

function Selector(props) {
    const options = props.options;
    const selectOption = props.selectOption;
    return (
        <div className="selector-outer-container">
            <div className="selector-inner-container">
                {
                    options.map((item, index) =>
                        <div
                            className={"selector-option-element-container " + (item.selected === true ? "selector-option-element-selected" : "selector-option-element")}
                            key={index}
                            onClick={() => selectOption(index)}
                        >
                            <span className={(item.selected === true ? "body-semibold colour-white" : "body-regular colour-primary")}>
                                {item.text}
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default Selector;