import React from 'react';
import '../styles/ChooseStrategy.css';

function ChooseStrategy(props) {
    const strategies = props.strategies;
    const strategy = props.strategy;
    const setStrategy = props.setStrategy;

    const onChangeStrategy = (val) => {
        setStrategy(val);
    }

    return (
        <div className="choose-strategy-outer-container">
            <div className="choose-strategy-inner-container">
                {
                    strategies.map((item, index) =>
                        <div 
                            className={"choose-strategy-element bg-colour-white cursor-pointer " + (item.value === strategy ? "choose-strategy-element-selected" : "") + (index !== 3 ? " margin-medium" : "")}
                            key={index}
                            onClick={() => onChangeStrategy(item.value)}
                        >
                            <div className="choose-strategy-inner-element">
                                <h2 className="body-semibold colour-primary margin-x-small">
                                    {item.title}
                                </h2>
                                <span className="label-regular colour-secondary">
                                    {item.description}
                                </span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default ChooseStrategy;