import React from 'react';
import '../styles/FixerUpperBadge.css';
import { FixerUpper } from '../assets';

function FixerUpperBadge() {
	return (
		<div className="fixer-upper-badge-outer-container">
			<img
				src={FixerUpper}
				className="fixer-upper-badge-icon"
				alt="Fixer Upper"
			/>
			<span className="top-pick-badge-text">
				Fixer Upper
			</span>
		</div>
	)
}

export default FixerUpperBadge;