import React from 'react';
import '../styles/ReportLoading.css';
import { LandingImage } from '../assets/images';
import { GreyTick, GreenTick } from '../assets';

function ReportLoading(props) {
    const step = props.step;
    const steps = [
        {
            preIcon: GreyTick,
            pre: "Property details...",
            post: "Property details gathered",
            postIcon: GreenTick
        },
        {
            preIcon: GreyTick,
            pre: "Comps...",
            post: "Comps gathered",
            postIcon: GreenTick
        },
        {
            preIcon: GreyTick,
            pre: "Neighborhood data...",
            post: "Neighborhood data gathered",
            postIcon: GreenTick
        },
        {
            preIcon: GreyTick,
            pre: "Rent & ARV estimates...",
            post: "Rent & ARV estimates gathered",
            postIcon: GreenTick
        }
    ];

    return (
        <div className="report-loading-outer-container">
            <div className="report-loading-inner-container">
                <div className="report-loading-image-container margin-large">
                    <img
                        src={LandingImage}
                        className="report-loading-image"
                        alt="Generating a report"
                    />
                </div>
                <div className="report-loading-title-container margin-medium">
                    <h2 className="heading-large-semibold colour-primary text-align-center">
                        Generating report...
                    </h2>
                </div>
                <div className="report-loading-steps-container">
                    {
                        steps.map((item, index) =>
                            <div 
                                className="report-loading-step-element margin-medium"
                                key={index}
                            >
                                <img
                                    src={(index + 1) <= step ? item.postIcon : item.preIcon}
                                    className="report-loading-icon"
                                    alt="Step icon"
                                />
                                <span className={"body-regular " + ((index + 1) <= step ? "colour-primary" : "colour-secondary")}>
                                    {
                                        (index + 1) <= step ? item.post : item.pre
                                    }
                                </span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
};

export default ReportLoading;