import React, { Fragment, useState } from 'react';
import '../styles/MFHRentOneUnitType.css';
import { FinancialsInput, FinancialsInputNoLabel } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeDot } from '../assets';
import { DefaultTooltip, RentAccordion } from '../components';

function MFHRentOneUnitType(props) {
    const rent = props.rent;
    const defaultRent = props.defaultRent;
    const blurred = props.blurred;
    const onChangeRent = props.onChangeRent;
    const units = props.units;
    const onChangeUnitRent = props.onChangeUnitRent;
    const resetRentEstimates = props.resetRentEstimates;
    const property = props.property;
    const [rentTooltip, setRentTooltip] = useState(false);
    const [rentAccordion, setRentAccordion] = useState(false);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };

    return (
        <div className="mfh-rent-one-unit-outer-container">
            <div className="mfh-rent-one-unit-inner-container margin-medium">
                <div className="mfh-rent-one-unit-icon-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none">
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M1.42857 32C0.639593 32 0 31.3488 0 30.5455V1.45455C0 0.651223 0.639593 0 1.42857 0H28.5714C29.3604 0 30 0.651222 30 1.45455V30.5455C30 31.3488 29.3604 32 28.5714 32H1.42857ZM1.42857 1.45455H28.5714V30.5455H24.2857V13.8182C24.2857 8.59658 20.1284 4.36364 15 4.36364C9.87164 4.36364 5.71429 8.59658 5.71429 13.8182V30.5455H1.42857L1.42857 1.45455ZM10.3958 8.26929C11.489 7.29214 12.9152 6.65846 14.4912 6.54545V10.1781C13.9443 10.2669 13.4467 10.4962 13.0395 10.8264L10.3958 8.26929ZM8.21429 13.0909C8.21429 11.5393 8.77087 10.1135 9.70145 8.99001L12.377 11.578C12.1036 12.0205 11.9465 12.538 11.9465 13.0909L8.21429 13.0909ZM21.7857 13.0909C21.7857 11.5394 21.2292 10.1136 20.2986 8.99013L17.6232 11.578C17.8966 12.0205 18.0537 12.538 18.0537 13.0909L21.7857 13.0909ZM16.9607 10.8264L19.6043 8.2694C18.5112 7.29223 17.085 6.65852 15.509 6.54547V10.1781C16.0559 10.2669 16.5535 10.4962 16.9607 10.8264ZM10.1078 22.6634C9.94883 23.1215 9.51978 23.4496 9.01544 23.4496C8.37573 23.4496 7.85714 22.9216 7.85714 22.2703C7.85714 21.6189 8.37573 21.0909 9.01544 21.0909C9.51978 21.0909 9.94882 21.4191 10.1078 21.8771H11.7181C11.9314 21.8771 12.1042 22.0532 12.1042 22.2703C12.1042 22.4874 11.9314 22.6634 11.7181 22.6634H10.1078Z" 
                            fill="#292621"
                        />
                    </svg>
                </div>
                <div className="mfh-rent-one-unit-body-container">
                    <div className="mfh-rent-one-unit-grid margin-large">
                        {
                            units.map((item, index) =>
                                <div 
                                    className="mfh-rent-one-unit-financials-element"
                                    key={index}
                                >
                                    <FinancialsInput
                                        value={blurred === true ? "999" : item.value}
                                        type="number"
                                        label={
                                            Number(item.value) !== (Math.round(Number(defaultRent) / units.length)) ?
                                            <Fragment>
                                                {item.label}
                                                <img
                                                    src={ChangeDot}
                                                    className="financials-panel-change-dot"
                                                    alt="Change"
                                                />
                                            </Fragment>
                                            :
                                            item.label
                                        }
                                        id={`${item.label}-input`}
                                        placeholder={`${item.value}`}
                                        onWheel={(e) => e.target.blur()}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        disabled={blurred}
                                        onChange={(text) => onChangeUnitRent(text.target.value, index)}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className="mfh-rent-one-unit-total-container">
                        <div className="mfh-rent-one-unit-total-label-container margin-x-small">
                            <span className="body-semibold colour-primary block-text subtext-margin-right">
                                Total rent
                            </span>
                            <div 
                                className="relative-container cursor-pointer"
                                onMouseEnter={() => setRentTooltip(true)}
                                onMouseLeave={() => setRentTooltip(false)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                                    <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                                </svg>
                                {
                                    rentTooltip === true ?
                                    <DefaultTooltip
                                        title={"How do we estimate rent?"}
                                        description={"Our Rent Estimate is an estimated monthly rental price, computed using a proprietary formula. We review local rental comparables and other industry estimates. It is a starting point in determining the monthly rental price for a specific property."}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="button-row">
                            <div className="button-row strategies-panel-financials-input">
                                <FinancialsInputNoLabel
                                    value={blurred === true ? "999" : rent}
                                    type="number"
                                    label={
                                        Number(rent) !== Number(defaultRent) ?
                                        <Fragment>
                                            <img
                                                src={ChangeDot}
                                                className="financials-panel-change-dot"
                                                alt="Change"
                                            />
                                        </Fragment>
                                        :
                                        null
                                    }
                                    id={`rent-input`}
                                    placeholder={`${rent}`}
                                    onWheel={(e) => e.target.blur()}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    disabled={blurred}
                                    onChange={(text) => onChangeRent(text.target.value)}
                                />
                            </div>
                            {
                                blurred === false ?
                                <div 
                                    className={"new-financials-costs-recurring-costs-reset-container " + (Number(rent) !== Number(defaultRent) ? "" : "view-none")}
                                    onClick={() => resetRentEstimates()}
                                >
                                    <span className="label-semibold colour-link">
                                        Reset
                                    </span>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <RentAccordion
                rentAccordion={rentAccordion}
                handleRentAccordion={handleRentAccordion}
                property={property}
            />
        </div>
    )
};

export default MFHRentOneUnitType;