import React from 'react';
import '../styles/OffMarketImageContainer.css';
import { OffMarketHouse, RedirectArrowBlue } from '../assets';
import { recordEvent } from '../functions';

function OffMarketImageContainer(props) {
    const property = props.property;
    const googleLink = `https://www.google.com/maps/place/${property.address.streetAddress.replace(" ", "+")},+${property.address.city},+${property.address.state}+${property.address.zipcode}`;

    const openGoogle = () => {
        recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
    };

    return (
        <div className="off-market-image-container">
            <img
                src={OffMarketHouse}
                className="off-market-image-icon"
                alt="Off Market"
            />
            <div 
                className="off-market-image-inner-container"
                onClick={() => openGoogle()}
            >
                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                    No photos available
                </span>
                <div className="off-market-image-inner-row">
                    <span className="body-regular-underline colour-link">
                        Go to street view
                    </span>
                    <img
                        src={RedirectArrowBlue}
                        className="off-market-image-container-arrow"
                        alt="Redirect"
                    />
                </div>
            </div>
        </div>
    )
}

export default OffMarketImageContainer;