import React from 'react';
import '../styles/Loading.css';
import { LoadingAnimation } from '../assets/animations';
import { useLottie } from 'lottie-react';

function Loading() {

    const options = {
        animationData: LoadingAnimation,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div className="loading-container">
            {View}
        </div>
    )
}

export default Loading;