import React from 'react';
import '../styles/NewPropertyCharacteristics.css';

function NewPropertyCharacteristics({ item }) {
	return (
		<div className="new-property-characteristics-outer-container">
			<div className="new-property-characteristics-element-container">
				<span className="body-regular colour-primary">
					{item.bedrooms !== null ? `${item.bedrooms} bed` : null}
					{
						item.bathrooms !== null && item.bedrooms !== null ?
						<span className="new-property-characteristics-spacer">|</span>
						:
						null
					}
					{item.bathrooms !== null ? `${item.bathrooms} bath ` : null}
					{
						item.livingArea === 0 || item.livingArea === null ?
						null
						:
						<span className="new-property-characteristics-spacer">|</span>
					}
					{item.livingArea === 0 || item.livingArea === null ? null : `${item.livingArea} sqft `}
					{
						item.propertyTypeDimension === "Single Family" ? 
						<span className="new-property-characteristics-spacer">|</span>
						:
						item.propertyTypeDimension === "Multi Family" ?
						<span className="new-property-characteristics-spacer">|</span>
						:
						item.propertyTypeDimension === "Townhouse" ?
						<span className="new-property-characteristics-spacer">|</span>
						:
						item.propertyTypeDimension === "Condo" ?
						<span className="new-property-characteristics-spacer">|</span>
						:
						item.propertyTypeDimension === "Manufactured" ?
						<span className="new-property-characteristics-spacer">|</span>
						:
						null
					}
					{item.propertyTypeDimension === "Single Family" ? "SFH" : item.propertyTypeDimension === "Multi Family" ? "MFH" : item.propertyTypeDimension === "Townhouse" ? "TWHS" : item.propertyTypeDimension === "Condo" ? "CNDO" : item.propertyTypeDimension === "Manufactured" ? "MFD" : ""}
					{
						item.propertyTypeDimension === "Multi Family" && item.units !== null && item.units !== undefined ?
						<span className="new-property-characteristics-spacer">|</span>
						:
						null
					}
					{item.propertyTypeDimension === "Multi Family" && item.units !== null && item.units !== undefined ? `${item.units} unit` : null}
				</span>
			</div>
			<div className="new-property-characteristics-address-container">
				<span className="body-regular colour-secondary">
					{item.address.streetAddress}, {item.address.city}, {item.address.state} {item.address.zipcode}
				</span>
			</div>
		</div>
	)
}

export default NewPropertyCharacteristics;