import React from 'react';
import '../styles/UpgradeModal.css';
import { Exit } from '../assets';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../styles/GlobalStyles';
import { recordEvent } from '../functions';

function UpgradeModalQuickReport(props) {
    const setViewUpgradeModal = props.setViewUpgradeModal;
    const title = props.title;
    const description = props.description;
    const navigate = useNavigate();

    const closeModal = () => {
        setViewUpgradeModal(false);
        recordEvent("Upgrade Modal - Close Modal");
    };

    const viewPricing = async() => {
        const chosenPath = "/pricing";
        navigate(chosenPath);
        recordEvent("Upgrade Modal - View Pricing");
        closeModal();
    };

    return (
        <div className="upgrade-modal-outer-container">
            <div className="upgrade-modal-inner-container">
                <div
                    className="upgrade-modal-exit-container"
                    onClick={() => closeModal()}
                >
                    <img
                        src={Exit}
                        className="upgrade-modal-exit"
                        alt="Close Modal"
                    />
                </div>
                <div className="upgrade-modal-body-container">
                    <h2 className="heading-large-semibold colour-primary margin-medium">
                        {title}
                    </h2>
                    <span className="body-regular colour-secondary block-text margin-medium">
                        {description}
                    </span>
                    <div className="upgrade-modal-button-container">
                        <div 
                            className="text-button"
                            onClick={() => closeModal()}
                        >
                           <span className="body-regular colour-link">
                                Maybe later
                           </span>
                        </div>
                        <div className="upgrade-modal-action-button-container">
                            <ActionButton
                                onClick={() => viewPricing()}
                            >
                                Upgrade now
                            </ActionButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpgradeModalQuickReport;