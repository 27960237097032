const locations = (state = [
	{
		label: "STATES",
		places: []
	},
	{
		label: "COUNTIES",
		places: []
	},
	{
		label: "CITIES",
		places: []
	}
], action) => {
    switch(action.type) {
        case 'locations':
            return action.payload;
        default:
            return state;
    }
}

export default locations;