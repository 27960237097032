import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ActionButton } from '../styles/GlobalStyles';
import { addStripeCreditCard } from '../functions';
import { Loading } from './';

function SetupForm(props) {

    const clientSecret = props.clientSecret;
    const domain = props.domain;
    const userData = props.userData;
    const setUserData = props.setUserData;
    const setPaymentMethodModal = props.setPaymentMethodModal;
    const payFunction = props.payFunc;
    const creditCardButton = props.creditCardButton;
    const payDisabled = props.setDisabled;
    const pageState = props.pageState;
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async(event) => {
        payDisabled(true);
        const addCard = await addStripeCreditCard(
            event,
            stripe,
            setDisabled,
            elements,
            setErrorMessage,
            userData,
            setUserData,
            setPaymentMethodModal,
            domain,
            clientSecret,
            pageState
        );

        if ( payFunction !== null && addCard.status === 200 ) {
            setDisabled(true);
            await payFunction(addCard.userDetails);
            payDisabled(false);
        }
        else if ( addCard.status !== 200 ) {
            setDisabled(false);
            payDisabled(false);
        }
        else {
            payDisabled(false);
        }
    };

    return (
        <div className="stripe-setup-form-container">
            {
                disabled === true ?
                <Loading />
                :
                null
            }
            <PaymentElement />
            <div className="stripe-form-button-container">
                {
                    errorMessage === null ?
                    null
                    :
                    <span className="body-regular colour-error margin-medium block-text">
                        {errorMessage}
                    </span>
                }
                <ActionButton
                    onClick={(event) => handleSubmit(event)}
                    disabled={disabled}
                    ref={payFunction !== null ? creditCardButton : null}
                >
                    Submit{domain === "checkout" ? " payment" : ""}
                </ActionButton>
            </div>
        </div>
    )
}

export default SetupForm;