import React, { useState } from 'react';
import '../styles/AddComp.css';
import { addComps, cloudFunctionV2, getCompImages, placesAutocompleteSearch } from '../functions';
import { ActionButton, FilterCityInput, allStates, numberFormatter } from '../styles/GlobalStyles';
import { Bedrooms, Bathrooms, SingleFamily, MultiFamilyBlack, SQFootage } from '../assets';
import { LoadingStatic } from '../components';

function AddComp(props) {
    const property = props.property;
    const setAddCompModal = props.setAddCompModal;
    const setSelectedComp = props.setSelectedComp;
    const manualComps = props.manualComps;
    const setManualComps = props.setManualComps;
    const marketStats = props.marketStats;
    const setMarketStats = props.setMarketStats;
    const resetSwiper = props.resetSwiper;
    const finishAddComp = props.finishAddComp;
    const [searchLocation, setSearchLocation] = useState("");
    const [activeSearching, setActiveSearching] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [places, setPlaces] = useState([]);
    const [newComp, setNewComp] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const attributes = newComp === null ? [] : [
        {
            icon: newComp.propertyType === "SINGLE_FAMILY" ? SingleFamily : MultiFamilyBlack,
            value: newComp.propertyType === null ? "N/A" : newComp.propertyType === "SINGLE_FAMILY" ? "Single Family" : newComp.propertyType === "MULTI_FAMILY" ? "Multi Family" : newComp.propertyType === "TOWNHOUSE" ? "Townhouse" : newComp.propertyType === "CONDO" ? "Condo" : newComp.propertyType
        },
        {
            icon: Bedrooms,
            value: `${newComp.bedrooms === null ? "N/A" : `${newComp.bedrooms} beds`}`
        },
        {
            icon: Bathrooms,
            value: `${newComp.bathrooms === null ? "N/A" : `${newComp.bathrooms} baths`}`
        },
        {
            icon: SQFootage,
            value: `${newComp.livingArea === null ? "N/A" : numberFormatter.format(newComp.livingArea)} sqft`
        },
    ];

    const changeLocation = async(text) => {
        setSearchLocation(text);
        setSelectedAddress(null);
        if ( text === "" ) {
            setPlaces([]);
        }
        else {
            setActiveSearching(true);
            const autocompleteParams = {
				input: text,
				language: "en",
				types: "address",
				components: "country:us"
			};
			const getAutocomplete = await placesAutocompleteSearch(autocompleteParams);
			if ( getAutocomplete.status === 200 ) {
				const responses = getAutocomplete.data;
				if ( responses.length !== 0 ) {
                    // Convert addresses into streetAddress, city, and state
                    const addressResponses = [];
                    for (let index = 0; index < responses.length; index++) {
                        const element = responses[index];
                        const terms = element.terms;
                        let minusIndex = 0;

                        if ( terms.length > 3 ) {
                            let cityState = terms[3].value;
                            if ( cityState === "USA" ) {
                                cityState = terms[2].value;
                                minusIndex = 1;
                            }
                            
                            if ( cityState.length > 2 ) {
                                const stateIndex = allStates.findIndex(e => e.name === cityState);
                                if ( stateIndex !== -1 ) {
                                    cityState = allStates[stateIndex].abbreviation;
                                }
                            }

                            const newObject = {
                                city: terms[2 - minusIndex].value,
                                state: cityState,
                                streetAddress: minusIndex === 0 ? `${terms[0].value} ${terms[1].value}` : `${terms[0].value}`
                            }
                            addressResponses.push(newObject);
                        }
                    };

                    setPlaces(responses);
                }
            }
            setActiveSearching(false);
        }
    };

    const selectedLocation = async(place) => {
        setSelectedAddress(place);
        setSearchLocation(place.description);
        setPlaces([]);
    };

    const hideModal = () => {
        setAddCompModal(false);
    };

    const queryComp = async() => {
        // addComp
        setLoading(true);
        setPlaces([]);
        setSearchLocation("");
        const zpid = property.zpid;
        const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
        const getGatewayQueryString = await addComps(zpid, selectedAddress, "sales");
        const resourceId = "addComp";
        const getGatewayParams = {
            type: "gateway",
            resourceId: resourceId,
            queryString: getGatewayQueryString
        };
        const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
        if ( getGateway.status === 200 ) {
            const body = getGateway.body;
            if ( body.errorMessage === undefined ) {
                setError("");
                const getImages = await getCompImages([body.compDetails], "sales");
                if ( getImages.status === 200 ) {
                    body.compDetails.images = getImages.data[0].images;
                    setNewComp(body.compDetails);
                }
            }
            else {
                setError(`This comp has not been sold for at least 12 months, please try another address`)
            }
        }
        else {
            setError("There was an error finding this comp..please contact us for more details.");
        }
        setLoading(false);
    };

    const complete = () => {
        setAddCompModal(false);
        setSelectedComp(newComp.zpid);
        setManualComps([...manualComps, newComp]);
        finishAddComp([...manualComps, newComp]);

        const newMarketStats = [...marketStats];
        newMarketStats[0].value = newMarketStats[0].value + 1;
        setMarketStats(newMarketStats);
        resetSwiper();
    };

    return (
        <div className="add-comp-outer-container bg-colour-light">
            <div className="add-comp-inner-container relative-container">
                <div 
                    className="add-comp-exit-container"
                    onClick={() => hideModal()}
                >
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="22" 
                        height="25" 
                        viewBox="0 0 22 25" 
                        fill="none"
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M2.25829 0.919021C1.7903 0.399259 0.989564 0.357294 0.469802 0.82529C-0.0499597 1.29329 -0.0919245 2.09402 0.376071 2.61378L9.2968 12.5213L0.414356 22.3862C-0.0536387 22.906 -0.0116752 23.7067 0.508087 24.1747C1.02785 24.6427 1.82858 24.6007 2.29658 24.081L11.0009 14.4138L19.7052 24.0809C20.1732 24.6007 20.9739 24.6427 21.4937 24.1747C22.0134 23.7067 22.0554 22.9059 21.5874 22.3862L12.705 12.5213L21.6257 2.61382C22.0937 2.09406 22.0517 1.29332 21.532 0.825326C21.0122 0.357331 20.2115 0.399296 19.7435 0.919057L11.0009 10.6287L2.25829 0.919021Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div>
                <div className="add-comp-title-container margin-x-large">
                    <h2 className="heading-large-semibold colour-primary margin-medium">
                        Add a comp
                    </h2>
                    <span className="body-regular colour-secondary">
                        Search for a residential address to add 
                        that property as a comp automatically.
                    </span>
                </div>
                {
                    loading === true ?
                    <div className="add-comp-loading-container margin-x-x-large">
                        <LoadingStatic />
                        <span className="body-semibold colour-primary">
                            Getting property details. This could take a few seconds...
                        </span>
                    </div>
                    :
                    newComp === null ?
                    <div className="add-comp-input-container relative-container margin-x-x-large">
                        <FilterCityInput
                            value={searchLocation}
                            type="text"
                            id="add-comp-input"
                            placeholder="Search for a residential address"
                            onChange={(text) => changeLocation(text.target.value)}
                            disabled={false}
                        />
                        {
                            error !== "" ?
                            <div className="margin-top-x-small">
                                <span className="body-regular colour-error">
                                    {error}
                                </span>
                            </div>
                            :
                            null
                        }
                        <div className="city-filtering-outer-container">
                            {
                                activeSearching === true ?
                                <div className="city-filtering-loading-container">
                                    <span className="body-regular colour-primary">
                                        Loading...
                                    </span>
                                </div>
                                :
                                null
                            }
                            {
                                activeSearching === false ?
                                    places.map((item, index) =>
                                        <div 
                                            className={"city-filtering-element " + (selectedAddress === index ? "city-filtering-element-selected" : "")}
                                            key={index}
                                            onClick={() => selectedLocation(item)}
                                        >
                                            <span className="body-regular colour-primary">
                                                {item.description}
                                            </span>
                                        </div>
                                    )
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    <div className="add-comp-selected-comp-container margin-x-x-large">
                        <h3 className="heading-small-semibold colour-primary margin-medium">
                            {newComp.address.streetAddress}, {newComp.address.city}, {newComp.address.state} {newComp.address.zipcode}
                        </h3>
                        <div className="add-comp-selected-comp-row">
                            {
                                attributes.map((item, index) =>
                                    <div 
                                        className="add-comp-selected-comp-attribute subtext-margin-right-x-large" 
                                        key={index}
                                    >
                                        <div className="add-comp-selected-comp-attribute-icon">
                                            <img 
                                                src={item.icon} 
                                                className="add-comp-icon"
                                                alt="attribute icon"
                                            />
                                        </div>
                                        <span className="body-regular colour-primary">
                                            {item.value}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                }
                <div className="add-comp-ctas-row">
                    <div 
                        className="text-button"
                        onClick={() => hideModal()}
                    >
                        <span className="body-regular colour-link">
                            Cancel
                        </span>
                    </div>
                    <div className="add-comp-button-container">
                        <ActionButton
                            disabled={selectedAddress === null || loading === true ? true : false}
                            onClick={() => newComp === null ? queryComp() : complete()}
                        >
                            Add comp
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AddComp;