import React from 'react';
import '../styles/FavouritesTable.css';
import { percentage, formatterLong } from '../styles/GlobalStyles';
import { NeighbourhoodBadge } from './';

function FavouritesTable(props) {
    const properties = props.properties;
    const propertySelected = props.propertySelected;
    const toggleCheckbox = props.toggleCheckbox;
    const selectedCategories = props.selectedCategories;
    const columns = props.columns;
    const neighbourhoodData = props.neighbourhoodData;

    // Style Guide
    // u = underline
    // p = percentage
    // b = badge
    // d = dollar
    // t = text

    const checkClass = (val) => {
        if ( val === "u" ) {
            return "body-regular-underline colour-link";
        }
        else if ( val === "d" || val === "dp" ) {
            return "body-regular colour-primary";
        }
        else if ( val === "p" ) {
            return "body-regular colour-primary";
        }
        else if ( val === "t" || val === "m" ) {
            return "body-regular colour-primary";
        }
        else {
            return "";
        }
    };

    const renderValue = (item, column) => {
        const propertyDP = item.financials.downPayment / 100;
        let value;
        if ( column.value.length === 2 ) {
            value = item[column.value[0]][column.value[1]];
        }
        else if ( column.value.length === 1 ) {
            value = item[column.value];
        }

        if ( column.style === "u" ) {
            return value;
        }
        else if ( column.style === "p" ) {
            return typeof(value) === "number" ? percentage.format(value / 100) : value;
        }
        else if ( column.style === "d" ) {
            return typeof(value) === "number" ? formatterLong.format(value).replace(".00", "") : value;
        }
        else if ( column.style === "dp" ) {
            return formatterLong.format(propertyDP * item.price).replace(".00", "");
        }
        else if ( column.style === "t" ) {
            return value;
        }
        else if ( column.style === "m" ) {
            return value === true ? "Off-market" : "On-market";
        }
    }

    const checkCategorySelected = (val) => {
        if ( selectedCategories.indexOf(val) !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    }

    const viewProperty = (item) => {
        window.open(`/properties/${item.cityId}/${item.zpid}`, '_blank');
    }

    return (
        <div className="favourites-table-outer-container">
            <table className="favourites-table-element">
                <thead className="favourites-table-row">
                    <tr className="favourites-fixed-container">
                        {
                            columns.map((item, index) =>
                                index < 2 ?
                                <th 
                                    className={"favourites-table-label-cell text-align-left " + (index === 0 ? "favourites-table-checkbox-header-container" : "")}
                                    key={index}
                                >
                                    <span className="body-semibold colour-primary">
                                        {item.label}
                                    </span>
                                </th>
                                :
                                null
                            )
                        }
                    </tr>
                </thead>
                <tbody className="favourites-table-row">
                    <tr className="favourites-fixed-container">
                        {
                            properties.map((item, index) =>
                                <td
                                    key={index}
                                    className={"favourites-table-value-row " + (propertySelected(item.zpid) === true ? "favourites-table-value-row-selected" : "")}
                                >
                                    {
                                        columns.map((column, i) => 
                                            i === 0 ?
                                            <div 
                                                className="favourites-table-value-checkbox-outer-container "
                                                key={`${i}-sub`}
                                            >
                                                <div 
                                                    className="favourites-table-checkbox-container"
                                                    onClick={() => toggleCheckbox(item.zpid)}
                                                >
                                                    {
                                                        propertySelected(item.zpid) === true ?
                                                        <div className="favourites-table-checkbox">
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            i === 1 ?
                                            <div 
                                                className="favourites-table-value-cell "
                                                key={`${i}-sub`}
                                            >
                                                <span 
                                                    className={checkClass(column.style)}
                                                    onClick={() => i === 1 ? viewProperty(item) : null}
                                                >
                                                    {renderValue(item, column)}
                                                </span>
                                            </div>
                                            :
                                            null
                                        )
                                    }
                                </td>
                            )
                        }
                    </tr>
                </tbody>
            </table>
            <table className="favourites-table-element favourites-body-table">
                <thead className="favourites-table-row">
                    <tr className="favourites-table-running-margin">
                        {
                            columns.map((item, index) =>
                                index >= 2 && checkCategorySelected(item.cellValue) === true ?
                                <th 
                                    className={"favourites-table-label-cell text-align-left " + (index === 0 ? "favourites-table-checkbox-header-container" : "")}
                                    key={index}
                                >
                                    <span className="body-semibold colour-primary">
                                        {item.label}
                                    </span>
                                </th>
                                :
                                null
                            )
                        }
                    </tr>
                </thead>
                <tbody className="">
                    <tr className="favourites-table-running-margin">
                        {
                            properties.map((item, index) =>
                                <td
                                    key={index}
                                    className={"favourites-table-value-row " + (propertySelected(item.zpid) === true ? "favourites-table-value-row-selected" : "")}
                                >
                                    {
                                        columns.map((column, i) => 
                                            i > 1 && checkCategorySelected(column.cellValue) === true ?
                                            <div 
                                                className={"favourites-table-value-cell " + (i === 1 ? "" : "")}
                                                key={`${i}-sub`}
                                            >
                                                {
                                                    column.style === "b" && column.cellValue === "neighbourhood-data" ?
                                                    <NeighbourhoodBadge
                                                        item={item}
                                                        neighbourhoodData={neighbourhoodData}
                                                        type={"neighbourhood-data"}
                                                        page="table"
                                                    />
                                                    :
                                                    <span 
                                                        className={checkClass(column.style)}
                                                        onClick={() => i === 0 ? viewProperty(item) : null}
                                                    >
                                                        {renderValue(item, column)}
                                                    </span>
                                                }
                                            </div>
                                            :
                                            null
                                        )
                                    }
                                </td>
                            )
                        }
                        <div className="favourites-table-right-shadow"></div>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FavouritesTable;