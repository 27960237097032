import React from 'react';
import ImageGallery from 'react-image-gallery';
import '../styles/ImageSwiper.css';
import { ExitWhite } from '../assets/';

function ImageSwiper(props) {
	const modalImages = props.modalImages;
	const modalStartIndex = props.modalStartIndex;
	const elementClicked = props.elementClicked;
	const closeModal = props.closeModal;

	return (
		<div 
			className="image-modal-container"
		>
			<img
				src={ExitWhite}
				className="image-modal-exit-element"
				alt="Close Modal"
				onClick={() => closeModal()}
			/>
			<ImageGallery 
				items={modalImages}
				showNav={true}
				showFullscreenButton={false}
				showPlayButton={false}
				startIndex={modalStartIndex}
				slideOnThumbnailOver={false}
				disableThumbnailScroll={false}
				onClick={(val) => elementClicked(val)}
				lazyLoad={false}
			/>
		</div>
	)
}

export default ImageSwiper;