import React from 'react';
import '../styles/BadgeExplanation.css';
import { Beak } from '../assets';

function BadgeExplanation(props) {
    const title = props.title;
    const description = props.description;

    return (
        <div className="drip-score-badge-explanation">
            <img
                src={Beak}
                className="drip-score-badge-beak"
                alt="Beak"
            />
            <div className="drip-score-badge-explanation-inner-container">
                <h2 className="drip-score-badge-explanation-title">
                    {title}
                </h2>
                <p className="drip-score-badge-explanation-description">
                    {description}
                </p>
            </div>
        </div>
    )
}

export default BadgeExplanation;