import React, { useEffect, useState } from 'react';
import '../styles/SellerFinanceCalculator.css';
import { Accordion, AccordionDetails, AccordionSummary, colour, FinancialsInput, FinancialsInputRed, formatterCompact, percentage, percentageCompact, CustomSelect } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { calculateCashOnCash, calculateInterestPaid, calculateSubToMortgagePayment, checkFinancialsInteger, formatPrice, calculateMortgagePayment, recordEvent } from '../functions';
import { StrategiesPanelFooter, MonthlyCostsTab, RentTab, MobileStrategiesPanel, NewSwitch, RehabTab, LeaseOptionRent, STRRent } from './';
import { useNavigate } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

function SubtoCalculator(props) {
    const property = props.property;
    const multipleUniqueUnits = property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
    const highLevelProfits = props.highLevelProfits;
    const setHighLevelProfits = props.setHighLevelProfits;
    const price = props.price;
    const setPrice = props.setPrice;
    const downPayment = props.downPayment;
    const setDownPayment = props.setDownPayment;
    const interestRate = props.interestRate;
    const setInterestRate = props.setInterestRate;
    const balloonPayment = props.balloonPayment;
    const setBalloonPayment = props.setBalloonPayment;
    const duration = props.duration;
    const setDuration = props.setDuration;
    const amortisation = props.amortisation;
    const setAmortisation = props.setAmortisation;
    const monthlyPayment = props.monthlyPayment;
    const setMonthlyPayment = props.setMonthlyPayment;
    const setSellerCompensation = props.setSellerCompensation;
    const viewRentalComps = props.viewRentalComps;
    const defaultDownPayment = props.defaultDownPayment;
    const mobileBar = props.mobileBar;
    const toggleMobileBottomBar = props.toggleMobileBottomBar;
    const cityId = props.cityId;
    const strategyPanel = props.strategyPanel;
    const setStrategyPanel = props.setStrategyPanel;
    const ownerEquity = props.ownerEquity;
    const setFullScreenFinancials = props.setFullScreenFinancials;
    const fullScreenFinancials = props.fullScreenFinancials;
    const setOwnerEquity = props.setOwnerEquity;
    const costPerSqFoot = props.costPerSqFoot;
    const setCostPerSqFoot = props.setCostPerSqFoot;
    const totalRehabCost = props.totalRehabCost;
    const setTotalRehabCost = props.setTotalRehabCost;
    const defaultCostPerSqFoot = props.defaultCostPerSqFoot;
    const blurred = props.blurred;
    const exitStrategy = props.exitStrategy;
    const setExitStrategy = props.setExitStrategy;
    const leaseOptionValue = props.leaseOptionValue;
    const setLeaseOptionValue = props.setLeaseOptionValue;
    const leaseOptionDownPayment = props.leaseOptionDownPayment;
    const setLeaseOptionDownPayment = props.setLeaseOptionDownPayment;
    const leaseOptionInterestRate = props.leaseOptionInterestRate;
    const setLeaseOptionInterestRate = props.setLeaseOptionInterestRate;
    const strRevenue = props.strRevenue;
    const setStrRevenue = props.setStrRevenue;
    const remainingMortgage = props.remainingMortgage;
    const setRemainingMortgage = props.setRemainingMortgage;
    const sellersMortgage = props.sellersMortgage;
    const setSellersMortgage = props.setSellersMortgage;

    const [firstQuery, setFirstQuery] = useState(false);
    const [monthlyCosts, setMonthlyCosts] = useState([]);
    const [rent, setRent] = useState(property.financials.rent);
    const [unitsRent, setUnitsRent] = useState([]);
    const [multiUnitRent, setMultiUnitRent] = useState([]);
    const [multiUnitRentTotal, setMultiUnitRentTotal] = useState(0);
    const [defaultMultiUnitRent, setDefaultMultiUnitRent] = useState(0);
    const [multiUnitTypeUnitLiving, setMultiUnitTypeUnitLiving] = useState([0, 0]);
    const [multiUnitRentRatio, setMultiUnitRentRatio] = useState([1, 1]);
    const [purchaseAccordion, setPurchaseAccordion] = useState(true);
    const [dollarDownPayment, setDollarDownPayment] = useState((price * (downPayment / 100)).toFixed(2));
    const [priceSlider, setPriceSlider] = useState(20);
    const [downPaymentSlider, setDownPaymentSlider] = useState(defaultDownPayment);
    const [termsAccordion, setTermsAccordion] = useState(false);
    const [ownerEquityDollar, setOwnerEquityDollar] = useState(Number((price * ownerEquity).toFixed(2)));
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(false);
    const [leaseOptionDollarDownPayment, setLeaseOptionDollarDownPayment] = useState(leaseOptionValue * (leaseOptionDownPayment / 100));
    const [leaseOptionRent, setLeaseOptionRent] = useState(0);
    

    const tabs = [
        {
            title: "Purchase",
        },
        {
            title: exitStrategy === 0 ? "Rent" : exitStrategy === 1 ? "Lease option" : "STR revenue",
        },
        {
            title: "Rehab",
        },
        {
            title: "Monthly costs"
        },
    ];
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();

    const strategies = [
		{
            text: "Seller Finance",
            shortText: "Seller-Fi.",
			title: "",
			description: "",
			link: ""
        },
		{
            text: "SubTo",
            shortText: "SubTo",
			title: "",
			description: "",
			link: ""
        }
    ];
    const strategiesText = [strategies[0].shortText, strategies[1].shortText];
    const defaultRemainingMortgage = property.ownerAttributes.estMortgageBalance;
    const defaultEquity = (property.price - defaultRemainingMortgage) / property.price;

    const ownerEquityInfo = [
        {
            label: "Est. equity",
            value: percentage.format(defaultEquity)
        },
        {
            label: "Est. mort. balance",
            value: formatterCompact.format(defaultRemainingMortgage)
        },
        {
            label: "Est. mort. payment",
            value: formatterCompact.format(property.ownerAttributes.estMortgagePayment)
        }
    ];

    const handlePurchaseAccordion = () => {
        setPurchaseAccordion(!purchaseAccordion);
    };

    const handleTermsAccordion = () => {
        setTermsAccordion(!termsAccordion);
    };

    useEffect(() => {

        const calculateFirstMonthlyPayment = async() => {
            const newMortgage = await calculateSubToMortgagePayment(Number(price), downPayment, interestRate, ownerEquity, (amortisation / 12));
            const newMonthlyPayment = (newMortgage + Number(sellersMortgage));
            const formattedNewMortgage = newMortgage.toFixed(2);
            setMonthlyPayment(formattedNewMortgage);
            const newOwnerEquityDollar = ownerEquity * price;

            const totalInterest = await calculateInterestPaid(newOwnerEquityDollar, downPayment, interestRate, (amortisation / 12), (duration / 12));
            const newTotalCompensation = Number(newOwnerEquityDollar) + totalInterest;
            setSellerCompensation(newTotalCompensation);
            const downPaymentFee = price * (downPayment / 100);
            const totalMonthlyPayments = newMortgage * duration;
            const remainingBalance = formatNumberEffect((newTotalCompensation - downPaymentFee) - totalMonthlyPayments, 2);

            setBalloonPayment(remainingBalance);

            const newProfit = rent - (newMonthlyPayment + property.financials.propertyTaxes + property.financials.insurance + property.financials.hoaFees + property.financials.vacancyRateAllocation + property.financials.managementFee + property.financials.maintenanceFee + property.financials.otherCosts);
            const newInitialCosts = Number(totalRehabCost) + downPaymentFee;
            const newCashOnCash = await calculateCashOnCash(newProfit, newInitialCosts);

            const newLeaseOptionMortgage = await calculateMortgagePayment(leaseOptionValue, leaseOptionDownPayment, leaseOptionInterestRate, 30);
            setLeaseOptionRent(newLeaseOptionMortgage);

            const newLeaseOptionDown = (leaseOptionDownPayment / 100) * leaseOptionValue;
            const newMonthlyCostsTotal = (newMonthlyPayment + property.financials.propertyTaxes + property.financials.insurance + property.financials.hoaFees + property.financials.vacancyRateAllocation + property.financials.managementFee + property.financials.maintenanceFee + property.financials.otherCosts);
            const newLeaseOptionProfit = newLeaseOptionMortgage - newMonthlyCostsTotal;
            const newLeaseOptionInitialCosts = newInitialCosts - newLeaseOptionDown;
            const newLeaseOptionCashOnCash = await calculateCashOnCash(newLeaseOptionProfit, newLeaseOptionInitialCosts);

            const monthlySTRRent = (strRevenue / 12);
            const monthlySTRProfit = monthlySTRRent - newMonthlyPayment;
            const newSTRCashOnCash = await calculateCashOnCash(monthlySTRProfit, newInitialCosts);

            const profitObject = {
                monthlyProfit: newProfit,
                cashOnCash: newCashOnCash / 100,
                totalCosts: newInitialCosts,
                leaseOptionProfit: newLeaseOptionProfit,
                leaseOptionCashOnCash: newLeaseOptionCashOnCash / 100,
                leaseOptionTotalCosts: newLeaseOptionInitialCosts,
                strProfit: monthlySTRProfit,
                strCashOnCash: newSTRCashOnCash / 100
            };
            setHighLevelProfits(profitObject);
        };

        const formatNumberEffect = (number, dp) => {
            // Check if the number is an integer
            if (Number.isInteger(number)) {
                return number; // Return the integer as is
            }
            else {
                return number.toFixed(dp); // Round to one decimal place
            }
        }

        // Multi family home units for BOTH 1 unit layout AND house hack 1 unit layout
        const checkUnits = () => {
            const propertyUnits = property.units;
            const propertyRent = property.financials.rent;
            const newUnits = [];
            for (let index = 0; index < propertyUnits; index++) {
                const newUnit = {
                    value: Math.round(propertyRent / propertyUnits),
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            };
            setUnitsRent(newUnits);
        };

		// Multi family home units for multi unit layout
		// AND house hack multi unit layout
		const checkUniqueUnits = () => {
			const uniqueUnits = property.uniqueUnitPropertyDetails;
			const letters = ["A", "B", "C", "D"];
			
			const newUnits = [];
			for (let index = 0; index < uniqueUnits.length; index++) {
				const element = uniqueUnits[index];
				const unitFrequency = Number(element.frequency);
				const newFrequency = {
					units: [],
					label: `Layout ${letters[index]}`
				};

				for (let ii = 0; ii < unitFrequency; ii++) {
					const newObject = {
						value: Number(element.rentcast_rent),
						original: Number(element.rentcast_rent),
						label: `Unit ${ii + 1}${letters[index]}`
					};
					newFrequency.units.push(newObject);
				};
				newUnits.push(newFrequency);
			};
			setMultiUnitRent(newUnits);
			const newTotal = newUnits.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitRentTotal(newTotal);
			setDefaultMultiUnitRent(newTotal);

			const uniqueRentArray = [];
			for (let index = 0; index < uniqueUnits.length; index++) {
				const element = uniqueUnits[index];
				uniqueRentArray.push(Number(element.rentcast_rent));
			};
			const minimumRentValue = Math.min(...uniqueRentArray);
			const minimumValueIndex = uniqueRentArray.indexOf(minimumRentValue);
			setMultiUnitTypeUnitLiving([minimumValueIndex, 0]);

			if ( uniqueRentArray.length > 1 ) {
				const firstRentRate = uniqueRentArray[0];
				const secondRentRate = uniqueRentArray[1];
				const ratio = secondRentRate / firstRentRate;
				setMultiUnitRentRatio([1, ratio]);
			}
		};

        const formatMonthlyCosts = async() => {
			// This is where we format monthly costs to be based off of user settings
            const newMortgage = await calculateSubToMortgagePayment(Number(price), downPayment, interestRate, ownerEquity, (amortisation / 12));
            const newMonthlyPayment = (newMortgage + Number(sellersMortgage));
            const recurringCosts = [
                {
                    label: "Mortgage payment",
                    value: Number(newMonthlyPayment).toFixed(2),
                    loss: true
                },
                {
                    label: "HOA fees",
                    value: property.financials.hoaFees === null ? 0 : checkFinancialsInteger(property.financials.hoaFees),
                    loss: true
                },
                {
                    label: "Insurance",
                    value: checkFinancialsInteger(property.financials.insurance),
                    loss: true
                },
                {
                    label: "Property taxes",
                    value: checkFinancialsInteger(property.financials.propertyTaxes),
                    loss: true
                },
                {
                    label: "Vacancy",
                    value: checkFinancialsInteger(property.financials.vacancyRateAllocation),
                    loss: true
                },
                {
                    label: "Management fee",
                    value: checkFinancialsInteger(property.financials.managementFee),
                    loss: true
                },
                {
                    label: "Maintenance",
                    value: checkFinancialsInteger(property.financials.maintenanceFee),
                    loss: true
                },
                {
                    label: "Other costs",
                    value: property.financials.otherCosts === undefined ? 0 : checkFinancialsInteger(property.financials.otherCosts),
                    loss: true
                }
            ];
			const newArray = [
				{
					label: "Property costs",
					inputs: recurringCosts.slice(0, 4)
				},
				{
					label: "Operating expenses",
					inputs: recurringCosts.slice(4, 6)
				},
				{
					label: "Upkeep",
					inputs: recurringCosts.slice(6, 8)
				}
			];
			const relevantRent = property.financials.rent;

			for (let index = 0; index < newArray.length; index++) {
				const element = newArray[index];
				for (let ii = 0; ii < element.inputs.length; ii++) {
					const input = element.inputs[ii];
                    // Values based on our defaults
                    newArray[index].inputs[ii].percentage = false;
                    if ( index === 0 ) {
                        const value = Number(newArray[index].inputs[ii].value) * 12;
                        const percentageVal = (value / price) * 100;
                        newArray[index].inputs[ii].percentageVal = Number(percentageVal.toFixed(1));
                    }
                    else {
                        const value = Number(newArray[index].inputs[ii].value);
                        const percentageVal = (value / relevantRent) * 100;
                        newArray[index].inputs[ii].percentageVal = Number(percentageVal.toFixed(1));
                    }
                    if ( input.original === undefined ) {
                        newArray[index].inputs[ii].original = input.value;
                    }
				}
			}
			setMonthlyCosts(newArray);
		};

        if ( firstQuery === false ) {
            setFirstQuery(true);
            calculateFirstMonthlyPayment();
            
            formatMonthlyCosts();
            if ( unitsRent.length === 0 && property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === false ) {
                checkUnits();
            }
            else if ( multiUnitRent.length === 0 && property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === true ) {
                checkUniqueUnits();
            }
        }
    }, [
        amortisation, 
        downPayment, 
        duration, 
        interestRate, 
        price, 
        firstQuery, 
        property.financials.hoaFees,
        property.financials.insurance,
        property.financials.managementFee,
        property.financials.maintenanceFee,
        property.financials.otherCosts,
        property.financials.propertyTaxes,
        property.financials.rent,
        property.financials.vacancyRateAllocation,
        setHighLevelProfits,
        property.financials.mortgagePayment,
        setBalloonPayment,
        setMonthlyPayment,
        setSellerCompensation,
        multiUnitRent.length,
        multipleUniqueUnits,
        property.propertyTypeDimension,
        property.uniqueUnitPropertyDetails,
        property.units,
        unitsRent.length,
        property.ownerAttributes.estMortgagePayment,
        strategyPanel,
        rent,
        sellersMortgage,
        ownerEquity,
        totalRehabCost,
        leaseOptionDownPayment,
        leaseOptionInterestRate,
        leaseOptionValue,
        strRevenue
    ]);

    const roundToNearestTenth = (num) => {
        return Math.round(num * 10) / 10;
    };

    const onChangePrice = async(value, newStrategy) => {
        setPrice(value);
        const newDownPayment = Number(value) === 0 ? 0 : (dollarDownPayment / Number(value)) * 100;

        const newOwnerEquity = Number(value) === 0 ? 0 : (Number(value) - remainingMortgage) / Number(value);
        const newEquity = (newOwnerEquity * Number(value)).toFixed(2).replace(".00", "");
        setOwnerEquityDollar(newEquity);
        setOwnerEquity(newOwnerEquity);

        const oneSliderUnit = property.price / 20;
        const newSliderValue = roundToNearestTenth(Number(value) / oneSliderUnit);
        setPriceSlider(newSliderValue);

        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(value, newDownPayment, interestRate, duration, amortisation, newStrategy, rent, newTotal, newOwnerEquity, sellersMortgage);
    };

    const onChangeDownPayment = async(value) => {
        setDownPayment(value);
        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        const newDollar = (Number(value) / 100) * price;
        setDollarDownPayment(newDollar);

        const newSliderValue = roundToNearestTenth(Number(value));
        setDownPaymentSlider(newSliderValue);

        recaulculateMonthlyPayment(price, value, interestRate, duration, amortisation, strategyPanel, rent, newTotal, ownerEquity, sellersMortgage);
    };

    const onChangeDownPaymentDollar = async(value) => {
        const newPercentage = (Number(value) / price) * 100;
        setDownPayment(newPercentage);
        setDollarDownPayment(value);
        setDownPaymentSlider(Math.round(newPercentage));

        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, newPercentage, interestRate, duration, amortisation, strategyPanel, rent, newTotal, ownerEquity, sellersMortgage);
    };

    const onChangeOwnerEquity = async(value) => {
        setOwnerEquityDollar(value);

        const newPrice = Math.round(Number(value) / ownerEquity);
        setPrice(newPrice);

        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(newPrice, downPayment, interestRate, duration, amortisation, strategyPanel, rent, newTotal, ownerEquity, sellersMortgage);
    };

    const onChangeInterestRate = async(value) => {
        setInterestRate(value);
        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, downPayment, value, duration, amortisation, strategyPanel, rent, newTotal, ownerEquity, sellersMortgage);
    };

    const onChangeBalloon = (value) => {
        setBalloonPayment(value);
    };

    const onChangeDuration = async(value) => {
        const newVal = value === "" ? "" : Number(value) * 12;
        setDuration(newVal);
        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, downPayment, interestRate, newVal, amortisation, strategyPanel, rent, newTotal, ownerEquity, sellersMortgage);
    };

    const onChangeAmortisation = async(value) => {
        const newVal = value === "" ? "" : Number(value) * 12;
        setAmortisation(newVal);
        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, downPayment, interestRate, duration, newVal, strategyPanel, rent, newTotal, ownerEquity, sellersMortgage);
    };

    const onChangeSellersMortgage = async(value) => {
        setSellersMortgage(value);

        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, downPayment, interestRate, duration, amortisation, strategyPanel, rent, newTotal, ownerEquity, Number(value));
    };
    
    const recaulculateMonthlyPayment = async(
        newPrice, 
        newDown, 
        newInt, 
        newDuration, 
        newAmortisation, 
        newStrategy, 
        newRent, 
        newMonthlyCostsTotal,
        newEquity,
        newSellersMortgage
    ) => {
        const sellersMortgage = newStrategy === 0 ? 0 : Number(newSellersMortgage);
        const newMortgage = await calculateSubToMortgagePayment(Number(newPrice), newDown, newInt, newEquity, (newAmortisation / 12));
        const newMonthlyPayment = (newMortgage + sellersMortgage);
        const formattedNewMortgage = newMortgage.toFixed(2);
        setMonthlyPayment(formattedNewMortgage);

        const newOwnerEquityDollar = newEquity * newPrice;
        const totalInterest = await calculateInterestPaid(newOwnerEquityDollar, newDown, newInt, (newAmortisation / 12), (newDuration / 12));
        const newTotalCompensation = Number(newOwnerEquityDollar) + totalInterest;
        setSellerCompensation(newTotalCompensation);
        const downPaymentFee = newPrice * (newDown / 100);
        const totalMonthlyPayments =  Number(newDuration) === 0 ? 0 : newMortgage * Number(newDuration);
        const remainingBalance = formatNumber((newTotalCompensation - downPaymentFee) - totalMonthlyPayments, 2);

        setBalloonPayment(remainingBalance);

        const newProfit = newRent - (newMonthlyPayment + newMonthlyCostsTotal);
        const newInitialCosts = Number(totalRehabCost) + downPaymentFee;
        const newCashOnCash = await calculateCashOnCash(newProfit, newInitialCosts);

        const newLeaseOptionDown = (leaseOptionDownPayment / 100) * leaseOptionValue;
        const newLeaseMonthlyCostsTotal = (newMonthlyPayment + newMonthlyCostsTotal);
        const newLeaseOptionProfit = leaseOptionRent - newLeaseMonthlyCostsTotal;
        const newLeaseOptionInitialCosts = newInitialCosts - newLeaseOptionDown;
        const newLeaseOptionCashOnCash = await calculateCashOnCash(newLeaseOptionProfit, newLeaseOptionInitialCosts);

        const newStrMonthlyRent = (strRevenue / 12);
        const newStrMonthlyProfit = newStrMonthlyRent - newMonthlyPayment;
        const newSTRCashOnCash = await calculateCashOnCash(newStrMonthlyProfit, newInitialCosts);

        const profitObject = {
            monthlyProfit: newProfit,
            cashOnCash: newCashOnCash / 100,
            totalCosts: newInitialCosts,
            leaseOptionProfit: newLeaseOptionProfit,
            leaseOptionCashOnCash: newLeaseOptionCashOnCash / 100,
            leaseOptionTotalCosts: newLeaseOptionInitialCosts,
            strProfit: newStrMonthlyProfit,
            strCashOnCash: newSTRCashOnCash / 100
        };
        setHighLevelProfits(profitObject);

        const newMonthlyCosts = [...monthlyCosts];
        newMonthlyCosts[0].inputs[0].value = newMonthlyPayment.toFixed(2);
        setMonthlyCosts(newMonthlyCosts);
    };

    const formatNumber = (number, dp) => {
        // Check if the number is an integer
        if (Number.isInteger(number)) {
            return number; // Return the integer as is
        }
        else {
            return Number(number).toFixed(dp); // Round to one decimal place
        }
    };

    const selectTab = (index) => {
        setSelectedTab(index);
    };

    // Function for changing rehab cost input
    const changeRehabCost = (event, val) => {
        setCostPerSqFoot(val);
        const newTotalCost = val * property.livingArea;
        setTotalRehabCost(newTotalCost);

        // Register rehab costs
        collectRehabCosts(Number(newTotalCost));
    }

    // Change cost per sq. foot
    const onChangeCostPerSqFoot = (val) => {
        setCostPerSqFoot(val);
        const newTotalCost = Number(val) * property.livingArea;
        setTotalRehabCost(newTotalCost);

        // Register rehab costs
        collectRehabCosts(Number(newTotalCost));
    };

    // Change total rehab cost
    const onChangeTotalCost = (val) => {
        setTotalRehabCost(val);
        const newCostPerSqFoot = Math.round(Number(val) / property.livingArea);
        setCostPerSqFoot(newCostPerSqFoot);

        // Register rehab costs
        collectRehabCosts(Number(val));
    };

    // This is where we send all new rehab costs
	// to then send to a cash flow update function
	const collectRehabCosts = async(rehab) => {
        const downPaymentFee = price * (downPayment / 100);
        const newInitialCosts = Number(rehab) + downPaymentFee;
        const profit = highLevelProfits.monthlyProfit;
        const cashOnCash = await calculateCashOnCash(profit, newInitialCosts);

        const newLeaseOptionDown = (leaseOptionDownPayment / 100) * leaseOptionValue;
        const newLeaseOptionInitialCosts = newInitialCosts - newLeaseOptionDown;
        const newLeaseOptionProfit = highLevelProfits.leaseOptionProfit;
        const newLeaseOptionCashOnCash = await calculateCashOnCash(newLeaseOptionProfit, newLeaseOptionInitialCosts);

        const newSTRCashOnCash = await calculateCashOnCash(highLevelProfits.strProfit, newInitialCosts);

        const profitObject = {
            monthlyProfit: profit,
            cashOnCash: cashOnCash / 100,
            totalCosts: newInitialCosts,
            leaseOptionProfit: highLevelProfits.leaseOptionProfit,
            leaseOptionCashOnCash: newLeaseOptionCashOnCash / 100,
            leaseOptionTotalCosts: newLeaseOptionInitialCosts,
            strProfit: highLevelProfits.strProfit,
            strCashOnCash: newSTRCashOnCash / 100
        };
        setHighLevelProfits(profitObject);
    };

    const onChangeMonthlyCosts = async(val, index, ii) => {
		const newArray = [...monthlyCosts];
        newArray[index].inputs[ii].value = val;
        const numberVal = Number(val);
        if ( index === 0 ) {
            const yearlyValue = numberVal * 12;
            const percentageOfTotal = (yearlyValue / property.price) * 100;
            newArray[index].inputs[ii].percentageVal = percentageOfTotal.toFixed(1);
        }
        else {
            const percentageOfTotal = (numberVal / property.financials.rent) * 100;
            newArray[index].inputs[ii].percentageVal = percentageOfTotal.toFixed(1);
        }

		setMonthlyCosts(newArray);

		const newTotal = await getTotalMonthlyCosts(newArray);
		// Register monthly costs
		collectMonthlyCosts(newTotal);
	};

    const onChangeMonthlyCostsPercentage = async(val, index, ii) => {
		const newArray = [...monthlyCosts];
        newArray[index].inputs[ii].percentageVal = val;

        // Choose the appropriate relative value - either property price or monthly rent.
        // If the relative value is property price, divide the price by 12, so as to get the
        // monthly cost, rather than the yearly cost.
        const relativeValue = index === 0 ? price / 12 : await getAppropriateRentEstimate();
        const dollarValue = (relativeValue * (val / 100));
        newArray[index].inputs[ii].value = Number(dollarValue.toFixed(2));
		setMonthlyCosts(newArray);

		const newTotal = await getTotalMonthlyCosts(newArray);
		// Register monthly costs
		collectMonthlyCosts(newTotal);
	};

    const togglePercentage = (val, index, ii) => {
		const newArray = [...monthlyCosts];
		newArray[index].inputs[ii].percentage = val;
		setMonthlyCosts(newArray);
	};

    const onChangeRent = (val) => {
        setRent(val);

        // For 1 unit layout MFH homes, this is where we segment the rent
		// between each unit
        if ( unitsRent.length !== 0 ) {
            const newUnits = [];
            for (let index = 0; index < unitsRent.length; index++) {
				const newUnit = {
					value: Math.round(val / unitsRent.length),
					label: `Unit ${index + 1}`
				};
				newUnits.push(newUnit);
            };
            setUnitsRent(newUnits);
        }

        // Register total rent
        collectTotalRent(Number(val));
    };

    const resetRentEstimates = () => {
		setRent(property.financials.rent);
        const newUnits = [];
		for (let index = 0; index < unitsRent.length; index++) {
			const newUnit = {
				value: Math.round(property.financials.rent / unitsRent.length),
				label: `Unit ${index + 1}`
			};
			newUnits.push(newUnit);
		};
		setUnitsRent(newUnits);
        collectTotalRent(property.financials.rent);
	};
    
      
    const collectTotalRent = async(val) => {
		const totalMonthlyCosts = await getTotalMonthlyCosts(monthlyCosts);
		const finalRent = Number(val);
		recaulculateMonthlyPayment(price, downPayment, interestRate, duration, amortisation, strategyPanel, finalRent, totalMonthlyCosts, ownerEquity, sellersMortgage);
	};

    const getAppropriateRentEstimate = async() => {
        return rent;
    };

    const getTotalMonthlyCosts = (vals) => {
		const totalMonthlyCosts = vals.reduce((accumulator, currentValue) => {
			currentValue.inputs.forEach(item => {
                if ( item.label !== "Mortgage payment" ) {
                    accumulator += Number(item.value);
                }
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		return totalMonthlyCosts;
	};

    const collectMonthlyCosts = async(costs) => {
		const getRent = await getAppropriateRentEstimate();
        recaulculateMonthlyPayment(price, downPayment, interestRate, duration, amortisation, strategyPanel, getRent, costs, ownerEquity, sellersMortgage);
	};

    // Function for changing the rent on INDIVIDUAL units
	// on a Multi-family home with 1 unit type, and on a
	// Multi-family house hack with 1 unit type
    const onChangeUnitRent = (val, unitIndex) => {
		
		// Setting up rent for 1 unit layout MFH homes
        const newUnits = [];
        for (let index = 0; index < unitsRent.length; index++) {
            if ( index === unitIndex ) {
                const newUnit = {
                    value: val,
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            }
            else {
                const newUnit = {
                    value: unitsRent[index].value,
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            }
        };
        setUnitsRent(newUnits);
        const newTotal = newUnits.reduce((a, b) => a + Number(b.value), 0);
        setRent(newTotal);
		collectTotalRent(newTotal);
    };

    // Function for changing the TOTAL rent for MFHs with multiple
	// unique units
	const onChangeMultiUnitTotalRent = (val) => {

        // This is if they were NOT in house hack mode
        setMultiUnitRentTotal(val);
        const totalNumber = Number(val);

        // Register total rent
        collectTotalRent(totalNumber);
        
        if ( multiUnitRent.length > 1 ) {
            if ( multiUnitRent[0].units.length < 1 ) {
                return;
            }
            const firstUnitTypeCount = multiUnitRent[0].units.length;
            const ratio = multiUnitRentRatio[1];
            
            // Equation to find new rent rates
            const newFirstUnitRent = (totalNumber / (ratio + firstUnitTypeCount));
            const newSecondUnitRent = newFirstUnitRent * ratio;
            
            const multiUnitRentClone = [...multiUnitRent];
            for (let index = 0; index < multiUnitRentClone.length; index++) {
                const element = multiUnitRentClone[index];
                for (let ii = 0; ii < element.units.length; ii++) {
                    multiUnitRentClone[index].units[ii].value = index === 0 ? Math.round(newFirstUnitRent) : Math.round(newSecondUnitRent);
                }
            }
            setMultiUnitRent(multiUnitRentClone);
        }
	};


	// Function to change indiviudal text inputs for multiple unique
	// units MFH properties
	const onChangeMultiUnitRent = (val, inputIndex, inputSubIndex) => {
		
        // NOT on house hack mode
        const multiUnitRentClone = [...multiUnitRent];
        for (let index = 0; index < multiUnitRentClone.length; index++) {
            const element = multiUnitRentClone[index].units;
            for (let ii = 0; ii < element.length; ii++) {
                if ( index === inputIndex && ii === inputSubIndex ) {
                    multiUnitRentClone[index].units[ii].value = val;
                }
            }
        };
        setMultiUnitRent(multiUnitRentClone);
        const newTotal = multiUnitRentClone.reduce((accumulator, currentValue) => {
            // Use forEach to iterate over the 'items' array of each object
            currentValue.units.forEach(item => {
                // Add the 'value' property to the accumulator
                accumulator += Number(item.value);
            });
            return accumulator; // Return the accumulator for the next iteration
        }, 0);
        setMultiUnitRentTotal(newTotal);

        // Register total rent
        collectTotalRent(newTotal);
	};

    // Function for resetting rent rates for MFHs with multiple
	// unique units
	const resetMFHMultiUnitRentEstimates = () => {
		const newUnits = [];
		const multiUnitRentClone = [...multiUnitRent];
		for (let index = 0; index < multiUnitRentClone.length; index++) {
			const element = multiUnitRentClone[index].units;
			for (let ii = 0; ii < element.length; ii++) {
				multiUnitRentClone[index].units[ii].value = multiUnitRentClone[index].units[ii].original;
			}
			newUnits.push(multiUnitRentClone[index]);
		};
		setMultiUnitRent(newUnits);
		const newUnitsTotal = newUnits.reduce((accumulator, currentValue) => {
			// Use forEach to iterate over the 'items' array of each object
			currentValue.units.forEach(item => {
			  // Add the 'value' property to the accumulator
			  accumulator += Number(item.value);
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		setMultiUnitRentTotal(newUnitsTotal);

		// Register total rent
        collectTotalRent(newUnitsTotal);
	};

    // Function to reset a specific monthly cost
	const resetMonthlyCost = async(index, ii) => {
		const newMonthlyCosts = [...monthlyCosts];
        newMonthlyCosts[index].inputs[ii].value = newMonthlyCosts[index].inputs[ii].original;

        if ( index === 0 ) {
            const originalValue = Number(newMonthlyCosts[index].inputs[ii].original) * 12;
            const percentageOfTotal = (originalValue / property.price) * 100;
            newMonthlyCosts[index].inputs[ii].percentageVal = Number(percentageOfTotal.toFixed(1));
        }
        else {
            const originalValue = Number(newMonthlyCosts[index].inputs[ii].original);
            const percentageOfTotal = (originalValue / property.financials.rent) * 100;
            newMonthlyCosts[index].inputs[ii].percentageVal = Number(percentageOfTotal.toFixed(1));
        }	

		setMonthlyCosts(newMonthlyCosts);
		const newTotal = await getTotalMonthlyCosts(newMonthlyCosts);

		collectMonthlyCosts(newTotal);
	};

    const toggleDPPercentage = (val) => {
        setDownPaymentPercentage(val);
    };

    const onChangeDownPaymentSlider = (event, value) => {
        setDownPaymentSlider(value);

        const newDownPayment = ((price * value) / 100).toFixed(2).replace(".00", "");
        onChangeDownPaymentDollar(newDownPayment);
    };

    const onChangePriceSlider = (event, value) => {
        setPriceSlider(value);

        const oneUnit = property.price / 20;
        const newPrice = (oneUnit * value).toFixed(2).replace(".00", "");
        onChangePrice(newPrice, strategyPanel);
    };

    const openFullScreenFinancials = () => {
        if ( fullScreenFinancials === true ) {
            setFullScreenFinancials(false);
        }
        else {
            setFullScreenFinancials(true);
        }
    };

    const changeMortgageBalance = async(value) => {
        setRemainingMortgage(value);

        const newOwnerEquity = (price - Number(value)) / price;
        const newEquity = (newOwnerEquity * price).toFixed(2).replace(".00", "");
        setOwnerEquityDollar(newEquity);

        const newTotal = await getTotalMonthlyCosts(monthlyCosts);
        recaulculateMonthlyPayment(price, downPayment, interestRate, duration, amortisation, strategyPanel, rent, newTotal, newOwnerEquity, sellersMortgage);
    };

    // const openYouTube = () => {
    //     recordEvent("Seller finance calculator - YouTube link clicked", {});
    //     window.open("https://youtu.be/JZiXtmebwz4", "_blank");
    // };

    const changeStrategyPanel = async(index) => {
        setStrategyPanel(index);
    };

    const onChangeLeaseValue = (val) => {
        setLeaseOptionValue(val);
        recalculateLeaseOption(Number(val), leaseOptionDownPayment, leaseOptionInterestRate);
    };

    const onChangeLeaseDownPayment = (val) => {
        if ( Number(val) > 100 ) {
            return;
        }
        setLeaseOptionDownPayment(val);
        const newDollar = (val / 100) * leaseOptionValue;
        setLeaseOptionDollarDownPayment(newDollar);
        recalculateLeaseOption(leaseOptionValue, Number(val), leaseOptionInterestRate);
    };

    const onChangeLeaseDownPaymentDollar = (val) => {
        const newPercentage = (Number(val) / leaseOptionValue) * 100;
        setLeaseOptionDownPayment(newPercentage);
        setLeaseOptionDollarDownPayment(val);
        recalculateLeaseOption(leaseOptionValue, newPercentage, leaseOptionInterestRate);
    };

    const onChangeLeaseInterest = (val) => {
        if ( Number(val) > 100 ) {
            return;
        }
        setLeaseOptionInterestRate(val);
        recalculateLeaseOption(leaseOptionValue, leaseOptionDownPayment, Number(val));
    };

    const recalculateLeaseOption = async(newPrice, newDown, newInterest) => {
        const newMortgage = await calculateMortgagePayment(newPrice, newDown, newInterest, 30);
        setLeaseOptionRent(newMortgage);

        const newDownPaymentDollar = (newDown / 100) * newPrice;
        const newMonthlyCostsTotal = await getTotalMonthlyCosts(monthlyCosts);
        const newProfit = newMortgage - (Number(monthlyPayment) + Number(sellersMortgage) + newMonthlyCostsTotal);
        const newInitialCosts = Number(totalRehabCost) + Number(dollarDownPayment) - newDownPaymentDollar;
        const newCashOnCash = await calculateCashOnCash(newProfit, newInitialCosts);

        const profitObject = {
            monthlyProfit: highLevelProfits.monthlyProfit,
            cashOnCash: highLevelProfits.cashOnCash,
            totalCosts: highLevelProfits.totalCosts,
            leaseOptionProfit: newProfit,
            leaseOptionCashOnCash: newCashOnCash / 100,
            leaseOptionTotalCosts: newInitialCosts,
            strProfit: highLevelProfits.strProfit,
            strCashOnCash: highLevelProfits.strCashOnCash
        };
        setHighLevelProfits(profitObject);
    };


    const onChangeSTRRevenue = async(val) => {
        setStrRevenue(val);

        const monthlyRent = (Number(val) / 12);
        const monthlyProfit = monthlyRent - Number(monthlyPayment) - Number(sellersMortgage);
        const newCashOnCash = await calculateCashOnCash(monthlyProfit, highLevelProfits.totalCosts);

        const profitObject = {
            monthlyProfit: highLevelProfits.monthlyProfit,
            cashOnCash: highLevelProfits.cashOnCash,
            totalCosts: highLevelProfits.totalCosts,
            leaseOptionProfit: highLevelProfits.leaseOptionProfit,
            leaseOptionCashOnCash: highLevelProfits.leaseOptionCashOnCash,
            leaseOptionTotalCosts: highLevelProfits.leaseOptionTotalCosts,
            strProfit: monthlyProfit,
            strCashOnCash: newCashOnCash / 100
        };
        setHighLevelProfits(profitObject);
    };

    const changeExitStrategy = (val) => {
        recordEvent("Exit strategy changed", {
            exitStrategy: val
        });
        setExitStrategy(val);

        if ( val !== 0 ) {
            setSelectedTab(1);
        }
    };
    
    const styles = {
        menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
		},
    };

    return (
        <div className="strategies-panel-outer-wrapper">
            <MobileStrategiesPanel
				mobileBar={mobileBar}
				property={property}
				strategyPanel={strategyPanel}
				changeStrategyPanel={changeStrategyPanel}
				strategies={strategies}
				rent={rent}
				downPayment={downPayment}
				downPaymentCost={dollarDownPayment}
				closingCostPercentage={0}
				closingCost={0}
				interestRate={interestRate}
				highLevelProfits={highLevelProfits}
				navigate={navigate}
				cityId={cityId}
				toggleMobileBottomBar={toggleMobileBottomBar}
			/>
            <div className="strategies-panel-outer-container margin-x-large">
                <div className="strategies-panel-inner-container">
                    <div className="seller-finance-calculator-outer-container">
                        <div className="seller-finance-calculator-inner-container">
                            <div className="seller-finance-title-container-row">
                                <div className="seller-finance-calculator-title-container">
                                    <h2 className="heading-large-semibold colour-primary margin-x-small">
                                        SubTo
                                    </h2>
                                    <div className="seller-finance-calculator-switch-container relative-container">
                                        <NewSwitch
                                            firstOption={strategyPanel === 0 ? true : false}
                                            pricingSwitch={changeStrategyPanel}
                                            textOptions={strategiesText}
                                            large={true}
                                        />
                                    </div>
                                </div>
                                <div className="seller-finance-title-right-container">
                                    {
                                        fullScreenFinancials === true ?
                                        <svg 
                                            width="15" 
                                            height="16"
                                            viewBox="0 0 15 16" 
                                            fill="none" 
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="block-text margin-left-medium cursor-pointer"
                                            onClick={() => openFullScreenFinancials()}
                                        >
                                            <path 
                                                fillRule="evenodd" 
                                                clipRule="evenodd" 
                                                d="M15.0795 1.44782C15.4 1.09182 15.3713 0.543356 15.0153 0.222805C14.6592 -0.0977454 14.1108 -0.0690019 13.7902 0.287006L7.99975 6.718L2.20929 0.287037C1.88874 -0.0689705 1.34028 -0.097714 0.984271 0.222837C0.628264 0.543388 0.59952 1.09185 0.920071 1.44785L6.83254 8.01432L0.945855 14.5521C0.625304 14.9081 0.654048 15.4566 1.01006 15.7772C1.36606 16.0977 1.91452 16.069 2.23507 15.713L7.99975 9.31064L13.7645 15.713C14.085 16.069 14.6335 16.0977 14.9895 15.7772C15.3455 15.4566 15.3742 14.9082 15.0537 14.5522L9.16696 8.01432L15.0795 1.44782Z" 
                                                fill="#2F4858"
                                            />
                                        </svg>
                                        :
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="16" 
                                            height="16" 
                                            viewBox="0 0 16 16" 
                                            fill="none"
                                            className="block-text margin-left-medium cursor-pointer"
                                            onClick={() => openFullScreenFinancials()}
                                        >
                                            <path 
                                                d="M7.74036 0C7.05029 0 6.49087 0.559417 6.49087 1.24949C6.49087 1.93957 7.05029 2.49899 7.74036 2.49899H11.734L2.49899 11.734V7.74036C2.49899 7.05029 1.93957 6.49087 1.24949 6.49087C0.559417 6.49087 0 7.05029 0 7.74036V14.7505C0 15.4406 0.559417 16 1.24949 16H8.25963C8.94971 16 9.50913 15.4406 9.50913 14.7505C9.50913 14.0604 8.94971 13.501 8.25963 13.501H4.26603L13.501 4.26603V8.25963C13.501 8.94971 14.0604 9.50913 14.7505 9.50913C15.4406 9.50913 16 8.94971 16 8.25963V1.24949C16 0.559417 15.4406 0 14.7505 0H7.74036Z" 
                                                fill="#2F4858"
                                            />
                                        </svg>
                                    }
                                </div>
                            </div>
                            <div className="seller-finance-title-border margin-tops">
                            </div>
                            <div className="seller-finance-calculator-panel-row">
                                <ul className="seller-finance-calculator-options-container">
                                    <li
                                        className="side-navigation-element-container"
                                    >
                                        <Box>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="exit-strategy-selector-label"
                                                    id="exit-strategy-selector"
                                                    value={exitStrategy}
                                                    label=""
                                                    input={<CustomSelect />}
                                                    onChange={(e) => changeExitStrategy(e.target.value)}
                                                > 
                                                    <MenuItem
                                                        value={0}
                                                        style={styles.menuItem}
                                                    >
                                                        <span className="body-regular colour-primary">
                                                            LTR
                                                        </span>
                                                    </MenuItem>	
                                                    <MenuItem 
                                                        value={1}
                                                        style={styles.menuItem}
                                                    >
                                                        <span className="body-regular colour-primary">
                                                            Lease option
                                                        </span>
                                                    </MenuItem>
                                                    <MenuItem 
                                                        value={2}
                                                        style={styles.menuItem}
                                                    >
                                                        <span className="body-regular colour-primary">
                                                            STR
                                                        </span>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </li>
                                    {
                                        tabs.map((item, index) =>
                                            <li
                                                className="side-navigation-element-container"
                                                key={index}
                                                onClick={() => selectTab(index)}
                                            >
                                                <div className={"button-row side-navigation-element-inner-container cursor-pointer " + (index === selectedTab ? "side-navigation-option-active" : "")}>
                                                    <span className={selectedTab === index ? "body-semibold colour-primary" : "body-regular text-link"}>
                                                        {item.title}
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                                {
                                    selectedTab === 0 ?
                                    <div className="subto-purchase-outer-container">
                                        <div className="subto-owner-equity-grid bg-colour-light margin-medium">
                                            {
                                                ownerEquityInfo.map((item, index) =>
                                                    <div 
                                                        className="subto-owner-equity-element"
                                                        key={index}
                                                    >
                                                        <span className="label-regular colour-secondary block-text margin-x-x-small">
                                                            {item.label}
                                                        </span>
                                                        <span className="body-semibold colour-primary block-text">
                                                            {item.value}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="subto-divider-line margin-tops">
                                        </div>
                                        <div className="subto-first-step-container">
                                            <h3 className="body-semibold colour-quaternary margin-medium">
                                                1. Finance the Seller’s Equity
                                            </h3>
                                            <Accordion
                                                className="purchase-accordion-outer-container"
                                                expanded={purchaseAccordion}
                                                onChange={handlePurchaseAccordion}
                                                sx={{ 
                                                        boxShadow: 'none',
                                                        '&:before': {
                                                        display: 'none',
                                                    }}
                                                }
                                            >
                                                <AccordionSummary
                                                    sx={{ marginBottom: '8px' }}
                                                >
                                                    <div className="subto-accordion-title-container">
                                                        <span className="label-semibold-caps text-link block-text subtext-margin-right">
                                                            PURCHASE
                                                        </span>
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="8" 
                                                            height="8" 
                                                            viewBox="0 0 8 8" 
                                                            fill="none"
                                                            className={purchaseAccordion === false ? "reverse" : ""}
                                                        >
                                                            <path 
                                                                d="M0.243947 6.75675C-0.0813155 6.44365 -0.0813156 5.92874 0.243946 5.61564L3.99999 2L7.75603 5.61564C8.08129 5.92874 8.08129 6.44365 7.75603 6.75675C7.44029 7.06069 6.93514 7.06069 6.6194 6.75675L3.99999 4.23525L1.38057 6.75675C1.06483 7.06069 0.559682 7.06069 0.243947 6.75675Z" 
                                                                fill="#2F4858"
                                                            />
                                                        </svg>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="subto-accordion-body-row">
                                                        <div className="subto-price-container">
                                                            <div className="seller-finance-input-container margin-x-small">
                                                                <FinancialsInput
                                                                    value={formatPrice(price)}
                                                                    type="text"
                                                                    label="Offer price"
                                                                    id={`sf-price-input`}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                    }}
                                                                    onChange={(text) => onChangePrice(text.target.value.replaceAll(",", ""), strategyPanel)}
                                                                />
                                                            </div>
                                                            {
                                                                Number(price) !== Number(property.price) ?
                                                                <div 
                                                                    className="subto-price-reset-container"
                                                                    onClick={() => onChangePrice(Number(property.price), strategyPanel)}
                                                                >
                                                                    <div>
                                                                        <span className="label-regular colour-secondary block-text text-italic">
                                                                            {percentage.format(Math.abs((price / property.price) - 1))}
                                                                            {" "}
                                                                            {price > property.price ? "above " : "below "}
                                                                            asking price
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="label-semibold colour-link">
                                                                            Reset
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <Stack 
                                                                spacing={2} 
                                                                direction="row" 
                                                                sx={{ mb: 1, px: 1 }} 
                                                                alignItems="center"
                                                            >
                                                                <Slider
                                                                    aria-label="Price"
                                                                    value={priceSlider}
                                                                    onChange={onChangePriceSlider}
                                                                    min={0}
                                                                    step={0.1}
                                                                    max={25}
                                                                    disabled={false}
                                                                    sx={{
                                                                        color: '#CFBCAD',
                                                                        height: '5px',
                                                                        '& .MuiSlider-track': {
                                                                            border: 'none',
                                                                        },
                                                                        '& .MuiSlider-thumb': {
                                                                            width: 24,
                                                                            height: 24,
                                                                            backgroundColor: '#fff',
                                                                            '&:before': {
                                                                                boxShadow: '0px 0px 10px 0px #99959180',
                                                                            },
                                                                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                                boxShadow: 'none',
                                                                            },
                                                                        }
                                                                    }}
                                                                />
                                                            </Stack>
                                                        </div>
                                                        <div className="subto-owner-equity-container">
                                                            <div className="seller-finance-input-container margin-x-small">
                                                                <FinancialsInput
                                                                    value={formatPrice(ownerEquityDollar)}
                                                                    type="text"
                                                                    label="Owner equity"
                                                                    id={`sf-equity-input`}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                    }}
                                                                    onChange={(text) => onChangeOwnerEquity(text.target.value.replaceAll(",", ""), strategyPanel)}
                                                                />
                                                            </div>
                                                            <span className="label-regular colour-secondary block-text text-italic">
                                                                Est. Owner Equity is {percentage.format(ownerEquity)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="subto-accordion-body-row">
                                                        <div className="subto-down-payment-container">
                                                            <div className=" margin-x-small">
                                                                <div className={"seller-finance-input-container margin-x-small " + (downPaymentPercentage === true ? "monthly-costs-input-container-right" : "")}>
                                                                    <FinancialsInput
                                                                        value={downPaymentPercentage === false ? formatPrice(dollarDownPayment) : `${downPayment}`.length > 4 ? Number(downPayment).toFixed(2) : downPayment}
                                                                        type="text"
                                                                        label="Down payment"
                                                                        id={`sf-dp-dollars-input`}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment className={downPaymentPercentage === false ? "financials-panel-active-option" : "financials-panel-dormant-option"} onClick={() => toggleDPPercentage(false)} position="start">$</InputAdornment>,
                                                                            endAdornment: <InputAdornment className={downPaymentPercentage === true ? "financials-panel-active-option" : "financials-panel-dormant-option"} onClick={() => toggleDPPercentage(true)} position="end">%</InputAdornment>
                                                                        }}
                                                                        onChange={(text) => downPaymentPercentage === false ? onChangeDownPaymentDollar(text.target.value.replaceAll(",", "")) : onChangeDownPayment(text.target.value)}
                                                                    />
                                                                </div>
                                                                <span className="label-regular colour-secondary block-text text-italic">
                                                                    {percentageCompact.format(dollarDownPayment / price)} of offer price, {percentageCompact.format(dollarDownPayment / ownerEquityDollar)} of equity
                                                                </span>
                                                            </div>
                                                            <Stack 
                                                                spacing={2} 
                                                                direction="row" 
                                                                sx={{ mb: 1, px: 1 }} 
                                                                alignItems="center"
                                                            >
                                                                <Slider
                                                                    aria-label="Down Payment Dollar"
                                                                    value={downPaymentSlider}
                                                                    onChange={onChangeDownPaymentSlider}
                                                                    min={0}
                                                                    step={1}
                                                                    max={100}
                                                                    disabled={false}
                                                                    sx={{
                                                                        color: '#CFBCAD',
                                                                        height: '5px',
                                                                        '& .MuiSlider-track': {
                                                                            border: 'none',
                                                                        },
                                                                        '& .MuiSlider-thumb': {
                                                                            width: 24,
                                                                            height: 24,
                                                                            backgroundColor: '#fff',
                                                                            '&:before': {
                                                                                boxShadow: '0px 0px 10px 0px #99959180',
                                                                            },
                                                                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                                boxShadow: 'none',
                                                                            },
                                                                        }
                                                                    }}
                                                                />
                                                            </Stack>
                                                        </div>
                                                        <div className="subto-seller-payment-container">
                                                            <div className="seller-finance-input-container margin-x-small">
                                                                <FinancialsInputRed
                                                                    value={formatPrice(monthlyPayment)}
                                                                    type="text"
                                                                    label="Monthly payment to seller"
                                                                    id={`sf-dp-dollars-input`}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                    }}
                                                                    disabled={true}
                                                                    onChange={(text) => onChangeDownPaymentDollar(text.target.value.replaceAll(",", ""))}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion
                                                className="purchase-accordion-outer-container"
                                                expanded={termsAccordion}
                                                onChange={handleTermsAccordion}
                                                sx={{ 
                                                        boxShadow: 'none',
                                                        '&:before': {
                                                        display: 'none',
                                                    }}
                                                }
                                            >
                                                <AccordionSummary
                                                    sx={{ marginBottom: '8px' }}
                                                >
                                                    <div className="subto-accordion-title-container">
                                                        <span className="label-semibold text-link block-text subtext-margin-right">
                                                            <span className="capitalise">TERMS</span>{" "}(interest & balloon)
                                                        </span>
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="8" 
                                                            height="8" 
                                                            viewBox="0 0 8 8" 
                                                            fill="none"
                                                            className={termsAccordion === false ? "reverse" : ""}
                                                        >
                                                            <path 
                                                                d="M0.243947 6.75675C-0.0813155 6.44365 -0.0813156 5.92874 0.243946 5.61564L3.99999 2L7.75603 5.61564C8.08129 5.92874 8.08129 6.44365 7.75603 6.75675C7.44029 7.06069 6.93514 7.06069 6.6194 6.75675L3.99999 4.23525L1.38057 6.75675C1.06483 7.06069 0.559682 7.06069 0.243947 6.75675Z" 
                                                                fill="#2F4858"
                                                            />
                                                        </svg>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="seller-finance-calculator-grid-container">
                                                        <div className="seller-finance-grid-element">
                                                            <div className="seller-finance-input-container margin-x-small">
                                                                <FinancialsInput
                                                                    value={interestRate}
                                                                    type="text"
                                                                    label="Interest rate"
                                                                    id={`sf-int-input`}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                        style: {
                                                                            maxWidth: '100px'
                                                                        }
                                                                    }}
                                                                    onChange={(text) => onChangeInterestRate(text.target.value)}
                                                                />
                                                            </div>
                                                            <span className="body-regular colour-secondary text-italic block-text">
                                                                Get as low as possible!
                                                            </span>
                                                        </div>
                                                        <div className="seller-finance-grid-element">
                                                            <div className="seller-finance-input-container margin-x-small">
                                                                <FinancialsInput
                                                                    value={amortisation === "" ? "" : amortisation / 12}
                                                                    type="text"
                                                                    label="Amortization schedule"
                                                                    id={`sf-amortisation-input`}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">years</InputAdornment>,
                                                                        style: {
                                                                            maxWidth: '100px'
                                                                        }
                                                                    }}
                                                                    onChange={(text) => onChangeAmortisation(text.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="seller-finance-grid-element">
                                                            <div className="seller-finance-input-container margin-x-small">
                                                                <FinancialsInput
                                                                    value={formatPrice(balloonPayment)}
                                                                    type="text"
                                                                    label="Balloon payment"
                                                                    id={`sf-bp-input`}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    disabled={true}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                    }}
                                                                    onChange={(text) => onChangeBalloon(text.target.value.replaceAll(",", ""))}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="seller-finance-grid-element">
                                                            <div className="seller-finance-input-container margin-x-small">
                                                                <FinancialsInput
                                                                    value={duration === "" ? "" : duration / 12}
                                                                    type="text"
                                                                    label="Balloon due in"
                                                                    id={`sf-duration-input`}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">years</InputAdornment>,
                                                                        style: {
                                                                            maxWidth: '100px'
                                                                        }
                                                                    }}
                                                                    onChange={(text) => onChangeDuration(text.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <div className="subto-divider-line margin-tops">
                                        </div>
                                        <div className="subto-second-step-container">
                                            <h3 className="body-semibold colour-quaternary margin-medium">
                                                2. Pay the seller's mortgage
                                            </h3>
                                            <div className="subto-second-step-row-container">
                                                <div className="subto-second-step-element">
                                                    <FinancialsInput
                                                        value={formatPrice(remainingMortgage)}
                                                        type="text"
                                                        label="Remaining mortgage balance"
                                                        id={`sf-mortgage-balance-input`}
                                                        onWheel={(e) => e.target.blur()}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                        }}
                                                        disabled={false}
                                                        onChange={(text) => changeMortgageBalance(text.target.value.replaceAll(",", ""))}
                                                    />
                                                </div>
                                                <div className="subto-second-step-element">
                                                    <FinancialsInputRed
                                                        value={formatPrice(sellersMortgage)}
                                                        type="text"
                                                        label="Mortgage payment to Bank"
                                                        id={`sf-mortgage-payment-input`}
                                                        onWheel={(e) => e.target.blur()}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                        }}
                                                        onChange={(text) => onChangeSellersMortgage(text.target.value.replaceAll(",", ""))}
                                                        disabled={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    selectedTab === 1 ?
                                        exitStrategy === 0 ?
                                        <RentTab
                                            rent={rent}
                                            onChangeRent={onChangeRent}
                                            blurred={blurred}
                                            defaultRent={property.financials.rent}
                                            strategyPanel={0}
                                            property={property}
                                            unitsRent={unitsRent}
                                            houseHackUnitsRent={null}
                                            onChangeUnitRent={onChangeUnitRent}
                                            houseHackRent={null}
                                            unitLiving={null}
                                            setUnitLiving={null}
                                            defaultHouseHackRent={null}
                                            onChangeUnitLiving={null}
                                            houseHackBedroomsRent={null}
                                            onChangeBedroomRent={null}
                                            onChangeBedroomLiving={null}
                                            resetRentEstimates={resetRentEstimates}
                                            multipleUniqueUnits={multipleUniqueUnits}
                                            multiUnitRent={multiUnitRent}
                                            multiUnitRentTotal={multiUnitRentTotal}
                                            defaultMultiUnitRent={defaultMultiUnitRent}
                                            houseHackMultiUnitsRent={null}
                                            multiUnitTypeUnitLiving={multiUnitTypeUnitLiving}
                                            multiUnitHouseHackRentTotal={null}
                                            defaultMultiUnitHouseHackRentTotal={null}
                                            multiUnitRentRatio={multiUnitRentRatio}
                                            onChangeMultiUnitTotalRent={onChangeMultiUnitTotalRent}
                                            onChangeMultiUnitLiving={null}
                                            resetMFHMultiUnitRentEstimates={resetMFHMultiUnitRentEstimates}
                                            onChangeMultiUnitRent={onChangeMultiUnitRent}
                                            onChangeSFHHouseHackTotal={null}
                                            onChangeValueAddInput={null}
                                            valueAddOptions={[]}
                                            onChangeValueAddOptions={null}
                                            viewRentalComps={viewRentalComps}
                                        />
                                        :
                                        exitStrategy === 1 ?
                                        <LeaseOptionRent
                                            blurred={blurred}
                                            property={property}
                                            downPaymentPercentage={downPaymentPercentage}
                                            toggleDPPercentage={toggleDPPercentage}
                                            leaseOptionValue={leaseOptionValue}
                                            leaseOptionDownPayment={leaseOptionDownPayment}
                                            leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
                                            leaseOptionInterestRate={leaseOptionInterestRate}
                                            onChangeLeaseValue={onChangeLeaseValue}
                                            onChangeLeaseDownPayment={onChangeLeaseDownPayment}
                                            onChangeLeaseDownPaymentDollar={onChangeLeaseDownPaymentDollar}
                                            onChangeLeaseInterest={onChangeLeaseInterest}
                                            leaseOptionRent={leaseOptionRent}
                                        />
                                        :
                                        <STRRent
                                            strRevenue={strRevenue}
                                            blurred={blurred}
                                            onChangeSTRRevenue={onChangeSTRRevenue}
                                        />
                                    :
                                    selectedTab === 2 ?
                                    <RehabTab
                                        property={property}
                                        costPerSqFoot={costPerSqFoot}
                                        totalRehabCost={totalRehabCost}
                                        changeRehabCost={changeRehabCost}
                                        onChangeCostPerSqFoot={onChangeCostPerSqFoot}
                                        onChangeTotalCost={onChangeTotalCost}
                                        defaultTotalRehabCost={property.livingArea !== undefined && property.livingArea !== null ? Number(property.livingArea) * Number(defaultCostPerSqFoot) : 0}
                                    />
                                    :
                                    <MonthlyCostsTab
                                        strategyPanel={1}
                                        monthlyCosts={monthlyCosts}
                                        onChangeMonthlyCosts={onChangeMonthlyCosts}
                                        togglePercentage={togglePercentage}
                                        onChangeMonthlyCostsPercentage={onChangeMonthlyCostsPercentage}
                                        resetMonthlyCost={resetMonthlyCost}
                                        exitStrategy={exitStrategy}
                                    />
                                }
                            </div>
                            {/* {
                                selectedTab === 0 ?
                                <div className="seller-finance-calculator-bottom-row margin-large">
                                    <div className="seller-finance-calculator-results-container bg-colour-light">
                                        <h3 className="heading-small-semibold colour-primary margin-medium">
                                            Good for you
                                        </h3>
                                        <div className="margin-medium">
                                            <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                                Mortgage to seller
                                            </span>
                                            <span className="body-semibold colour-primary block-text">
                                                {formatterLong.format(monthlyPayment).replace(".00", "")}
                                                <span className="body-regular">
                                                    /month
                                                </span>
                                            </span>
                                        </div>
                                        {
                                            oldMortgage > monthlyPayment ?
                                            <span className="body-semibold colour-green">
                                                {formatterLong.format(oldMortgage - monthlyPayment).replace(".00", "")} cheaper than standard mortgage
                                            </span>
                                            :
                                            <span className="body-semibold colour-error">
                                                {formatterLong.format(monthlyPayment - oldMortgage).replace(".00", "")} pricier than standard mortgage
                                            </span>
                                        }
                                    </div>
                                    <div className="seller-finance-calculator-seller-compensation bg-colour-light">
                                        <h3 className="heading-small-semibold colour-primary margin-medium">
                                            Good for the seller
                                        </h3>
                                        <div className="seller-finance-seller-compensation-row margin-medium">
                                            <div className="seller-finance-seller-compensation-element subtext-margin-right-x-large">
                                                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                                    Sale price
                                                </span>
                                                <span className="body-semibold colour-primary">
                                                    {formatterLong.format(price).replace(".00", "")}
                                                </span>
                                            </div>
                                            <div className="seller-finance-seller-compensation-element">
                                                <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                                    Sale + Interest
                                                </span>
                                                <span className="body-semibold colour-primary">
                                                    {formatterLong.format(sellerCompensation).replace(".00", "")}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="seller-finance-seller-compensation-element margin-medium">
                                            <span className="label-regular-caps colour-secondary block-text margin-x-x-small">
                                                Income from interest
                                            </span>
                                            <span className="body-semibold colour-primary">
                                                {formatterLong.format(Number(sellerCompensation) - Number(price)).replace(".00", "")}
                                            </span>
                                        </div>
                                        {
                                            property.price > sellerCompensation ?
                                            <span className="body-semibold colour-error">
                                                {formatterLong.format(property.price - sellerCompensation).replace(".00", "")} less than asking price
                                            </span>
                                            :
                                            <span className="body-semibold colour-green">
                                                {formatterLong.format(sellerCompensation - property.price).replace(".00", "")} more than asking price
                                            </span>
                                        }
                                    </div>
                                </div>
                                :
                                null
                            } */}
                            {
                                highLevelProfits !== "" ?
                                <StrategiesPanelFooter
                                    highLevelProfits={highLevelProfits}
                                    strategyPanel={0}
                                    blurred={blurred}
                                    cashInDeal={null}
                                    exitStrategy={exitStrategy}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SubtoCalculator;