import React from 'react';
import '../styles/DefaultUserImage.css';

function DefaultUserImage(props) {
    const size = props.size;
    return (
        <div className={"default-user-image-container " + (size === "small" ? "default-user-image-small" : "default-user-image-large")}>
            <svg 
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
            >
                <path 
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7907 8.15764C11.8484 7.32035 12.5269 6.02513 12.5269 4.57143C12.5269 2.0467 10.4802 0 7.95548 0C5.43075 0 3.38405 2.0467 3.38405 4.57143C3.38405 6.05713 4.0928 7.3773 5.19062 8.21228C3.24258 9.3819 2.09209 11.7061 2.4875 14.118C2.55326 14.5191 2.61256 14.9046 2.66203 15.2608C2.72715 15.7297 3.16005 16.057 3.62893 15.9918C4.09782 15.9267 4.42514 15.4938 4.36001 15.0249C4.30816 14.6516 4.24661 14.2518 4.1792 13.8407C3.77685 11.3865 5.61621 9.14286 8.03857 9.14286C10.4313 9.14286 12.2372 11.314 11.8016 13.6667L11.5571 14.9868C11.4709 15.4523 11.7784 15.8995 12.2438 15.9857C12.7093 16.0719 13.1565 15.7644 13.2427 15.2989L13.4872 13.9788C13.9343 11.5646 12.7512 9.28238 10.7907 8.15764ZM10.8126 4.57143C10.8126 6.14938 9.53344 7.42857 7.95548 7.42857C6.37753 7.42857 5.09834 6.14938 5.09834 4.57143C5.09834 2.99347 6.37753 1.71429 7.95548 1.71429C9.53344 1.71429 10.8126 2.99347 10.8126 4.57143Z"
                    fill="#2F4858"
                />
            </svg>
        </div>
    )
};

export default DefaultUserImage;