import React from 'react';
import '../styles/SignUp.css';
import SignUpComponent from '../authentication/SignUpComponent';
import { Header, Footer } from '../components';
import { useLocation } from 'react-router-dom';

function BuySignUp() {

	const location = useLocation();
	const state = location.state;
	return (
		<div className="sign-up-outer-container">
			<Header
				subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
			/>
			<div className="sign-up-inner-container">
				<h1 className="heading-large-semibold colour-primary text-align-center">
					Let's get you signed up
				</h1>
				<SignUpComponent 
					route={state.route}
					loginRoute="/buy-login"
					state={state}
				/>
			</div>
			<Footer />
		</div>
	)
}

export default BuySignUp;