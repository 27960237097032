import React from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CustomSelect, bathroomOptions, bedroomOptions, colour, squareFootageRange, FormInput } from '../styles/GlobalStyles';
import { Bathrooms, BedroomBlack } from '../assets';

function ChoosePropertySpecifics(props) {
    const bedrooms = props.bedrooms;
    const setBedrooms = props.setBedrooms;
    const bathrooms = props.bathrooms;
    const setBathrooms = props.setBathrooms;
    const squareFootage = props.squareFootage;
    const setSquareFootage = props.setSquareFootage;
    const constructionYears = props.constructionYears;
    const setConstructionYears = props.setConstructionYears;

    const changeConstruction = (val, index) => {
		const newArray = constructionYears.slice(0);
		newArray[index] = val;
		setConstructionYears(newArray);
	};

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		},
        fullWidth: {
            width: '100%'
        }
    };

    return (
        <div className="choose-property-specifics-outer-container">
            <div className="choose-property-specifics-inner-container">
                <div className="choose-property-specifics-row margin-large">
                    <div className="choose-property-specifics-element-container">
                        <div className="choose-property-specifics-label-row margin-x-x-small">
                            <img
                                src={BedroomBlack}
                                className="choose-property-specifics-icon"
                                alt="Bedrooms"
                            />
                            <span className="body-semibold colour-primary">
                                Beds
                            </span>
                        </div>
                        <Box sx={styles.fullWidth}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="beds-select-label"
                                    id="bedroom-select"
                                    value={bedrooms}
                                    label=""
                                    onChange={(event) => setBedrooms(event.target.value)}
                                    input={<CustomSelect />}
                                >
                                    {
                                        bedroomOptions.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={item.value}
                                                style={styles.menuItem}
                                            >
                                                {item.label}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="choose-property-specifics-element-container">
                        <div className="choose-property-specifics-label-row margin-x-x-small">
                            <img
                                src={Bathrooms}
                                className="choose-property-specifics-icon"
                                alt="Bathrooms"
                            />
                            <span className="body-semibold colour-primary">
                                Baths
                            </span>
                        </div>
                        <Box sx={styles.fullWidth}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="baths-select-label"
                                    id="baths-select"
                                    value={bathrooms}
                                    label=""
                                    onChange={(event) => setBathrooms(event.target.value)}
                                    input={<CustomSelect />}
                                >
                                    {
                                        bathroomOptions.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={item.value}
                                                style={styles.menuItem}
                                            >
                                                {item.label}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
                <div className="margin-x-x-small">
                    <span className="body-semibold colour-primary">
                        Square footage
                    </span>
                </div>
                <div className="choose-property-specifics-row margin-large">
                    <div className="choose-property-specifics-element-container">
                        <Box style={styles.fullWidth}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="min-sq-footage-label"
                                    id="min-sq-footage-select"
                                    value={squareFootage[0]}
                                    label=""
                                    onChange={(event) => setSquareFootage([event.target.value, squareFootage[1]])}
                                    input={<CustomSelect />}
                                >
                                    {
                                        squareFootageRange.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={item.value}
                                                style={styles.menuItem}
                                                disabled={squareFootage[1] <= item.value ? true : false}
                                            >
                                                {item.value === 0 || item.value === 999999 ? item.label : item.value}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="choose-property-specifics-element-container">
                        <Box style={styles.fullWidth}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="max-sq-footage-label"
                                    id="max-sq-footage-select"
                                    value={squareFootage[1]}
                                    label=""
                                    onChange={(event) => setSquareFootage([squareFootage[0], event.target.value])}
                                    input={<CustomSelect />}
                                >
                                    {
                                        squareFootageRange.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={item.value}
                                                style={styles.menuItem}
                                                disabled={squareFootage[0] >= item.value ? true : false}
                                            >
                                                {item.value === 0 || item.value === 999999 ? item.label : item.value}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>



                <div className="margin-x-x-small">
                    <span className="body-semibold colour-primary">
                        Year built
                    </span>
                </div>
                <div className="choose-property-specifics-row margin-large">
                    <div className="choose-property-specifics-element-container">
                        <FormInput
                            value={constructionYears[0]}
                            type="text"
                            id={`construction-min-input`}
                            placeholder="1800"
                            label="Min"
                            inputProps={{
                                maxLength: 4
                            }}
                            onChange={(text) => changeConstruction(text.target.value, 0)}
                        />
                    </div>
                    <div className="choose-property-specifics-element-container">
                        <FormInput
                            value={constructionYears[1]}
                            type="text"
                            id={`construction-max-input`}
                            placeholder="2024"
                            label="Max"
                            inputProps={{
                                maxLength: 4
                            }}
                            onChange={(text) => changeConstruction(text.target.value, 1)}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
};

export default ChoosePropertySpecifics;