import React from 'react';
import '../styles/Modal.css';
import { ActionButton, WhiteActionButton } from '../styles/GlobalStyles';

function Modal(props) {
    const title = props.title;
    const description = props.description;
    const closeButtonText = props.closeButtonText;
    const saveButtonText = props.saveButtonText;
    const closeModal = props.closeModal;
    const saveModal = props.saveModal;

    return (
        <div className="modal-outer-container bg-colour-light">
            <div className="modal-inner-container">
                <div className="modal-title-container margin-medium">
                    <h2 className="heading-large-semibold colour-primary">
                        {title}
                    </h2>
                </div>
                <div className="modal-description-container margin-x-x-x-large">
                    <span className="body-regular colour-primary">
                        {description}
                    </span>
                </div>
                <div className="modal-buttons-container">
                    <div className="modal-close-button-container">
                        <WhiteActionButton
                            onClick={() => closeModal()}
                        >
                            {closeButtonText}
                        </WhiteActionButton>
                    </div>
                    <div className="modal-save-button-container">
                        <ActionButton
                            onClick={() => saveModal()}
                        >
                            {saveButtonText}
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Modal;