import React from 'react';
import '../styles/StreetView.css';
import { PinBlue, RedirectArrowBlue } from '../assets';
import { recordEvent } from '../functions';

function StreetView(props) {
    const property = props.property;
    const googleLink = `https://www.google.com/maps/place/${property.address.streetAddress.replace(" ", "+")},+${property.address.city},+${property.address.state}+${property.address.zipcode}`;
    
    const openGoogle = () => {
        recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
    };

    return (
        <div 
            className="street-view-container"
            onClick={() => openGoogle()}
        >
            <img
                src={PinBlue}
                className="street-view-location-pin"
                alt="Location Pin"
            />
            <span className="body-regular-underline colour-link block-text subtext-margin-right">
                Street view
            </span>
            <img
                src={RedirectArrowBlue}
                className="street-view-redirect-arrow"
                alt="redirect-arrow"
            />
        </div>
    )
}

export default StreetView;