import React, { useState } from 'react';
import '../styles/Chip.css';
import { ChangeDot } from '../assets';
import { DefaultTooltip, RecommendedTab } from './';

function Chip(props) {
    const label = props.label;
    const selected = props.selected;
    const index = props.index;
    const func = props.func
    const changeIcon = props.changeIcon;
    const recommended = props.recommended;
    const hover = props.hover;
    const leave = props.leave;
    const disabled = props.disabled;
    const [showTooltip,  setShowTooltip] = useState(false);

    return (
        <div 
            className={"chip-container " + (selected === index ? "chip-selected" : "chip-unselected") + (recommended === true ? " relative-container" : "") + (disabled === true ? " chip-disabled" : "")}
            onClick={() => func === null || disabled === true ? null : func(index)}
            onMouseEnter={() => hover === null ? null : hover(index)}
            onMouseLeave={() => leave === null ? null : leave(index)}
        >
            <span className="body-regular colour-primary">
                {label}
            </span>
            {
                changeIcon === true ?
                <img
                    src={ChangeDot}
                    className="chip-change-icon"
                    alt="Changes Made"
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                />
                :
                null
            }
            {
                showTooltip === true ?
                <DefaultTooltip
                    title="Changes Made"
                    description={`You have made changes to the ${label} tab.`}
                    changeIcon={changeIcon}
                />
                :
                null
            }
            {
                recommended === true ?
                <RecommendedTab
                    index={index}
                />
                :
                null
            }
        </div>
    )
}

export default Chip;