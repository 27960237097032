import React, { useEffect } from 'react';
import { Header, Footer } from '../components';
import '../styles/ErrorPage.css';
import { EmptyCup } from '../assets';

function ErrorPage() {

	const email = "info@coffeeclozers.com";

	useEffect(() => {
		document.title = "Error 404";
	}, []);

	return (
		<div className="error-page-outer-container">
			<Header
				subscriptions={null}
				users={null}
				queries={[true, true]}
				mobileNav={true}
			/>
			<div className="error-page-inner-container">
				<div className="error-page-image-container">
					<img
						src={EmptyCup}
						className="error-page-image"
						alt="Error 404"
					/>
				</div>
				<div className="error-page-body-container">
					<h1 className="heading-large-semibold colour-primary text-align-center margin-medium">
						🫣 Oops! Page not found
					</h1>
					<span className="body-regular colour-primary block-text text-align-center margin-medium">
						This page seems to be taking a coffee break...
						Try checking the address or URL for typos.
					</span>
					<span className="label-regular colour-tertiary block-text text-align-center">
						(Don't worry you definitely typed the correct URL, it's the keyboard's fault.)
					</span>
				</div>
				<div className="error-page-footer-container">
					<span className="body-semibold colour-primary block-text error-page-text-margin">
						Need assistance?
					</span>
					<a 
						href={`mailto:${email}`}
						className="body-regular-underline colour-link"
					>
						Contact us here
					</a>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default ErrorPage;