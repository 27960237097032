import React from 'react';
import '../styles/MapLegend.css';
import { neighbourhoodColours, colour } from '../styles/GlobalStyles';

function MapLegend(props) {
    const hoverMapLegend = props.hoverMapLegend;
    const setHoverMapLegend = props.setHoverMapLegend;
    const neighbourhoodOption = props.neighbourhoodOption;
    const dark = colour.heatmapHotHex;
    const light = colour.heatmapColdHex;

    const options = [
        {
            label: "A"
        },
        {
            label: "B+"
        },
        {
            label: "B"
        },
        {
            label: "B-"
        },
        {
            label: "C+"
        },
        {
            label: "C"
        },
        {
            label: "C-"
        },
        {
            label: "D"
        }
    ];
    
    const styles = {
        first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        },
        gradient: {
            background: `linear-gradient(to right, ${light}, ${dark})`
        }
    };

    return (
        <div className="map-legend-outer-container map-legend-default">
            <div className="map-legend-inner-container">
                {
                    neighbourhoodOption === null || neighbourhoodOption === 0 ?
                    options.map((item, index) => 
                        <div 
                            className={"map-legend-element " + (hoverMapLegend === index ? "map-legend-hover-element" : "")}
                            style={
                                index === 0 ? styles.first :
                                index === 1 ? styles.second :
                                index === 2 ? styles.third :
                                index === 3 ? styles.fourth :
                                index === 4 ? styles.fifth :
                                index === 5 ? styles.sixth :
                                index === 6 ? styles.seventh :
                                styles.eighth
                            }
                            key={index}
                            onMouseEnter={() => setHoverMapLegend == null ? null : setHoverMapLegend(index)}
                            onMouseLeave={() => setHoverMapLegend == null ? null : setHoverMapLegend(null)}
                        >
                            <span className={"body-regular " + (index > 5 ? "colour-white" : "colour-primary ")}>
                                {item.label}
                            </span>
                        </div>
                    )
                    :
                    <div 
                        className="map-legend-gradient-row"
                        style={styles.gradient}
                    >
                        <span className="body-regular colour-primary">
                            Low
                        </span>
                        <span className="body-regular colour-primary">
                            Medium
                        </span>
                        <span className="body-regular colour-primary">
                            High
                        </span>
                    </div>
                }
            </div>
        </div>
    )
};

export default MapLegend;