import React from 'react';
import '../styles/DefaultTooltip.css';

function HUDTooltip() {

    const openLink = () => {
        window.open('https://www.huduser.gov/portal/datasets/fmr.html', '_blank');
    };

    return (
        <div className="default-tooltip-container">
            <h5 className="body-semibold margin-x-small colour-primary">
                How do we estimate this?
            </h5>
            <span className="body-regular colour-primary">
                Our estimates for renting by the bedroom come from the <span className="underline" onClick={() => openLink()}>HUD Fair Market Rent</span> estimate.
            </span>
        </div>
    )
}

export default HUDTooltip;