import React from 'react';
import '../styles/DoubleTapMessage.css';
import { checkUserId, setData } from "../functions";

function DoubleTapMessage(props) {
	const userDetails = props.userDetails;
	const setUserDetails = props.setUserDetails;
	const setHideMobileMessage = props.setHideMobileMessage;

	const cta = async() => {
		let mobileMessage;
		if ( userDetails.mobileMessage === undefined ) {
			mobileMessage = 1;
		}
		else {
			mobileMessage = userDetails.mobileMessage + 1;
		}

		userDetails.mobileMessage = mobileMessage;
		setUserDetails(userDetails);
		setHideMobileMessage(true);
		
		const colRef = "Users";
		const user = await checkUserId();

		if ( user.userId !== "" ) {
			const docRef = user.userId;
			const data = userDetails;
			await setData(colRef, docRef, data);
		}
	}

	return (
		<div className="double-tap-message-outer-container">
			<div className="double-tap-message-inner-container">
				<div className="double-tap-message-text-container">
					<span className="double-tap-message-text">
						Tap on an area to learn about it.
					</span>
				</div>
				<div className="double-tap-message-cta-container">
					<span 
						className="double-tap-message-cta-text"
						onClick={() => cta()}
					>
						GOT IT
					</span>
				</div>
			</div>
		</div>
	)
}

export default DoubleTapMessage;