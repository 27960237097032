import React from 'react';
import '../styles/TopPickBadge.css';

function CashOnlyBadge() {
	return (
		<div className="top-pick-badge-outer-container">
			<span className="top-pick-badge-text">
				💵 Cash Only
			</span>
		</div>
	)
}

export default CashOnlyBadge;