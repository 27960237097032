import React from 'react';
import '../styles/SelectedLine.css';

function SelectedLine(props) {
    const visible = props.visible;
    return (
        <div className={"selected-line-container " + (visible === true ? "selected-line-container-visible" : "selected-line-container-invisbile")}>
        </div>
    )
};

export default SelectedLine;