import React, { useState, useEffect } from 'react';
import '../styles/UpgradeCityMap.css';
import { GoogleMap, useLoadScript, Polygon } from '@react-google-maps/api';
import mapStyles from '../styles/mapStyles.json';
import { mapColours } from '../styles/GlobalStyles';
import { Loading } from '../components';

function UpgradeCityMap(props) {
	const centre = props.centre;
	const zoom = props.zoom;
	const mapRef = props.mapRef;
	const onZoomChanged = props.onZoomChanged;
	const cityBoundaries = props.cityBoundaries;
	const metroAreas = props.metroAreas;
	const mapLoading = props.mapLoading;
	const upgradedMSA = props.upgradedMSA;
	const mapRefContainer = props.mapRefContainer;
	const [renderOverlay, setRenderOverlay] = useState(false);
    const api_key = JSON.parse(process.env.REACT_APP_GOOGLE).key
    const { isLoaded } = useLoadScript({
		googleMapsApiKey: api_key
	});

	useEffect(() => {
		// There's a bug with the overlay where 
		// it only renders on a re-render

		if ( renderOverlay === false ) {
			setTimeout(() => {
				setRenderOverlay(true);
			}, 1000)
		}

	}, [renderOverlay]);

    const options = {
		disableDefaultUI: false,
		scrollwheel: true,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		disableDoubleClickZoom: true,
		styles: mapStyles
	};

	const styles = {
		city: {
			fillColor: mapColours.mapColorsTransparentYellow,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		metroArea: {
			fillColor: mapColours.mapColorsTransparentBlue,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		}
	};

    return (
        <div 
			className="upgrade-city-map-container relative-container"
			ref={mapRefContainer}
		>
			{
				mapLoading === true ?
				<Loading />
				:
				null
			}
            {
				isLoaded === true ?
				<GoogleMap
					zoom={zoom}
					center={centre}
					mapContainerClassName="upgrade-city-map-element"
					options={options}
					onLoad={(map) => {
						mapRef.current = map;
					}}
					ref={mapRef}
					onZoomChanged={() => onZoomChanged()}
				>
					{
						renderOverlay === true ?
							<Polygon
								editable={false}
								paths={cityBoundaries}
								options={styles.city}
							/>
						:
						null
					}
					{
						renderOverlay === true && upgradedMSA === true ?
						metroAreas.map((item, index) =>
							<Polygon
								editable={false}
								paths={item.boundaries}
								options={styles.metroArea}
								key={index}
							/>
						)
						:
						null
					}
				</GoogleMap>
				:
				null
            }
        </div>
    )
};

export default UpgradeCityMap;