import React from 'react';
import '../styles/FullScreenButton.css';
import { Expand } from '../assets';

function FullScreenButton() {

    const clickFullScreen = () => {
        const fullScreenButton = document.querySelector('.gm-fullscreen-control');
        if ( fullScreenButton !== null ) {
            fullScreenButton.click();
        }
    };

    return (
        <div 
            className="full-screen-button-outer-container"
            onClick={() => clickFullScreen()}
        >
            <div className="full-screen-button-inner-container">
                <img
                    src={Expand}
                    className="expand-icon"
                    alt="Expand Icon"
                />
            </div>
        </div>
    )
}

export default FullScreenButton;