import React from 'react';
import '../styles/RentAccordion.css';
import { Accordion, AccordionDetails, AccordionSummary, formatterLong } from '../styles/GlobalStyles';

function ValuesAccordion(props) {
    const valuesAccordion = props.valuesAccordion;
    const handleValuesAccordion = props.handleValuesAccordion;
    const property = props.property;
    const traditionalRentEstimate = property.propertyEstimates === undefined ? null : property.propertyEstimates;
    const rentalEstimates = traditionalRentEstimate === null ? [] : [
		{
			label: "Zillow Zestimate",
			value: Math.round(traditionalRentEstimate.zillowEstimate)
		},
		{
			label: "Realtor.com",
			value: Math.round(traditionalRentEstimate.realtorEstimate)
		},
		{
			label: "REAPI",
			value: Math.round(traditionalRentEstimate.reapiEstimate)
		}
	];

    return (
        <Accordion 
            className="rent-accordion-outer-container"
            expanded={valuesAccordion}
            onChange={handleValuesAccordion}
            sx={{ 
                    boxShadow: 'none',
                    '&:before': {
                    display: 'none',
                }}
            }
        >
            <AccordionSummary
                aria-controls={`rent-accordion-bh-content`}
                id={`rent-accordion-bh-header`}
            >
                <div className={"button-row " + (valuesAccordion === true ? "rent-accordion-title-container-reverse" : "")}>
                    <span className="label-regular colour-link block-text subtext-margin-right">
                        Show other property value estimates
                    </span>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="8" 
                        height="8" 
                        viewBox="0 0 8 8" 
                        fill="none"
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M7.75605 1.24325C8.08132 1.55635 8.08132 2.07126 7.75605 2.38436L4.00001 6L0.243974 2.38436C-0.0812885 2.07126 -0.0812885 1.55635 0.243974 1.24325C0.55971 0.939314 1.06486 0.939314 1.3806 1.24325L4.00001 3.76475L6.61943 1.24325C6.93517 0.939313 7.44032 0.939313 7.75605 1.24325Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="rent-accordion-row bg-colour-light">
                    {
                        rentalEstimates.map((item, index) =>
                            item.value === null || item.value === 0 ?
                            null
                            :
                            <div 
                                className="rent-accordion-element-container"
                                key={index} 
                            >
                                <div className="rent-estimates-label">
                                    <span className="label-regular colour-secondary">
                                        {item.label}
                                    </span>
                                </div>
                                <div className="rent-estimates-value">
                                    <span className="body-semibold colour-primary">
                                        {formatterLong.format(item.value).replace(".00", "")}
                                    </span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default ValuesAccordion;