import React from 'react';
import '../styles/MulticolourSlider.css';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { colour } from '../styles/GlobalStyles';

function MulticolourSlider(props) {
    const maxPercentage = props.maxPercentage;
    const changeSlider = props.changeSlider
    const value = props.value;
    const reverse = props.reverse;
    const background = `linear-gradient(to right, ${colour.greenGreen02} 0% 25%, ${colour.yellowYellow04} 25% 50%, ${colour.orangeOrange03} 50% 75%, ${colour.redRed02} 75% 100%)`;
    const reverseBackground = `linear-gradient(to right, ${colour.redRed02} 0% 25%, ${colour.orangeOrange03} 25% 50%, ${colour.yellowYellow04} 50% 75%, ${colour.greenGreen02} 75% 100%)`;

    return (
        <Stack 
            spacing={2} 
            direction="row" 
            sx={{ mb: 1, px: 1, width: '100%'}} 
            alignItems="center"
        >
            <Slider
                aria-label="Multicolour Slider"
                value={value}
                onChange={changeSlider}
                min={maxPercentage}
                max={30}
                track={false}
                sx={{
                    "& .MuiSlider-rail": {
                        // This is the part AFTER the thumb
                        background: reverse === true ? reverseBackground : background,
                        opacity: 1,
                        height: 6
                    },
                    "& .MuiSlider-thumb": {
                        width: 24,
                        height: 24,
                        backgroundColor: colour.grayScaleWhite,
                        '&:before': {
                            boxShadow: '0px 0px 10px 0px #99959180',
                        },
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                            boxShadow: 'none',
                        }
                    },
                    "& .MuiSlider-valueLabel": {},
                }}
            />
        </Stack>
    )
};

export default MulticolourSlider;