import React, { useState, useRef } from 'react';
import '../styles/UploadImage.css';
import { uploadImage, checkUserId } from '../functions';
import { PlusLight } from '../assets';
import { useNavigate } from 'react-router-dom';

function UploadImage(props) {

    const textInput = useRef(null);
	const [loading, setLoading] = useState("");
    const [error, setError] = useState("");
    const saveFile = props.saveFile;
    const uploadedImage = props.uploadedImage;
    const route = props.route;
    const subText = props.subText;
    const showIcon = props.showIcon;
    const navigate = useNavigate();

	const onChangeFile = async(event) => {
        event.stopPropagation();
        event.preventDefault();
        if ( event.target.files.length === 0 ) return;
        const file = URL.createObjectURL(event.target.files[0]);
		const type = event.target.files[0].type;
		const user = await checkUserId();
		const userId = user.userId;

		if ( userId === "" ) {
			navigate("/sign-up");
		}
		else if ( type.includes("image") ) {
            setError("");
			const savePath = `Users/${userId}/${route}`;
			const uploading = await uploadImage(file, savePath, setLoading);
			const uploadedFile = {
				title: event.target.files[0].name,
				url: uploading
			};
			saveFile(uploadedFile, userId);
        }
        else {
            setError("Please select an image!");
        }
	}

    return (
        <div className="upload-image-outer-container">
            <div 
                onClick={() => textInput.current.click()}
                className="upload-image-container"
            >
                <input type='file' id='file' ref={textInput} style={{ display: 'none' }} onChange={onChangeFile} />
                {
                    uploadedImage !== "" ? 
                    <div className="uploaded-image-container">
                        <img
                            src={uploadedImage}
                            className="uploaded-image"
                            alt="Uploaded"
                        />
                        {
                            showIcon === true ?
                            <img
                                src={PlusLight}
                                className="upload-image-icon-overlay"
                                alt="Upload"
                            />
                            :
                            null
                        }
                    </div>
                    :
                    loading === "" ?
                    <div className="upload-image-border-container">
                        <img
                            src={PlusLight}
                            className="upload-image-icon"
                            alt="Upload Document"
                        />
                    </div>
                    :
                    <div className="upload-image-border-container">
                        <h2 className="heading-large-semibold colour-primary">
                            {loading}
                        </h2>
                    </div>
                }
                {
                    subText === "" ?
                    null
                    :
                    <span className="body-regular-underline colour-link block-text margin-top-small">
                        {subText}
                    </span>
                }
            </div>
            {
                error === "" ?
                null
                :
                <span className="body-regular colour-error">
                    {error}
                </span>
            }
        </div>
    )
};

export default UploadImage;