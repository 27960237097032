import React, { useEffect, useState } from 'react';
import '../styles/EditBuyBox.css';
import { ChooseBudget, ChooseBuyBoxName, ChoosePropertySpecifics, ChoosePropertyType, ChooseValueAddOpportunity, Footer, Header, SideNavigation, Loading, DeleteModal, EditStrategy, EditNeighbourhoodGrade, EditListingType } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft, Exit, Pin, Search } from '../assets';
import { ActionButton, colour, CustomSelect, FilterCityInput } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, cloudFunctionV2, getCountySearch, getDocument, setData } from '../functions';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { counties } from '../counties';

function EditBuyBox() {

    const location = useLocation();
    const state = location.state;
    const userData = state !== null ? state.userData : null;
    const buyBox = state !== null ? state.buyBox : null;
    const [title, setTitle] = useState(buyBox !== null ? buyBox.title : null)
    const [propertyTypes, setPropertyTypes] = useState(buyBox !== null ? buyBox.propertyTypes : null);
    const [units, setUnits] = useState(buyBox !== null ? buyBox.units : null);
    const [budgetRange, setBudgetRange] = useState(buyBox !== null ? buyBox.budgetRange : null);
    const [bedrooms, setBedrooms] = useState(buyBox !== null ? buyBox.bedrooms : null);
    const [bathrooms, setBathrooms] = useState(buyBox !== null ? buyBox.bathrooms : null);
    const [squareFootage, setSquareFootage] = useState(buyBox !== null ? buyBox.squareFootage : null);
    const [constructionYears, setConstructionYears] = useState(buyBox !== null ? buyBox.constructionYears : null);
    const [onlyADU] = useState(buyBox !== null ? buyBox.onlyADU : null);
    const [onlyExtraBedroom] = useState(buyBox !== null ? buyBox.onlyExtraBedroom : null);
    const [frequency, setFrequency] = useState(buyBox !== null ? buyBox.frequency : null);
    const [strategy, setStrategy] = useState(buyBox !== null && buyBox.strategy !== undefined ? buyBox.strategy : "")
    const [neighbourhoodGradeMin, setNeighbourhoodGradeMin] = useState(buyBox !== null && buyBox.neighbourhoodGradeMin !== undefined ? buyBox.neighbourhoodGradeMin : 1);
    const [neighbourhoodGradeMax, setNeighbourhoodGradeMax] = useState(buyBox !== null && buyBox.neighbourhoodGradeMax !== undefined ? buyBox.neighbourhoodGradeMax : 8);
    const [buyBoxMetroCity, setBuyBoxMetroCity] = useState(buyBox !== null && buyBox.buyBoxMetroCity !== undefined ? buyBox.buyBoxMetroCity : "all");
    const [onlyFixerUppers, setOnlyFixerUppers] = useState(buyBox !== null && buyBox.onlyFixerUppers !== undefined ? buyBox.onlyFixerUppers : false);
    const [hideFixerUppers, setHideFixerUppers] = useState(buyBox !== null && buyBox.hideFixerUppers !== undefined ? buyBox.hideFixerUppers : false);
    const [fsbo, setFsbo] = useState(buyBox !== null && buyBox.fsbo !== undefined ? buyBox.fsbo : false);
    const [geographyType, setGeographyType] = useState(buyBox !== null && buyBox.customCities !== undefined && buyBox.customCities !== null ? 1 : buyBox.customZipCodes !== undefined && buyBox.customZipCodes !== null ? 2 : 0);
    const [customCitiesOpen, setCustomCitiesOpen] = useState(false);
    const [zipText, setZipText] = useState("");
    const [customCities, setCustomCities] = useState(geographyType === 1 ? buyBox.customCities : []);
    const [customZipCodes, setCustomZipCodes] = useState(geographyType === 2 ? buyBox.customZipCodes : []);
    const [allZipCodes, setAllZipCodes] = useState([]);
    const [zipOptions, setZipOptions] = useState([]);
    const [allZipsLoaded, setAllZipsLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const geographies = [
        "Entire County",
        "City",
        "ZIP Codes"
    ];

    const navigate = useNavigate();

    useEffect(() => {

        const getCountyData = async() => {
            const msaCode = buyBox.msaCode;
            const withoutPrefix = msaCode.replace("CTY", "");
            const countyIndex = counties.findIndex(e => e.code === withoutPrefix);
            if ( countyIndex !== -1 ) {
                const item = counties[countyIndex];
                const countyParams = {
                    countyId: `CTY${item.code}`
                }
                const getCountyParams = await getCountySearch(countyParams);
                const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
                const gatewayData = {
                    type: "gateway",
                    resourceId: "county",
                    queryString: getCountyParams
                };
                const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
                if ( getCityDetails.status === 200 ) {
                    const body = getCityDetails.body;

                    const newAllZipCodes = [];
                    for (let index = 0; index < body.msaCityList.length; index++) {
                        const element = body.msaCityList[index];
                        if ( element.city === null ) {
                            continue;
                        }
                        
                        const newObject = {
                            city: element.city,
                            state: element.state,
                            cityId: element.cityId,
                            zipCodes: element.activeZipCodes
                        };
                        newAllZipCodes.push(newObject);
                    };
                    setAllZipCodes(newAllZipCodes);
                    setAllZipsLoaded(true);
                }
            }
        };

        if ( state === null || userData === null || buyBox === null )  {
            navigate("/my-cities");
        }
        else {
            getCountyData();
        }
    }, [state, navigate, buyBox, userData]);

    const strategies = [
        {
            title: "Seller Finance",
            description: "This strategy means we're targeting properties that are owned free & clear. We rank these properties based on the likelihood of the owner being open to seller financing, and whether the property would make a good investment property (based on cash-flow, neighborhood, and condition).",
            value: "sellerFinance"
        },
        {
            title: "Subject To",
            description: "This strategy means we're targeting properties where the seller has a mortgage that would be desirable to take over (meaning a low monthly payment). We rank these properties based on the likelihood of the owner being open to selling 'Subject To', and whether the property would make a good investment property (based on cash-flow, neighborhood, and condition).",
            value: "subto"
        }
    ];

    const goBack = () => {
        navigate("/my-cities", {
            state: state
        });
    };

    const deleteBuyBox = async() => {
        setDeleteModal(false);
        setLoading(true);
        const colRef = "Creative Buy Boxes";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            const queryDocument = await getDocument(colRef, docRef);
            if ( queryDocument.status === 200 ) {
                const buyBoxes = queryDocument.data.data;
                const buyBoxCityIndex = buyBox.metroArea === true ? buyBoxes.findIndex(e => e.msaCode === buyBox.msaCode) : buyBoxes.findIndex(e => e.cityId === buyBox.cityId);
                if ( buyBoxCityIndex !== -1 ) {
                    const buyBoxIndex = buyBoxes[buyBoxCityIndex].buyBoxes.findIndex(e => e.id === buyBox.id);
                    if ( buyBoxIndex !== -1 ) {
                        buyBoxes[buyBoxCityIndex].buyBoxes.splice(buyBoxIndex, 1);
                        const updateDocument = await setData(colRef, docRef, buyBoxes);
                        if ( updateDocument.status === 200 ) {
                            navigate("/my-cities", {
                                state: state
                            });
                        }
                    }
                }
                else {
                    navigate("/my-cities");
                }
            }
            else {
                navigate("/my-cities");
            }
        }
        else {
            navigate("/sign-up");
        }
        setLoading(false);
    };

    const saveChanges = async() => {
        setLoading(true);
        const colRef = "Creative Buy Boxes";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            const queryDocument = await getDocument(colRef, docRef);
            if ( queryDocument.status === 200 ) {
                const buyBoxes = queryDocument.data.data;
                const buyBoxCityIndex = buyBoxes.findIndex(e => e.msaCode === buyBox.msaCode);
                if ( buyBoxCityIndex !== -1 ) {
                    const buyBoxIndex = buyBoxes[buyBoxCityIndex].buyBoxes.findIndex(e => e.id === buyBox.id);
                    if ( buyBoxIndex !== -1 ) {
                        buyBoxes[buyBoxCityIndex].buyBoxes[buyBoxIndex] = {
                            ...buyBoxes[buyBoxCityIndex].buyBoxes[buyBoxIndex],
                            bathrooms: bathrooms,
                            bedrooms: bedrooms,
                            budgetRange: budgetRange,
                            constructionYears: constructionYears,
                            customCities: geographyType === 1 ? customCities : null,
                            customZipCodes: geographyType === 2 ? customZipCodes : null,
                            frequency: frequency,
                            hideFixerUppers: hideFixerUppers,
                            onlyADU: onlyADU,
                            onlyExtraBedroom: onlyExtraBedroom,
                            onlyFixerUppers: onlyFixerUppers,
                            propertyTypes: propertyTypes,
                            strategy: strategy,
                            neighbourhoodGradeMin: neighbourhoodGradeMin,
                            neighbourhoodGradeMax: neighbourhoodGradeMax,
                            squareFootage: squareFootage,
                            title: title,
                            units: units,
                            fsbo: fsbo
                        };

                        if ( buyBox.metroArea === true ) {
                            buyBoxes[buyBoxCityIndex].buyBoxes[buyBoxIndex].buyBoxMetroCity = buyBoxMetroCity;
                        }

                        delete buyBoxes[buyBoxCityIndex].buyBoxes[buyBoxIndex].lastCheck;
                        const updateDocument = await setData(colRef, docRef, buyBoxes);
                        if ( updateDocument.status === 200 ) {
                            navigate("/my-cities", {
                                state: state
                            });
                        }
                    }
                    else {
                        navigate("/my-cities");
                    }
                }
                else {
                    navigate("/my-cities");
                }
            }
        }
        else {
            navigate("/sign-up");
        }
        setLoading(false);
    };

    const onChangeTitle = (val) => {
        setTitle(val);
        setDisabled(false);
    };

    const onChangeMetroCity = (val) => {
        setBuyBoxMetroCity(val);
        setDisabled(false);
    };

    const onChangeFrequency = (val) => {
        setFrequency(val);
        setDisabled(false);
    };
        
    const onChangePropertyTypes = (val) => {
        setPropertyTypes(val);
        
        if ( val.length === 2 && val[0].included === false && val[1].included === false ) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    };

    const onChangeUnits = (val) => {
        setUnits(val);
        setDisabled(false);
    };

    const onChangeBudget = (val) => {
        setBudgetRange(val);
        setDisabled(false);
    };

    const onChangeBedrooms = (val) => {
        setBedrooms(val);
        setDisabled(false);
    };

    const onChangeBathrooms = (val) => {
        setBathrooms(val);
        setDisabled(false);
    };

    const onChangeSquareFootage = (val) => {
        setSquareFootage(val);
        setDisabled(false);
    };

    const onChangeConstructionYears = (val) => {
        setConstructionYears(val);
        setDisabled(false);
    };

    // const onChangeOnlyADU = (val) => {
    //     setOnlyADU(val);
    //     setDisabled(false);
    // };

    // const onChangeOnlyExtraBedroom = (val) => {
    //     setOnlyExtraBedroom(val);
    //     setDisabled(false);
    // };

    // const onChangeNoFixerUpper = (val) => {
    //     setNoFixerUpper(val);
    //     setDisabled(false);
    // };

    const changeFSBOFilter = (val) => {
        if ( val === "true" ) {
            val = true;
        }
        else if ( val === "false" ) {
            val = false;
        }
        setFsbo(val);
        setDisabled(false);
    };

    const onChangeStrategy = (val) => {
        setStrategy(val);
        setDisabled(false);
    };

    const changeNeighbourhoodGrade = (val, index) => {
        if ( index === 0 ) {
            setNeighbourhoodGradeMin(val);
        }
        else {
            setNeighbourhoodGradeMax(val);
        }
        setDisabled(false);
    };

    const changeGeography = (val) => {
        setGeographyType(val);

        if ( val === 0 || val === "0" ) {
            setDisabled(false);
        }
    };

    const cityOptionsSelected = (val) => {
        const index = customCities.indexOf(val);
		if ( index === -1 ) {
			return false;
		}
		else {
			return true;
		}
    };

    const changeCustomCities = (vals) => {
        const newArray = [...customCities];
		const index = newArray.indexOf(vals.cityId);
		if ( index === -1 ) {
			newArray.push(vals.cityId);
		}
		else {
			newArray.splice(index, 1);
		}
		setCustomCities(newArray);
        setDisabled(false);
    };

    const changeZipText = (val) => {
        setZipText(val);

        const newZipOptions = [];
        for (let index = 0; index < allZipCodes.length; index++) {
            const element = allZipCodes[index];
            const zipCodes = element.zipCodes;
            for (let ii = 0; ii < zipCodes.length; ii++) {
                const zipCode = zipCodes[ii];
                const zipOptionIndex = newZipOptions.findIndex(e => e.value === zipCode);
                if ( zipCode.includes(val) && zipOptionIndex === -1 && (customZipCodes.includes(zipCode) === false) ) {
                    const newObject = {
                        label: `${zipCode} - ${element.city}, ${element.state}`,
                        value: zipCode
                    };
                    newZipOptions.push(newObject);
                }
            }
        };

        setZipOptions(newZipOptions);
    };

    const resetZipText = () => {
        setZipText("");
    };

    const removeZipCode = (val) => {
        const newArray = customZipCodes.filter(e => e !== val);
        setCustomZipCodes(newArray);
        setDisabled(false);
    };

    const selectZipCode = (item) => {
        const newArray = [...customZipCodes];
        newArray.push(item.value);
        setCustomZipCodes(newArray);
        setZipText("");
        setZipOptions([]);
        setDisabled(false);
    };

    const styles = {
        menuItem: {
            backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
        }
    };

    return (
        <div className="edit-buy-box-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            {
                state === null ?
                null
                :
                <div className="edit-buy-box-outer-row-container relative-container">
                    <SideNavigation
                        navigate={navigate}
                        state={state}
                        userData={userData}
                    />
                    <div className="edit-buy-box-body-container">
                        {
                            loading === true ?
                            <Loading />
                            :
                            null
                        }
                        {
                            deleteModal === true ?
                            <DeleteModal
                                deleteFunction={deleteBuyBox}
                                setDeleteModal={setDeleteModal}
                                description="Are you sure you want to delete this buy box?"
                                deleteParam={null}
                            />
                            :
                            null
                        }
                        <div className="edit-buy-box-outer-title-container margin-x-small">
                            <div className="edit-buy-box-title-container">
                                <div 
                                    className="button-row text-button subtext-margin-right-x-large"
                                    onClick={() => goBack()}
                                >
                                    <img
                                        src={ChevronLeft}
                                        className="edit-buy-box-back-chevron"
                                        alt="Back"
                                    />
                                    <span className="body-regular text-link">
                                        Back
                                    </span>
                                </div>
                                <div className="edit-buy-box-title-text-container">
                                    <h1 className="heading-large-semibold colour-primary margin-x-small">
                                        Edit buy box
                                    </h1>
                                    <span className="body-regular colour-secondary">
                                        Change your buy box parameters.
                                    </span>
                                </div>
                            </div>
                            <div className="edit-buy-box-title-buttons-container">
                                <div 
                                    className="text-button subtext-margin-right-x-large"
                                    onClick={() => setDeleteModal(true)}
                                >
                                    <span className="body-regular colour-error">
                                        Delete this buy box
                                    </span>
                                </div>
                                <div className="edit-buy-box-save-container">
                                    <ActionButton
                                        disabled={loading === true ? true : disabled}
                                        onClick={() => saveChanges()}
                                    >
                                        Save changes
                                    </ActionButton>
                                </div>
                            </div>
                        </div>
                        <div className="edit-buy-box-divider margin-tops">
                        </div>
                        <div className="edit-buy-box-change-container margin-top-x-large">
                            <div className="edit-buy-box-inner-change-container">
                                <div className="edit-buy-box-section-container edit-buy-box-no-padding-top">
                                    <h2 className="heading-small-semibold colour-quaternary margin-medium">
                                        Buy box
                                    </h2>
                                    <div className="margin-large">
                                        <div className="edit-buy-box-msa-row margin-medium">
                                            <img
                                                src={Pin}
                                                className="buy-box-edit-pin"
                                                alt="pin"
                                            />
                                            <span className="body-regular colour-secondary block-text text-align-center">
                                                {buyBox.msaTitle}
                                            </span>
                                        </div>
                                        <div className="choose-county-options-selector-container margin-medium">
                                            <Box>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="buy-box-geography-select-label"
                                                        id={`buy-box-geography-select`}
                                                        value={geographyType}
                                                        label=""
                                                        onChange={(event) => changeGeography(event.target.value)}
                                                        input={<CustomSelect />}
                                                        disabled={loading}
                                                    >
                                                        {
                                                            geographies.map((item, index) =>
                                                                <MenuItem
                                                                    key={index}
                                                                    value={index}
                                                                    style={styles.menuItem}
                                                                    disabled={false}
                                                                >
                                                                    {item}
                                                                </MenuItem>	
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        {
                                            geographyType === 0 ?
                                            null
                                            :
                                            geographyType === 1 ?
                                            <div className="choose-county-city-selector-container">
                                                <span className="body-semibold colour-primary block-text margin-x-small">
                                                    Which cities would you like to focus on?
                                                </span>
                                                <Box>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="buy-box-city-select-label"
                                                            id={`buy-box-city-select`}
                                                            value={customCities}
                                                            renderValue={(selected) => `Cities (${selected.length})`}
                                                            input={<CustomSelect />}
                                                            multiple
                                                            open={customCitiesOpen}
                                                            onOpen={() => setCustomCitiesOpen(true)}
                                                            onClose={() => setCustomCitiesOpen(false)}
                                                        >
                                                            {
                                                                state.buyBoxCity.metroCities.map((item, index) =>
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={item.cityId}
                                                                        style={styles.menuItem}
                                                                        disabled={false}
                                                                        onClick={() => changeCustomCities(item)}
                                                                    >
                                                                        <div className="sign-up-component-how-did-you-hear-row">
                                                                            <div className="checkbox-container">
                                                                                {
                                                                                    cityOptionsSelected(item.cityId) === true ?
                                                                                    <div className="checkbox-icon">
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </div>
                                                                            <span className="body-regular colour-primary">
                                                                                {item.city}, {item.state}
                                                                            </span>
                                                                        </div>
                                                                    </MenuItem>	
                                                                )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>
                                            :
                                            geographyType === 2 ?
                                            <div className="choose-county-city-selector-container">
                                                <span className="body-semibold colour-primary block-text margin-x-small">
                                                    Which ZIP codes would you like to focus on?
                                                </span>
                                                {
                                                    customZipCodes.length > 0 ?
                                                    <div className="choose-county-zip-codes-container margin-x-small">
                                                        {
                                                            customZipCodes.map((item, index) =>
                                                                <div 
                                                                    className="choose-county-custom-zip-code-bubble"
                                                                    key={index}
                                                                >
                                                                    <span 
                                                                        key={index}
                                                                        className="body-regular colour-primary"
                                                                    >
                                                                        {item}
                                                                    </span>
                                                                    <svg 
                                                                        xmlns="http://www.w3.org/2000/svg" 
                                                                        width="23" 
                                                                        height="24" 
                                                                        viewBox="0 0 23 24" 
                                                                        fill="none"
                                                                        className="choose-county-zip-options-icon cursor-pointer"
                                                                        onClick={() => removeZipCode(item)}
                                                                    >
                                                                        <path 
                                                                            fillRule="evenodd" 
                                                                            clipRule="evenodd" 
                                                                            d="M2.75829 0.419021C2.2903 -0.100741 1.48956 -0.142706 0.969802 0.32529C0.45004 0.793286 0.408076 1.59402 0.876071 2.11378L9.7968 12.0213L0.914356 21.8862C0.446361 22.406 0.488325 23.2067 1.00809 23.6747C1.52785 24.1427 2.32858 24.1007 2.79658 23.581L11.5009 13.9138L20.2052 23.5809C20.6732 24.1007 21.4739 24.1427 21.9937 23.6747C22.5134 23.2067 22.5554 22.4059 22.0874 21.8862L13.205 12.0213L22.1257 2.11382C22.5937 1.59406 22.5517 0.793322 22.032 0.325326C21.5122 -0.142669 20.7115 -0.100704 20.2435 0.419057L11.5009 10.1287L2.75829 0.419021Z" 
                                                                            fill="#2F4858"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                        {
                                            geographyType === 2 &&
                                            <div className="upgrade-city-inner-input-container relative-container margin-x-large">
                                                {
                                                    allZipsLoaded === false ?
                                                    <Loading />
                                                    :
                                                    null
                                                }
                                                <FilterCityInput
                                                    value={zipText}
                                                    type="text"
                                                    id="select-zip-text-input"
                                                    placeholder="Search for ZIP codes.."
                                                    onChange={(text) => changeZipText(text.target.value)}
                                                    disabled={false}
                                                />
                                                <img
                                                    src={zipText === "" ? Search : Exit}
                                                    className={zipText === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                                                    alt="Search"
                                                    onClick={() => zipText === "" ? null : resetZipText()}
                                                />
                                                {
                                                    zipOptions.length > 0 && zipText !== "" ?
                                                        <div className="city-filtering-outer-container">
                                                            {
                                                                zipOptions.map((item, index) =>
                                                                    <div 
                                                                        className="city-filtering-element"
                                                                        key={index}
                                                                        onClick={() => selectZipCode(item)}
                                                                    >
                                                                        <span className="body-regular colour-primary">
                                                                            {item.label}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        }
                                    </div>
                                    <ChooseBuyBoxName
                                        title={title}
                                        setTitle={onChangeTitle}
                                        buyBox={buyBox}
                                        frequency={frequency}
                                        setFrequency={onChangeFrequency}
                                        buyBoxCity={buyBox !== null && buyBox.metroArea === true ? state.buyBoxCity : null}
                                        buyBoxMetroCity={buyBoxMetroCity}
                                        onChangeMetroCity={onChangeMetroCity}
                                    />
                                </div>
                                <div className="edit-buy-box-major-divider">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="4" viewBox="0 0 512 4" fill="none">
                                        <path d="M2 2H510" stroke="#EEDFD2" strokeWidth="3" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <h2 className="heading-small-semibold colour-quaternary margin-medium">
                                        Property requirements
                                    </h2>
                                    <ChoosePropertyType
                                        propertyTypes={propertyTypes}
                                        setPropertyTypes={onChangePropertyTypes}
                                        units={units}
                                        setUnits={onChangeUnits}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <ChooseBudget
                                        budgetRange={budgetRange}
                                        setBudgetRange={onChangeBudget}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <ChoosePropertySpecifics
                                        bedrooms={bedrooms}
                                        setBedrooms={onChangeBedrooms}
                                        bathrooms={bathrooms}
                                        setBathrooms={onChangeBathrooms}
                                        squareFootage={squareFootage}
                                        setSquareFootage={onChangeSquareFootage}
                                        constructionYears={constructionYears}
                                        setConstructionYears={onChangeConstructionYears}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <ChooseValueAddOpportunity
                                        onlyFixerUppers={onlyFixerUppers}
                                        setOnlyFixerUppers={setOnlyFixerUppers}
                                        hideFixerUppers={hideFixerUppers}
                                        setHideFixerUppers={setHideFixerUppers}
                                        setDisabled={setDisabled}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <EditListingType
                                        fsbo={fsbo}
                                        changeFSBOFilter={changeFSBOFilter}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <EditStrategy
                                        strategies={strategies}
                                        strategy={strategy}
                                        onChangeStrategy={onChangeStrategy}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <EditNeighbourhoodGrade
                                        neighbourhoodGradeMin={neighbourhoodGradeMin}
                                        neighbourhoodGradeMax={neighbourhoodGradeMax}
                                        changeNeighbourhoodGrade={changeNeighbourhoodGrade}
                                        filtersModal={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default EditBuyBox;