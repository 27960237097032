import React from 'react';
import '../styles/BuyBoxFrequency.css';
import { Selector } from './';

function BuyBoxFrequency(props) {
    const frequency = props.frequency;
    const buyBoxFrequency = props.buyBoxFrequency;
    const setBuyBoxFrequency = props.setBuyBoxFrequency;
    const onChangeBuyBoxFrequency = props.onChangeBuyBoxFrequency;
    const id = props.id;
    const item = props.item;
    const options = [
        {
            text: 'Daily',
            value: 'daily',
            selected: frequency === "daily" ? true : false
        },
        {
            text: 'Weekly',
            value: 'weekly',
            selected: frequency === "weekly" ? true : false
        },
        {
            text: 'Monthly',
            value: 'monthly',
            selected: frequency === "monthly" ? true : false
        },
        {
            text: 'Never',
            value: 'never',
            selected: frequency === "never" ? true : false
        },
    ];

    const openOptions = () => {
        if ( buyBoxFrequency === false ) {
            setBuyBoxFrequency(id);
        }
    };

    const selectOption = (index) => {
        const option = options[index].value;
        onChangeBuyBoxFrequency(option, id, item);
        setBuyBoxFrequency(null);
    };

    return (
        <div 
            className="buy-box-frequency-outer-container cursor-pointer chip-button bg-colour-white"
            onClick={() => openOptions()}
        >
            {
                buyBoxFrequency === true ?
                <Selector
                    options={options}
                    selectOption={selectOption}
                />
                :
                null
            }
            <div className="buy-box-frequency-inner-container">
                {
                    frequency === "never" ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M2.76693 8.16622C2.63403 7.27288 2.72336 6.40093 2.99079 5.60519L3.89058 6.33318C3.76502 6.86459 3.73591 7.43065 3.82199 8.00926C3.88079 8.40453 3.93679 8.79218 3.98808 9.16254C4.05965 9.67935 3.97955 10.2005 3.76606 10.6681L3.07731 12.1767C4.49703 12.0074 6.47838 11.8094 8.01191 11.8094C8.87331 11.8094 9.87599 11.8718 10.841 11.9566L12.3554 13.1818C11.0049 13.0291 9.32407 12.876 8.01191 12.876C6.46403 12.876 4.40313 13.089 2.97669 13.2632C2.25662 13.3511 1.70556 12.6129 2.00684 11.953L2.79574 10.2251C2.92671 9.93822 2.97475 9.62122 2.93149 9.30886C2.88079 8.94272 2.82532 8.55868 2.76693 8.16622Z" fill="#292621"/>
                        <path d="M12.0482 9.07692C12.0209 9.23546 12.0074 9.39542 12.0076 9.55505L13.4338 10.709L13.2327 10.2736C13.086 9.95621 13.0402 9.60226 13.0994 9.25766C13.1685 8.85561 13.2395 8.43271 13.3105 8.00132C13.7966 5.04973 11.7204 2.36842 8.85309 1.95865C8.90014 1.84848 8.92619 1.7272 8.92619 1.59983C8.92619 1.09489 8.51685 0.685547 8.0119 0.685547C7.50696 0.685547 7.09762 1.09489 7.09762 1.59983C7.09762 1.73596 7.12737 1.86513 7.18071 1.98121C6.10184 2.16564 5.14295 2.673 4.39229 3.39369L5.2302 4.07162C5.98699 3.39006 6.98411 2.97126 8.08908 2.97126C10.6952 2.97126 12.6772 5.28251 12.258 7.82799C12.1873 8.25745 12.1168 8.67783 12.0482 9.07692Z" fill="#292621"/>
                        <path d="M8.0119 15.3141C8.76564 15.3141 9.39156 14.7669 9.51401 14.048C9.53908 13.9009 9.42793 13.7728 9.28014 13.7518C8.96194 13.7067 8.41165 13.6379 8.0119 13.6379C7.61216 13.6379 7.06187 13.7067 6.74366 13.7518C6.59587 13.7728 6.48473 13.9009 6.50979 14.048C6.63224 14.7669 7.25816 15.3141 8.0119 15.3141Z" fill="#292621"/>
                        <path d="M1.15803 1.60941C0.881917 1.38582 0.476834 1.42839 0.253246 1.7045C0.0296587 1.98061 0.0722347 2.38569 0.348343 2.60928L14.8422 14.3462C15.1183 14.5698 15.5234 14.5272 15.747 14.2511C15.9706 13.975 15.928 13.5699 15.6519 13.3463L1.15803 1.60941Z" fill="#292621"/>
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.84589 1.95865C8.89294 1.84848 8.91899 1.7272 8.91899 1.59983C8.91899 1.09489 8.50965 0.685547 8.0047 0.685547C7.49976 0.685547 7.09042 1.09489 7.09042 1.59983C7.09042 1.73596 7.12016 1.86513 7.17351 1.98121C4.32884 2.4675 2.31828 5.19886 2.75973 8.16622C2.81812 8.55868 2.87359 8.94272 2.92429 9.30886C2.96755 9.62122 2.91951 9.93822 2.78854 10.2251L1.99964 11.953C1.69836 12.6129 2.24942 13.3511 2.96949 13.2632C4.39592 13.089 6.45683 12.876 8.00471 12.876C9.54938 12.876 11.605 13.0882 13.0311 13.2621C13.753 13.3502 14.3041 12.6081 13.9991 11.9479L13.2255 10.2736C13.0788 9.95621 13.033 9.60226 13.0922 9.25766C13.1613 8.85561 13.2323 8.43271 13.3033 8.00132C13.7894 5.04973 11.7132 2.36842 8.84589 1.95865ZM12.041 9.07692C12.1096 8.67783 12.1801 8.25745 12.2508 7.82799C12.67 5.28251 10.688 2.97126 8.08188 2.97126C5.44378 2.97126 3.42043 5.35847 3.81478 8.00926C3.87359 8.40453 3.92959 8.79218 3.98088 9.16254C4.05244 9.67935 3.97235 10.2005 3.75886 10.6681L3.07011 12.1767C4.48983 12.0074 6.47118 11.8094 8.00471 11.8094C9.53467 11.8094 11.5103 12.0065 12.9292 12.1755L12.2572 10.721C12.0191 10.2058 11.9454 9.63264 12.041 9.07692Z" fill="#292621"/>
                        <path d="M8.0047 15.3141C8.75844 15.3141 9.38436 14.7669 9.50681 14.048C9.53188 13.9009 9.42073 13.7728 9.27294 13.7518C8.95474 13.7067 8.40444 13.6379 8.0047 13.6379C7.60496 13.6379 7.05467 13.7067 6.73646 13.7518C6.58867 13.7728 6.47753 13.9009 6.50259 14.048C6.62504 14.7669 7.25096 15.3141 8.0047 15.3141Z" fill="#292621"/>
                    </svg>
                }
                <span className="body-regular colour-primary text-capitalise block-text subtext-margin-left">
                    {frequency}
                </span>
            </div>
        </div>
    )
};

export default BuyBoxFrequency;