import React from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { budgetOptions, colour, formatterCompact, CustomSelect } from '../styles/GlobalStyles';

function ChooseBudget(props) {
    const budgetRange = props.budgetRange;
    const setBudgetRange = props.setBudgetRange;

    const changeBudgetRange = (val) => {
        setBudgetRange(val);
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
    };
    
    return (
        <div className="buy-box-setup-choose-budget-outer-container">
            <span className="body-semibold colour-primary block-text margin-x-small">
                Budget
            </span>
            <div className="buy-box-setup-choose-budget-row">
                <div className="buy-box-setup-choose-budget-element">
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                labelId="min-select-label"
                                id="min-budget-select"
                                value={budgetRange[0]}
                                label=""
                                onChange={(event) => changeBudgetRange([event.target.value, budgetRange[1]])}
                                input={<CustomSelect />}
                            >
                                {
                                    budgetOptions.map((item, index) =>
                                        <MenuItem 
                                            key={index}
                                            value={item.value}
                                            style={styles.menuItem}
                                            disabled={budgetRange[1] <= item.value ? true : false}
                                        >
                                            {item.value === 0 || item.value === 1000000000 ? item.label : formatterCompact.format(item.value)}
                                        </MenuItem>	
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                <div className="buy-box-setup-choose-budget-element">
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                labelId="min-select-label"
                                id="min-budget-select"
                                value={budgetRange[1]}
                                label=""
                                onChange={(event) => changeBudgetRange([budgetRange[0], event.target.value])}
                                input={<CustomSelect />}
                            >
                                {
                                    budgetOptions.map((item, index) =>
                                        <MenuItem 
                                            key={index}
                                            value={item.value}
                                            style={styles.menuItem}
                                            disabled={budgetRange[0] >= item.value ? true : false}
                                        >
                                            {item.value === 0 || item.value === 1000000000 ? item.label : formatterCompact.format(item.value)}
                                        </MenuItem>	
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </div>
        </div>
    )
};

export default ChooseBudget;