import React, { useState, useRef, useEffect } from 'react';
import '../styles/LoginComponent.css';
import { FormInput, ActionButton, WhiteActionButton } from '../styles/GlobalStyles';
import { GoogleLogo } from '../assets';
import { emailAction, passwordAction } from '../actions';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getDocument, signInWithGoogle, userDeletion, recordEvent } from '../functions/index';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import TagManager from 'react-gtm-module';

function LoginComponent(props) {

	const signUpRoute = props.signUpRoute;
	const state = props.state;
	const route = props.route;

	const email = useSelector(state => state.email);
	const password = useSelector(state => state.password);
	const [disabled, setDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const actionButtonRef = useRef(null);

	useEffect(() => {
		document.title = "Coffee Clozers | Login";

	}, []);

	const googleSignIn = async() => {
		const loginUser = await signInWithGoogle();
		setDisabled(true);
		setLoading(true);
		if ( loginUser.status === 200 ) {
			const colRef = "Users";
			const userId = loginUser.user.uid;
			const checkUserDocument = await getDocument(colRef, userId);
			if ( checkUserDocument.status === 200 ) {
				complete(loginUser, true);
			}
			else {
				await userDeletion(loginUser.user);
				alert("Please create an account at the sign-up page!");

				navigate("/sign-up", {
					state: state
				});
			}
		}
		else {
			if ( loginUser.code === "auth/wrong-password" ) {
				setErrorMessage("Incorrect password 😔");
			}
			else if ( loginUser.code === "auth/user-not-found" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/invalid-email" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else if ( loginUser.code === "auth/popup-closed-by-user" || loginUser.code === "auth/cancelled-popup-request") {
				console.log("User closed the popup");
			}
			else {
				alert(loginUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	};

	const signInWithEmail = async() => {
		setLoading(true);
		setDisabled(true);
		const loginUser = await getUser(email, password);

		if ( loginUser.status === 200 ) {
			complete(loginUser, false);
		}
		else {
			if ( loginUser.code === "auth/wrong-password" ) {
				setErrorMessage("Incorrect password 😔");
			}
			else if ( loginUser.code === "auth/user-not-found" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/invalid-email" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else {
				alert(loginUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	}

	const complete = async(loginUser, googleAuth) => {
		const colRef = "Users";
		const userId = loginUser.user.uid;
		const queryUserDoc = await getDocument(colRef, userId);
		let activeSubscription = false;
		let freeTrialUsed = false;
		// let phoneNumberVerified = true;
		// let phoneNumberEntered = true;

		if ( queryUserDoc.status === 200 ) {
			recordEvent("Login", {
				userId: userId
			});
			const tagManagerArgs = {
				dataLayer: {
					event: "login",
					userId: loginUser.user.uid
				}
			};
			TagManager.dataLayer(tagManagerArgs);

			setErrorMessage("");
			const userDetails = queryUserDoc.data.data;
			const userSubscriptions = userDetails.subscriptions;
			if ( userSubscriptions !== undefined && userSubscriptions.length !== 0 ) {
				freeTrialUsed = true;
				
				const subscriptionLength = userSubscriptions.length - 1;
				const finalSubscription = userSubscriptions[subscriptionLength];
				const endDate = finalSubscription.endDate.seconds;
				const today = new Date();
				const todaySeconds = today.getTime() / 1000;

				if ( endDate > todaySeconds ) {
					activeSubscription = true;
				}
			}
			else if ( userDetails.freeTrial !== undefined && userDetails.freeTrial.length !== 0 ) {
				freeTrialUsed = true;
			}

			// if ( userDetails.phoneNumberVerified !== true ) {
			// 	phoneNumberVerified = false;
			// }

			// if ( userDetails.phoneNumber === undefined ) {
			// 	phoneNumberEntered = false;
			// }

			// if ( phoneNumberVerified === false && phoneNumberEntered === true  ) {
			// 	navigate("/phone-verification", {
			// 		state: state
			// 	});
			// }
			if ( route !== undefined && route !== null && route !== "" ) {
				navigate(route, {
					state: state
				});
			}
			else if ( activeSubscription === true ) {
				navigate('/my-cities', {
					state: state
				});
			}
			else if ( freeTrialUsed === false && state !== undefined && state !== null && state.freeTrial !== undefined ) {
				navigate(state.freeTrial, {
					state: state
				});
			}
			else {
				navigate('/profile', {
					state: state
				});
			}
			setLoading(false);
			setDisabled(false);
		}
		else {
			if ( loginUser.code === "auth/wrong-password" ) {
				setErrorMessage("Incorrect password 😔");
			}
			else if ( loginUser.code === "auth/user-not-found" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/invalid-email" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else {
				alert(loginUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	};

	const handleKeyDown = event => {
		if ( event.key === "Enter" ) {
			actionButtonRef.current.focus();
		}
	};

	const signUpPage = () => {
		navigate(signUpRoute, {
			state: state
		});
	};

	return (
		<div onKeyDown={handleKeyDown} className="login-component-outer-container">
			<div className="login-component-inner-container">
				{
					loading === true ?
					<Loading />
					:
					null
				}
				<div className="login-component-element-container">
					<FormInput
						value={email}
						type="email"
						label="Email"
						placeholder="youremail@email.com"
						id="email-input"
						onChange={(text) => dispatch(emailAction(text.target.value))}
					/>
				</div>
				<div className="login-component-element-container">
					<FormInput
						value={password}
						type="password"
						label="Password"
						id="password-input"
						placeholder="••••••••••"
						onChange={(text) => dispatch(passwordAction(text.target.value))}
					/>
				</div>
				{
					errorMessage !== "" ?
					<div className="login-component-incorrect-password-container">
						<span className="body-regular colour-error">
							{errorMessage}
						</span>
					</div>
					:
					null
				}
				<div className="login-component-element-container login-component-element-no-margin">
					<span
						className="body-regular colour-link"
						onClick={() => navigate("/password-forgotten", {state: state})}
					>
						Forgot your password?
					</span>
				</div>
				<div className="login-component-button-container">
					<ActionButton
						onClick={() => signInWithEmail()}
						disabled={email === "" ? true : password === "" ? true : disabled === true ? true : false}
						ref={actionButtonRef}
					>
						Login
					</ActionButton>
				</div>
				<div className="login-component-google-button-container">
					<WhiteActionButton
						onClick={() => googleSignIn()}
						disabled={disabled}
					>
						<img
							src={GoogleLogo}
							className="sign-up-component-google-logo"
							alt="Sign in with Google"
						/>
						Login with Google
					</WhiteActionButton>
				</div>
				<div className="login-component-sign-up-container">
					<span
						onClick={() => signUpPage()}
						className="body-regular colour-secondary"
					>
						Don't have an account? <span className="body-regular-underline colour-link">Sign up here</span>
					</span>
				</div>
			</div>
		</div>
	)
}

export default LoginComponent;