import React from 'react';
import { colour } from '../styles/GlobalStyles';
import '../styles/ProgressBar.css';

function ProgressBar(props) {
    const item = props.item;
    return (
        <div className="progress-bar-outer-container">
            <div className="progress-bar-inner-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="154" height="4" viewBox="0 0 154 4" fill="none">
                    <rect 
                        width="100%" 
                        height="4" 
                        rx="2" 
                        fill="#F2E1D0"
                    />
                    {
                        item.similarity_score !== undefined && item.rating === undefined && item.prediction_label === undefined && item.correlation === undefined ?
                        <rect
                            width={`${item.similarity_score * 100}%`} 
                            height="4" 
                            rx="2" 
                            fill={(item.similarity_score * 10) > 7 ? colour.greenGreen02 : (item.similarity_score * 10) > 4 ? colour.yellowYellow01 : colour.redRed02}
                        />
                        :
                        item.correlation !== undefined && item.rating === undefined && item.prediction_label === undefined ?
                        <rect
                            width={`${item.correlation * 100}%`} 
                            height="4" 
                            rx="2" 
                            fill={(item.correlation * 10) > 7 ? colour.greenGreen02 : (item.correlation * 10) > 4 ? colour.yellowYellow01 : colour.redRed02}
                        />
                        :
                        item.rating === undefined && item.prediction_label !== undefined ?
                        <rect
                            width={`${item.prediction_label * 100}%`} 
                            height="4" 
                            rx="2" 
                            fill={(item.prediction_label * 10) > 7 ? colour.greenGreen02 : (item.prediction_label * 10) > 4 ? colour.yellowYellow01 : colour.redRed02}
                        />
                        :
                        item.place_rating !== undefined && item.rating === undefined ?
                        <rect
                            width={`${item.place_rating * 10}%`} 
                            height="4" 
                            rx="2" 
                            fill={(item.place_rating) > 7 ? colour.greenGreen02 : (item.place_rating) > 4 ? colour.yellowYellow01 : colour.redRed02}
                        />
                        :
                        <rect
                            width={`${item.rating * 10}%`} 
                            height="4" 
                            rx="2" 
                            fill={item.rating > 7 ? colour.greenGreen02 : item.rating > 4 ? colour.yellowYellow01 : colour.redRed02}
                        />
                    }
                </svg>
            </div>
        </div>
    )
}

export default ProgressBar;