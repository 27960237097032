import React, { useState } from 'react';
import '../styles/Header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../logo.svg';
import { useDispatch } from 'react-redux';
import { logoClickAction } from '../actions';
import { recordEvent, containsNumbers, placesAutocompleteSearch, addRecentMarket, formatCityNames } from '../functions';
import { FBPixel } from '../integrations';
import { CityFiltering, DefaultUserImage, Loading, MobileBottomNav, UpgradeModalQuickReport } from './';
import { FilterCityInput, nonDisclosureStates, allStates } from '../styles/GlobalStyles';
import { isMobile } from 'react-device-detect';
import { HeaderTotalEffect, HeaderPartialEffect, HeaderNoEffect } from '../queries';
import { Search, Exit } from '../assets';
import { counties } from '../counties';

function Header(props) {
	const location = useLocation();
	const state = props.state === undefined || props.state === null ? location.state : props.state;
	const colQueries = props.queries !== undefined ? props.queries : [true, true];
	const mobileNav = props.mobileNav === undefined ? false : props.mobileNav;
	const path = window.location.pathname;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [userData, setUserData] = useState("");
	const [city, setCity] = useState("");
	const [filteredResults, setFilteredResults] = useState([]);
	const [selectedFilteredResult, setSelectedFilteredResult] = useState(null);
	const [upgradeModal, setUpgradeModal] = useState(false);
	const [upgradeCity, setUpgradeCity] = useState(null);
	const [freeTrial, setFreeTrial] = useState(false);
	const [subscriptions, setSubscriptions] = useState([]);
	const [mapSearchAddressAutocomplete, setMapSearchAddressAutocomplete] = useState([]);
	const [activeSearching, setActiveSearching] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userId, setUserId] = useState(null);
	const [countyList, setCountyList] = useState([]);
	const [upgradeModalData, setUpgradeModalData] = useState({title: "Upgrade to use this feature", description: "You'll need to upgrade to use this feature"});
	const devMode = window.location.hostname === "localhost" || window.location.hostname.includes("refi-787d3") ? true : false;

	const goHome = () => {
		dispatch(logoClickAction(true));
		recordEvent("Navigation", {
			oldRoute: path,
			newRoute: "/"
		});

		navigate("/my-cities", {
			state: state
		});
	};

	const bookDemo = () => {
		recordEvent("Book Demo", {});
		window.open("https://calendly.com/liam-maher/coffee-clozers-creative-finance", "_blank");
	};

	const changePath = (newPath) => {
		navigate(newPath, {
			state: state
		});
		recordEvent("Navigation", {
			oldRoute: path,
			newRoute: newPath
		});
	};

	const changeCity = async(val) => {
		setCity(val);
		const checkNumber = await containsNumbers(val);
		if ( val === "" ) {
			setFilteredResults([]);
			setSelectedFilteredResult(null);
			setActiveSearching(false);
		}

		else if ( checkNumber === true ) {
			if ( val.length === 4 || val.length === 8 || val.length === 12 || val.length > 12 ) {
				setActiveSearching(true);
			}
			queryGooglePlaces(val, "address");
			setFilteredResults([]);
			setCountyList([]);
		}
		else {
			setMapSearchAddressAutocomplete([]);
			setActiveSearching(false);
			searchCounties(val);
		}
	};

	const searchCounties = (val) => {
		const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            const mainCity = element.mainCity.city === "" ? false : true;

            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
            else if ( mainCity === true ) {
                const mainCityFullName = `${element.mainCity.city} ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityFullNameComma = `${element.mainCity.city}, ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedName = `${element.mainCity.city} ${element.state}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedNameComma = `${element.mainCity.city}, ${element.state}`.replace(".", "").toLowerCase();
                const noFullStopVal = lowerCaseVal.replace(".", "");

                if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) || mainCityAbbreviatedName.includes(noFullStopVal) || mainCityAbbreviatedNameComma.includes(noFullStopVal) ) {
                    newArray.push(counties[index]);
                }
            }
        };
		setCountyList(newArray);
	}

	const queryGooglePlaces = async(val, type) => {
		if ( (val.length > 4 && type === "address") || type === "locality" ) {
			const autocompleteParams = {
				input: val,
				language: "en",
				types: type,
				components: "country:us"
			};
			const getAutocomplete = await placesAutocompleteSearch(autocompleteParams);
			if ( getAutocomplete.status === 200 ) {
				const responses = getAutocomplete.data;
				if ( responses.length !== 0 ) {
					if ( type === "address" ) {
						setMapSearchAddressAutocomplete(responses);
						setActiveSearching(false);
					}
					else {
						const cityStateResponses = [];
						for (let index = 0; index < responses.length; index++) {
							const element = responses[index];
							const terms = element.terms;
							if ( terms.length > 1 ) {
								let cityState = terms[1].value;
								if ( cityState.length > 2 ) {
									const stateIndex = allStates.findIndex(e => e.name === cityState);
									if ( stateIndex !== -1 ) {
										cityState = allStates[stateIndex].abbreviation;
									}
								}

								const newObject = {
									city: terms[0].value,
									state: cityState
								}
								cityStateResponses.push(newObject);
							}
						}
						setFilteredResults(cityStateResponses);
						setActiveSearching(false);
					}
				}
			}
		}
	};

	const selectCounty = async(item) => {
		const todaySeconds = new Date().getTime() / 1000;
		const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
		const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
        const isAdmin = adminIds.includes(userId);
		const countyFullName = formatCityNames(item);

		if ( activeSubscription === -1 && isAdmin === false && freeTrial === false ) {
			setUpgradeModal(true);
			setUpgradeModalData({
				title: `Upgrade to view ${countyFullName}`,
				description: `You'll need an active subscription to view the best deals in ${countyFullName}`
			});
		}
		else {
			window.open(`/properties-list/CTY${item.code}`, "_blank");
			if ( userId !== null ) {
				const newItem = {
					msaTitle: countyFullName,
					msaCode: `CTY${item.code}`
				};
				await addRecentMarket(newItem, userId, null);
			}
		}
		setCountyList([]);
		setCity("");
	};

	const resetCity = () => {
		setCity("");
		setSelectedFilteredResult(null);
		setMapSearchAddressAutocomplete([]);
		setFilteredResults([]);
	};

	const selectGoogleMapProperty = async(item) => {
		setLoading(true);
		const todaySeconds = new Date().getTime() / 1000;
		const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
		const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
        const isAdmin = adminIds.includes(userId);

		if ( activeSubscription === -1 && isAdmin === false ) {
			setUpgradeModal(true);
			setUpgradeModalData({
				title: "Upgrade to use our Quick Report",
				description: "You'll need an active subscription to use our Quick report feature"
			})
		}
		else if ( item.zpid !== undefined ) {
			window.open(`/properties/${item.cityId}/${item.zpid}`, "_blank");
		}
		else {
			recordEvent("Property Searched", {});
			const encodedAddress = encodeURIComponent(item.description);
			window.open(`/quick-report/${encodedAddress}`, "_self");
		}
		setLoading(false);
		setCity("");
		setFilteredResults([]);
		setMapSearchAddressAutocomplete([]);
	};

	return (
		<header className="header-outer-container">
			{
				upgradeModal === true ?
				<UpgradeModalQuickReport
					setViewUpgradeModal={setUpgradeModal}
					upgradeCity={upgradeCity}
					title={upgradeModalData.title}
					description={upgradeModalData.description}
				/>
				:
				null
			}
			{
				loading === true ?
				<Loading />
				:
				null
			}
			{
				colQueries[0] === true && colQueries[1] === true ?
				<HeaderTotalEffect
					setFreeTrial={setFreeTrial}
					setSubscriptions={setSubscriptions}
					setUserData={setUserData}
					setCity={setCity}
					setFilteredResults={setFilteredResults}
					setSelectedFilteredResult={setSelectedFilteredResult}
					setUpgradeModal={setUpgradeModal}
					setMapSearchAddressAutocomplete={setMapSearchAddressAutocomplete}
					setLoading={setLoading}
					state={state}
					filteredResults={filteredResults}
					userData={userData}
					city={city}
					mapSearchAddressAutocomplete={mapSearchAddressAutocomplete}
					selectedFilteredResult={selectedFilteredResult}
					freeTrial={freeTrial}
					subscriptions={subscriptions}
					setUpgradeCity={setUpgradeCity}
					nonDisclosureStates={nonDisclosureStates}
					path={path}
					setUserId={setUserId}
				/>
				:
				colQueries[0] === false && colQueries[1] === false && props !== null && props.users !== null && props.users !== "" ?
				<HeaderNoEffect
					setFreeTrial={setFreeTrial}
					setSubscriptions={setSubscriptions}
					setUserData={setUserData}
					setCity={setCity}
					setFilteredResults={setFilteredResults}
					setSelectedFilteredResult={setSelectedFilteredResult}
					setUpgradeModal={setUpgradeModal}
					setMapSearchAddressAutocomplete={setMapSearchAddressAutocomplete}
					setLoading={setLoading}
					state={state}
					filteredResults={filteredResults}
					city={city}
					mapSearchAddressAutocomplete={mapSearchAddressAutocomplete}
					selectedFilteredResult={selectedFilteredResult}
					freeTrial={freeTrial}
					userData={props.users}
					subscriptions={props.subscriptions}
					setUpgradeCity={setUpgradeCity}
					nonDisclosureStates={nonDisclosureStates}
					path={path}
					setUserId={setUserId}
				/>
				:
				colQueries[0] === true && colQueries[1] === false && props !== null ?
				<HeaderPartialEffect
					setFreeTrial={setFreeTrial}
					setSubscriptions={setSubscriptions}
					setUserData={setUserData}
					setCity={setCity}
					setFilteredResults={setFilteredResults}
					setSelectedFilteredResult={setSelectedFilteredResult}
					setUpgradeModal={setUpgradeModal}
					setMapSearchAddressAutocomplete={setMapSearchAddressAutocomplete}
					setLoading={setLoading}
					state={state}
					filteredResults={filteredResults}
					city={city}
					mapSearchAddressAutocomplete={mapSearchAddressAutocomplete}
					selectedFilteredResult={selectedFilteredResult}
					freeTrial={freeTrial}
					userData={userData === null ? null : userData !== "" ? userData : null}
					subscriptions={props.subscriptions}
					collection={"Users"}
					setUpgradeCity={setUpgradeCity}
					nonDisclosureStates={nonDisclosureStates}
					path={path}
					setUserId={setUserId}
				/>
				:
				(colQueries[0] === false && colQueries[1] === true) ?
				<HeaderPartialEffect
					setFreeTrial={setFreeTrial}
					setSubscriptions={setSubscriptions}
					setUserData={setUserData}
					setCity={setCity}
					setFilteredResults={setFilteredResults}
					setSelectedFilteredResult={setSelectedFilteredResult}
					setUpgradeModal={setUpgradeModal}
					setMapSearchAddressAutocomplete={setMapSearchAddressAutocomplete}
					setLoading={setLoading}
					state={state}
					filteredResults={filteredResults}
					city={city}
					mapSearchAddressAutocomplete={mapSearchAddressAutocomplete}
					selectedFilteredResult={selectedFilteredResult}
					freeTrial={freeTrial}
					userData={props.users}
					subscriptions={subscriptions === null ? null : subscriptions.length !== 0 ? subscriptions : null}
					collection={"Subscriptions"}
					setUpgradeCity={setUpgradeCity}
					nonDisclosureStates={nonDisclosureStates}
					path={path}
					setUserId={setUserId}
				/>
				:
				null
			}
			<div className="header-inner-container header-desktop">
				{
					devMode === false ?
					<FBPixel
						email={userData === "" ? "" : userData.email}
						firstName={userData === "" ? "" : userData.firstName}
						lastName={userData === "" ? "" : userData.lastName}
					/>
					:
					null
				}
				<div className="header-logo-row">
					<img
						src={Logo}
						onClick={() => goHome()}
						className="header-logo"
						alt="Logo"
					/>
				</div>
				<div className="header-central-container">
					<div className="header-central-inner-container">
						<FilterCityInput
							value={city}
							type="text"
							id="city-input"
							placeholder="Search for a county or address"
							onChange={(text) => changeCity(text.target.value, false)}
							disabled={false}
						/>
						<img
							src={city === "" ? Search : Exit}
							className={city === "" ? "pick-city-search-icon" : "pick-city-exit-icon"}
							alt="Search"
							onClick={() => city === "" ? null : resetCity()}
						/>
						{
							city === "" || (filteredResults.length === 0 && mapSearchAddressAutocomplete === 0) ?
							null
							:
							<CityFiltering
								filteredResults={filteredResults}
								func={null}
								selectedFilteredResult={selectedFilteredResult}
								activeSearching={activeSearching}
								mapSearchAddressAutocomplete={mapSearchAddressAutocomplete}
								selectGoogleMapProperty={selectGoogleMapProperty}
								countyList={countyList}
								selectCounty={selectCounty}
							/>
						}
					</div>
				</div>
				<div className="header-right-side-container">
					{
						subscriptions.length === 0 ?
						<div 
							className="text-button header-book-demo-container"
							onClick={() => bookDemo()}
						>
							<span className="body-regular text-link">
								Book a demo
							</span>
						</div>
						:
						null
					}
					<div 
						className="cursor-pointer"
						onClick={() => changePath(userData === "" ? "/login" : "/profile")}
					>
						{
							userData === "" ?
							<span className="body-regular colour-link">
								Login
							</span>
							:
							userData.profileImage === undefined ?
							<DefaultUserImage
								size="small"
							/>
							:
							<img
								src={userData.profileImage.url}
								className="side-navigation-account-pic"
								alt="My account"
							/>
						}
					</div>
				</div>
			</div>
			<div className={"header-inner-container header-mobile " + (isMobile === true ? "header-mobile-phone-container" : "")}>
				<div className="header-logo-row">
					<div 
						className=""
						onClick={() => goHome()}
					>
						<img
							src={Logo}
							className="header-logo"
							alt="Logo"
						/>
					</div>
				</div>
				{
					mobileNav === true ?
					<MobileBottomNav />
					:
					null
				}
			</div>
		</header>
	)
}

export default Header;