import React, { useEffect, useState } from 'react';
import '../styles/Comparables.css';
import { Chip, ARVComps, RentalComps } from './';
import { ActionButton } from '../styles/GlobalStyles';

function Comparables(props) {
    const property = props.property;
    const blurred = props.blurred;
    const propertySearch = props.propertySearch;
    const compsRef = props.compsRef;
    const selectedPanel = props.selectedPanel;
    const setSelectedPanel = props.setSelectedPanel;
    const runComps = props.runComps;
    const savedData = props.savedData;
    const setSavedData = props.setSavedData;
    const savedRentData = props.savedRentData;
	const setSavedRentData = props.setSavedRentData;
    const editedProperty = props.editedProperty;
    const setEditedProperty = props.setEditedProperty;
    const strategyPanel = props.strategyPanel;
    const loanYears = props.loanYears;
    const applyEstimateModal = props.applyEstimateModal;
	const setApplyEstimateModal = props.setApplyEstimateModal;
    const [firstRender, setFirstRender] = useState(true);
    const multipleUniqueUnits = property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
    const rentalComps = multipleUniqueUnits === true && property.propertyTypeDimension === "Multi Family" ?
        property.unitPropertyComps
        :
        property.propertyTypeDimension === "Multi Family" && property.uniqueUnitPropertyDetails !== undefined ?
        property.unitPropertyComps
        :
        property.rentComps !== undefined && property.rentComps !== null ?
        property.rentComps
        :
        [];

    useEffect(() => {

        const checkRentalComps = async() => {
            setFirstRender(false);

            if ( property.unitPropertyComps !== undefined && property.unitPropertyComps !== null && property.unitPropertyComps.length === 0 ) {
                setSelectedPanel(1);
            }
            else if ( property.rentComps !== undefined && property.rentComps !== null && property.rentComps.length === 0 ) {
                setSelectedPanel(1);
            }
            else if ( property.rentComps === undefined && property.unitPropertyComps === undefined ) {
                setSelectedPanel(1);
            }
        };

        if ( firstRender === true ) {
            checkRentalComps();
        }
    }, [firstRender, property.rentComps, property.unitPropertyComps, setSelectedPanel]);

    const compOptions = [
        "Rental comps",
        property.fixerUpper === true ? "ARV comps" : "Sales comps"
    ];

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    };

    return (
        <div 
            className="rental-comps-outer-container"
            ref={compsRef}
        >
            <div className="rental-comps-inner-container">
                <div className="rental-comps-title-container margin-medium">
                    <h1 className="heading-small-semibold colour-primary">
                        Comparables
                    </h1>
                </div>
                <div className={"comparables-chips-outer-row-container " + (property.arvComps === true ? "margin-medium" : "")}>
                    <div className="rental-comps-chips-row">
                        {
                            property.arvComps === true ?
                            compOptions.map((item, index) =>
                                <Chip
                                    label={item}
                                    func={changeSelectedPanel}
                                    index={index}
                                    selected={selectedPanel}
                                    key={index}
                                    changeIcon={false}
                                    recommended={false}
                                    hover={null}
                                    leave={null}
                                    disabled={false}
                                />
                            )
                            :
                            null
                        }
                    </div>
                    {
                        property.arvCompsSimilarProp !== null && runComps !== null ?
                        <div className="comparables-run-comps-button-container relative-container mobile-none">
                            <ActionButton
                                onClick={() => runComps()}
                            >
                                Run comps
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 16 16" 
                                    fill="none"
                                    className="comparables-arrow-icon"
                                >
                                    <path 
                                        d="M15.5 1.85735V14.0735C15.5 14.8231 14.8923 15.4308 14.1427 15.4308C13.393 15.4308 12.7853 14.8231 12.7853 14.0735L12.7853 5.13427L2.81714 15.1024C2.28706 15.6325 1.42764 15.6325 0.897558 15.1024C0.36748 14.5724 0.367481 13.7129 0.897558 13.1829L10.8657 3.2147L1.92653 3.21469C1.17688 3.21469 0.56918 2.60699 0.56918 1.85735C0.569179 1.10771 1.17689 0.5 1.92653 0.5L14.1427 0.500002C14.8923 0.500002 15.5 1.10771 15.5 1.85735Z" 
                                        fill="#FFFEFC"
                                    />
                                </svg>
                            </ActionButton>
                        </div>
                        :
                        null
                    }
                </div>
                {
                    selectedPanel === 0 ?
                    <RentalComps
                        property={property}
                        comps={rentalComps === null ? [] : rentalComps}
                        blurred={blurred}
                        propertySearch={propertySearch}
                        partnerEdit={false}
                        deleteComp={null}
                        rent={null}
                        multipleUniqueUnits={multipleUniqueUnits}
                        savedData={savedRentData}
                        setSavedData={setSavedRentData}
                        editedProperty={editedProperty}
                        setEditedProperty={setEditedProperty}
                        strategyPanel={strategyPanel}
                        applyEstimateModal={applyEstimateModal}
                        setApplyEstimateModal={setApplyEstimateModal}
                    />
                    :
                    <ARVComps
                        comps={property.arvCompsSimilarProp}
                        blurred={blurred}
                        propertySearch={propertySearch}
                        partnerEdit={false}
                        deleteComp={null}
                        property={property}
                        savedData={savedData}
                        setSavedData={setSavedData}
                        editedProperty={editedProperty}
                        setEditedProperty={setEditedProperty}
                        strategyPanel={strategyPanel}
                        loanYears={loanYears}
                        applyEstimateModal={applyEstimateModal}
                        setApplyEstimateModal={setApplyEstimateModal}
                    />
                }
            </div>
        </div>
    )
};

export default Comparables;