import React from 'react';
import '../styles/UnassignedCounties.css';

function UnassignedCounties(props) {
    const cta = props.cta;

    return (
        <div className="unassigned-counties-outer-container margin-top-small">
            <div className="unassigned-counties-inner-container">
                <div className="unassigned-counties-badge-container subtext-margin-right-large">
                    <span className="label-semibold-caps colour-regular block-text text-align-center">
                        Reminder
                    </span>
                </div>
                <div 
                    className="text-button button-row"
                    onClick={() => cta()}
                >
                    <span className="body-regular text-link block-text subtext-margin-right-large">
                        Finish selecting your counties.
                    </span>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 16 16" 
                        fill="none"
                    >
                        <path 
                            d="M15.1521 8.71167L8.74807 15.1157C8.35509 15.5086 7.71794 15.5086 7.32496 15.1157C6.93198 14.7227 6.93198 14.0855 7.32496 13.6926L12.0111 9.00641L1.56 9.00641C1.00424 9.00641 0.553711 8.55588 0.553711 8.00012C0.553711 7.44436 1.00424 6.99383 1.56 6.99383L12.0111 6.99383L7.32496 2.30769C6.93198 1.91471 6.93198 1.27756 7.32496 0.884579C7.71794 0.491599 8.35509 0.491599 8.74807 0.884579L15.1521 7.28857C15.545 7.68155 15.545 8.31869 15.1521 8.71167Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
};

export default UnassignedCounties;