import React from 'react';
import '../styles/PriceChange.css';
import { DownArrow, UpArrow } from '../assets';
import moment from 'moment';
import { formatterCompact, formatterLong } from '../styles/GlobalStyles';

function PriceChange(props) {
    // const priceChangeHistory = props.priceChangeHistory;
    const priceChangeTotalAmount = props.priceChangeTotalAmount;
    const propertyPage = props.propertyPage;
    const priceDrop = props.priceDrop;
    const priceChangeLatestDate = props.priceChangeLatestDate;

    const todayDate = moment().format("YYYY-MM-DD");
    let priceChangeDateChecked;

    if ( priceChangeLatestDate === todayDate ) {
        priceChangeDateChecked = "Today";
    }
    else {
        priceChangeDateChecked = moment(priceChangeLatestDate).format("MMM DD");
        // const priceChangeDateTimestamp = moment(priceChangeDate);
        // const todayTimestamp = moment(todayDate);
        // const dateDifference = todayTimestamp.diff(priceChangeDateTimestamp, 'days')
        // console.log("dateDifference", dateDifference);
    }

    return (
        <div className={"price-change-container " + (propertyPage === true ? "price-change-property-page" : "")}>
            {
                propertyPage === true ?
                <div className="price-change-label-container">
                    <span className={"body-regular " + (priceDrop === true ? "colour-error" : "colour-green")}>
                        Price
                        {
                            priceDrop === true ?
                            " cut"
                            :
                            " gain"
                        }
                    </span>
                </div>
                :
                null
            }
			<div className="price-change-icon-container">
                <img
                    src={
                            priceDrop === true ?
                            DownArrow
                            :
                            UpArrow
                        }
                    className="price-change-icon"
                    alt="Price Change"
                />
            </div>
            <div className="price-change-text-container">
                <span className={"body-semibold block-text price-change-price-text " + (priceDrop === true ? "colour-error" : "colour-green")}>
                    {
                        propertyPage === true ?
                        formatterLong.format(Math.abs(priceChangeTotalAmount)).replace(".00", "")
                        :
                        formatterCompact.format(Math.abs(priceChangeTotalAmount)).replace(".00", "")
                    }
                </span>
                <span className="body-regular colour-secondary">
                    {priceChangeDateChecked}
                </span>
            </div>
		</div>
    )
}

export default PriceChange;