import React, { Fragment, useState } from 'react';
import '../styles/MFHHouseHackMultiUnitType.css';
import { FinancialsInput, FinancialsInputNoLabel } from '../styles/GlobalStyles';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeDot } from '../assets';
import { DefaultTooltip, RentAccordion } from '../components';

function MFHHouseHackMultiUnitType(props) {
    const units = props.units;
    const unitLiving = props.unitLiving;
    const blurred = props.blurred;
    const rent = props.rent;
    const defaultRent = props.defaultRent;
    const onChangeRent = props.onChangeRent;
    const onChangeUnitLiving = props.onChangeUnitLiving;
    const resetRentEstimates = props.resetRentEstimates;
    const onChangeUnitRent = props.onChangeUnitRent;
    const property = props.property;
    const [rentTooltip, setRentTooltip] = useState(false);
    const [rentAccordion, setRentAccordion] = useState(false);

    const handleRentAccordion = () => {
        setRentAccordion(!rentAccordion);
    };
    
    const clickUnit = (index, ii, e) => {
        const target = e.target;
        const targetClass = target.classList.value;
        const parentTargetClass = target.parentNode.classList.value;

        if ( targetClass.includes("Mui") || parentTargetClass.includes("Mui") ) {
            return;
        }
        else {
            onChangeUnitLiving(index, ii);
        }
    };
    
    return (
        <div className="mfh-house-hack-multi-unit-type-outer-container margin-x-large">
            <div className="mfh-house-hack-multi-unit-type-inner-container margin-medium">
                <div className="mfh-house-hack-multi-unit-type-title-container margin-medium">
                    <span className="body-semibold colour-primary">
                        Which unit do you plan to live in?
                    </span>
                </div>
                <div className="mfh-house-hack-multi-unit-type-inputs-container margin-large">
                    {
                        units.map((item, index) =>
                            <div 
                                className="mfh-house-hack-multi-unit-type-element"
                                key={index}
                            >
                                <span className="body-regular colour-primary block-text margin-x-small">
                                    {item.label}
                                </span>
                                <div className="mfh-house-hack-multi-unit-type-grid">
                                    {
                                        item.units.map((unit, ii) =>
                                            <div
                                                key={ii}
                                                className={"mfh-house-hack-multi-unit-type-card-element " + (unitLiving[0] === index && unitLiving[1] === ii ? "mfh-house-hack-multi-unit-type-card-element-selected" : "")}
                                                onClick={(e) => clickUnit(index, ii, e)}
                                            >
                                                <div className="mfh-house-hack-multi-unit-type-inner-card-element">
                                                    <div className="mfh-house-hack-multi-unit-type-icon-container">
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="32" 
                                                            height="32" 
                                                            viewBox="0 0 32 32" 
                                                            fill="none"
                                                        >
                                                            <path 
                                                                fillRule="evenodd" 
                                                                clipRule="evenodd" 
                                                                d="M2.42857 32C1.63959 32 1 31.3488 1 30.5455V1.45455C1 0.651223 1.63959 0 2.42857 0H29.5714C30.3604 0 31 0.651222 31 1.45455V30.5455C31 31.3488 30.3604 32 29.5714 32H2.42857ZM2.42857 1.45455H29.5714V30.5455H25.2857V13.8182C25.2857 8.59658 21.1284 4.36364 16 4.36364C10.8716 4.36364 6.71429 8.59658 6.71429 13.8182V30.5455H2.42857L2.42857 1.45455ZM11.3958 8.26929C12.489 7.29214 13.9152 6.65846 15.4912 6.54545V10.1781C14.9443 10.2669 14.4467 10.4962 14.0395 10.8264L11.3958 8.26929ZM9.21429 13.0909C9.21429 11.5393 9.77087 10.1135 10.7015 8.99001L13.377 11.578C13.1036 12.0205 12.9465 12.538 12.9465 13.0909L9.21429 13.0909ZM22.7857 13.0909C22.7857 11.5394 22.2292 10.1136 21.2986 8.99013L18.6232 11.578C18.8966 12.0205 19.0537 12.538 19.0537 13.0909L22.7857 13.0909ZM17.9607 10.8264L20.6043 8.2694C19.5112 7.29223 18.085 6.65852 16.509 6.54547V10.1781C17.0559 10.2669 17.5535 10.4962 17.9607 10.8264ZM11.1078 22.6634C10.9488 23.1215 10.5198 23.4496 10.0154 23.4496C9.37573 23.4496 8.85714 22.9216 8.85714 22.2703C8.85714 21.6189 9.37573 21.0909 10.0154 21.0909C10.5198 21.0909 10.9488 21.4191 11.1078 21.8771H12.7181C12.9314 21.8771 13.1042 22.0532 13.1042 22.2703C13.1042 22.4874 12.9314 22.6634 12.7181 22.6634H11.1078Z" 
                                                                fill="#292621"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="mfh-house-hack-multi-unit-type-text-container">
                                                        <span className="body-semibold colour-primary block-text margin-x-small">
                                                            {unit.label}
                                                        </span>
                                                        <div className="mfh-house-hack-multi-unit-type-input-container">
                                                            <FinancialsInput
                                                                value={blurred === true ? "999" : unit.value}
                                                                type="number"
                                                                label={
                                                                    unitLiving[0] === index && unitLiving[1] === ii ?
                                                                    unit.label
                                                                    :
                                                                    Number(unit.value) !== Number(unit.original)  ?
                                                                    <Fragment>
                                                                        {unit.label}
                                                                        <img
                                                                            src={ChangeDot}
                                                                            className="financials-panel-change-dot"
                                                                            alt="Change"
                                                                        />
                                                                    </Fragment>
                                                                    :
                                                                    unit.label
                                                                }
                                                                id={`${unit.label}-input`}
                                                                placeholder={`${unit.value}`}
                                                                onWheel={(e) => e.target.blur()}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                }}
                                                                disabled={blurred === true ? true : unitLiving[0] === index && unitLiving[1] === ii ? true : false}
                                                                onChange={(text) => onChangeUnitRent(text.target.value, index, ii)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="mfh-house-hack-multi-unit-type-total-container">
                    <div className="mfh-house-hack-multi-unit-type-total-label-container margin-x-small">
                        <span className="body-semibold colour-primary block-text subtext-margin-right">
                            Total rent
                        </span>
                        <div 
                            className="relative-container cursor-pointer"
                            onMouseEnter={() => setRentTooltip(true)}
                            onMouseLeave={() => setRentTooltip(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M7.61547 11.0352C7.65031 11.07 7.69262 11.0875 7.7424 11.0875H8.36213C8.41689 11.0875 8.4592 11.07 8.48907 11.0352C8.52391 11.0004 8.54133 10.958 8.54133 10.9083V7.384C8.54133 7.33422 8.52391 7.29191 8.48907 7.25707C8.4592 7.22222 8.41689 7.2048 8.36213 7.2048H7.7424C7.69262 7.2048 7.65031 7.22222 7.61547 7.25707C7.58062 7.29191 7.5632 7.33422 7.5632 7.384V10.9083C7.5632 10.958 7.58062 11.0004 7.61547 11.0352Z" fill="#716E69"/>
                                <path d="M7.57813 5.64287C7.61298 5.67273 7.65778 5.68767 7.71253 5.68767H8.392C8.44676 5.68767 8.49156 5.67273 8.5264 5.64287C8.56124 5.60802 8.57867 5.56322 8.57867 5.50847V4.97087C8.57867 4.92109 8.56124 4.87878 8.5264 4.84393C8.49156 4.80909 8.44676 4.79167 8.392 4.79167H7.71253C7.65778 4.79167 7.61298 4.80909 7.57813 4.84393C7.54827 4.87878 7.53333 4.92109 7.53333 4.97087V5.50847C7.53333 5.56322 7.54827 5.60802 7.57813 5.64287Z" fill="#716E69"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM14.0667 8C14.0667 11.3505 11.3505 14.0667 8 14.0667C4.64947 14.0667 1.93333 11.3505 1.93333 8C1.93333 4.64947 4.64947 1.93333 8 1.93333C11.3505 1.93333 14.0667 4.64947 14.0667 8Z" fill="#716E69"/>
                            </svg>
                            {
                                rentTooltip === true ?
                                <DefaultTooltip
                                    title={"How do we estimate rent?"}
                                    description={"Our Rent Estimate is an estimated monthly rental price, computed using a proprietary formula. We review local rental comparables and other industry estimates. It is a starting point in determining the monthly rental price for a specific property."}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="button-row strategies-panel-financials-input">
                            <FinancialsInputNoLabel
                                value={blurred === true ? "999" : rent}
                                type="number"
                                label={
                                    Number(rent) !== Number(defaultRent) ?
                                    <Fragment>
                                        <img
                                            src={ChangeDot}
                                            className="financials-panel-change-dot"
                                            alt="Change"
                                        />
                                    </Fragment>
                                    :
                                    null
                                }
                                id={`rent-input`}
                                placeholder={`${rent}`}
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                disabled={blurred}
                                onChange={(text) => onChangeRent(text.target.value)}
                            />
                        </div>
                        {
                            blurred === false ?
                            <div 
                                className={"new-financials-costs-recurring-costs-reset-container " + (Number(rent) !== Number(defaultRent) ? "" : "view-none")}
                                onClick={() => resetRentEstimates()}
                            >
                                <span className="label-semibold colour-link">
                                    Reset
                                </span>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <RentAccordion
                rentAccordion={rentAccordion}
                handleRentAccordion={handleRentAccordion}
                property={property}
            />
        </div>
    )
};

export default MFHHouseHackMultiUnitType;