import React, { useEffect, useState } from 'react';
import '../styles/GrantSubscription.css';
import { Header, Footer, Loading } from '../components';
import { checkOnAuthStateChanged, cloudFunctionV2, getCountySearch, getDocument, setData, setMergeData } from '../functions';
import { FormInput, FilterCityInput, colour, CustomSelect, ActionButton } from '../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import { Search, Exit } from '../assets';
import moment from 'moment';
import { counties } from '../counties';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

function GrantSubscription() {

    const [enabled, setEnabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("");
    const [price, setPrice] = useState("");
    const [paymentId, setPaymentId] = useState("");
    const [months, setMonths] = useState("");
    const [county, setCounty] = useState("");
    const [countyOptions, setCountyOptions] = useState([]);
    const [cities, setCities] = useState([]);
    const [autoBilling, setAutoBilling] = useState(false);
    const [countyLoading, setCountyLoading] = useState(false);
    const [savedLoading, setSavedLoading] = useState(false);
    const [activeCities, setActiveCities] = useState(false);
    const [creativeOption, setCreativeOption] = useState(true);
    const navigate = useNavigate();

    const autoBillingOptions = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        }
    ];

    const creativeOptions = [
        {
            label: "Creative",
            value: true
        },
        {
            label: "Vanilla",
            value: false
        }
    ];

    useEffect(() => {
        document.title = "Grant Subscription";
        const checkAdmin = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
                const isAdmin = adminIds.includes(userId);
                if ( isAdmin === true ) {
                    setEnabled(true);
                }
                else {
                    navigate("/my-cities");
                }
            }
            else {
                navigate("/my-cities");
            }
            setLoading(false);
        };

        checkAdmin();
    }, [navigate]);

    const changeCounty = (val) => {
        setCounty(val);

        const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
        };
        setCountyOptions(newArray);
    };

    const selectCounty = async(item) => {
        setCountyLoading(true);
        setCounty("");
        const countyParams = {
            countyId: `CTY${item.code}`
        }
		const getCountyParams = await getCountySearch(countyParams);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "county",
			queryString: getCountyParams
		};
		const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getCityDetails.status === 200 ) {
            const body = getCityDetails.body;
            body.price = item.price;
            const msaCityList = [];
            const msaCityIDs = [];
            for (let index = 0; index < body.msaCityList.length; index++) {
                const element = body.msaCityList[index];
                const newObject = {
                    city: element.city,
                    state: element.state,
                    cityId: element.cityId
                };
                msaCityList.push(newObject);
                msaCityIDs.push(element.cityId);
            }

            const today = new Date();
            const endDate = moment(today).add(1, "years");

            const newObject = {
                allCities: false,
                autoBilling: false,
                endDate: endDate._d,
                metroArea: true,
                msaTitle: countyParams.countyId,
                msaCode: body.countyId,
                metroCities: msaCityList,
                msaCityIds: msaCityIDs,
                price: price,
                id: paymentId,
                startDate: today
            };
            setCities([...cities, newObject]);
        }
        setCountyLoading(false);
    };

    const deleteItem = (item) => {
        const newCities = cities.filter(city => city.id !== item.id);
        setCities(newCities);
    };

    const changeAutoBilling = (val) => {
        setAutoBilling(val);
    };

    const changeActiveCities = (val) => {
        setActiveCities(val);
    };

    const changeCreative = (val) => {
        setCreativeOption(val);
    };

    const addSubscription = async(data) => {
        const queryBuyBoxes = await getDocument("Buy Boxes", userId);
        const querySubscriptions = await getDocument("Subscriptions", userId);
        const queryFinancialPreferences = await getDocument("Financial Preferences", userId);
        const oldBuyBoxes = queryBuyBoxes.status === 200 ? queryBuyBoxes.data.data : [];
        const oldSubscriptions = querySubscriptions.status === 200 ? querySubscriptions.data.data : [];
        const oldFinancialPreferences = queryFinancialPreferences.status === 200 ? queryFinancialPreferences.data.data : null; 

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const buyBoxIndex = element.metroArea === false ? oldBuyBoxes.findIndex(e => e.metroArea === false && e.cityId === element.cityId) : oldBuyBoxes.findIndex(e => e.metroArea === true && e.msaCode === element.msaCode);
            if ( buyBoxIndex === -1 ) {
                const newBuyBox = {
                    buyBoxes: [],
                    loaded: true
                };
        
                if ( element.metroArea === true ) {
                    newBuyBox.metroArea = true;
                    newBuyBox.metroCities = element.metroCities;
                    newBuyBox.msaCityIds = element.msaCityIds;
                    newBuyBox.msaTitle = element.msaTitle;
                    newBuyBox.msaCode = element.msaCode;
                }
                else {
                    newBuyBox.city = element.city;
                    newBuyBox.state = element.state;
                    newBuyBox.cityId = element.cityId
                    newBuyBox.metroArea = false;
                }
                oldBuyBoxes.push(newBuyBox);
            }   
        };
        await setData("Buy Boxes", userId, oldBuyBoxes);
    
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const subscriptionIndex = element.metroArea === false ? oldSubscriptions.findIndex(e => e.metroArea === false && e.cityId === element.cityId) : oldSubscriptions.findIndex(e => e.metroArea === true && e.msaCode === element.msaCode);
            if ( subscriptionIndex === -1 ) {
                oldSubscriptions.push(element);
            }
        };
        await setData("Subscriptions", userId, oldSubscriptions);
    
        if ( queryFinancialPreferences.status === 200 ) {
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                const customCities = oldFinancialPreferences.customCities;
                const customCityIndex = element.metroArea === false ? customCities.findIndex(e => e.metroArea === false && e.cityId === element.cityId) : customCities.findIndex(e => e.metroArea === true && e.msaCode === element.msaCode);
                if ( customCityIndex === -1 ) {
                    const newCustomCity = {
                        icon: "",
                        settings: []
                    };
            
                    if ( element.metroArea === true ) {
                        newCustomCity.metroArea = true;
                        newCustomCity.msaTitle = element.msaTitle;
                        newCustomCity.msaCode = element.msaCode;
                    }
                    else {
                        newCustomCity.city = element.city;
                        newCustomCity.state = element.state;
                        newCustomCity.cityId = element.cityId;
                        newCustomCity.metroArea = false;
                    }
                    customCities.push(newCustomCity);
                    oldFinancialPreferences.customCities = customCities;
                }
            };
            await setData("Financial Preferences", userId, oldFinancialPreferences);
        }
    };

    const addCreativeSubscription = async(data) => {
        const creativeColRef = "Subscriptions";
        const querySubscriptions = await getDocument(creativeColRef, userId);
        const oldSubscriptions = querySubscriptions.status === 200 ? querySubscriptions.data.data : [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const subscriptionIndex = element.metroArea === false ? oldSubscriptions.findIndex(e => e.metroArea === false && e.cityId === element.cityId) : oldSubscriptions.findIndex(e => e.metroArea === true && e.msaCode === element.msaCode);
            if ( subscriptionIndex === -1 ) {
                oldSubscriptions.push(element);
            }
        };
        await setData(creativeColRef, userId, oldSubscriptions);
    };

    const addActiveCity = async() => {
        const today = new Date();
        const endDate = moment(today).add(Number(months), "M");
        const endDateFormatted = endDate._d;

        const colRef = "Active Cities";
        const docRef = "List";
        const queryData = await getDocument(colRef, docRef);
        if ( queryData.status === 200 ) {
            const data = queryData.data.data;
            for (let index = 0; index < cities.length; index++) {
                const element = cities[index];
                const cityIndex = data.findIndex(e => e.cityId === element.msaCode);
                if ( cityIndex === -1 ) {
                    const newObject = {
                        city: element.msaTitle,
                        state: "",
                        cityId: element.msaCode,
                        options: [
                            {
                                endDate: endDateFormatted,
                                paid: true,
                                userId: userId,
                            }
                        ]
                    };
                    data.push(newObject);
                }
                else {
                    data[cityIndex].options[0].endDate = endDateFormatted
                    data[cityIndex].options[0].paid = true;
                    data[cityIndex].options[0].userId = userId;
                }
            };

            await setMergeData(colRef, docRef, data);
        }
    };

    const complete = async() => {
        setSavedLoading(true);
        if ( userId === "" ) {
            alert("No user ID");
        }
        else if ( price === "" ) {
            alert("No price");
        }
        else if ( paymentId === "" ) {
            alert("No payment ID");
        }
        else if ( months === "" ) {
            alert("No months");
        }
        else if ( cities.length === 0 ) {
            alert("No counties");
        }
        else {
            const newSubscriptionArray = [];
            for (let index = 0; index < cities.length; index++) {
                const element = cities[index];
                const startDate = new Date();
                const endDate = moment(startDate).add(Number(months), "M");
                const newSubscription = {
                    allCities: false,
                    autoBilling: false,
                    credits: false,
                    discountApplied: null,
                    endDate: endDate._d,
                    id: paymentId,
                    metroArea: true,
                    metroCities: element.metroCities,
                    msaCityIds: element.msaCityIds,
                    msaCode: element.msaCode,
                    msaTitle: element.msaTitle,
                    price: Number(price),
                    startDate: startDate
                };

                if ( autoBilling === true ) {
                    newSubscription.autoBilling = true;
                    newSubscription.cancelled = false;
                }
                newSubscriptionArray.push(newSubscription);
            }

            if ( creativeOption === true ) {
                await addCreativeSubscription(newSubscriptionArray);
            }
            else {
                await addSubscription(newSubscriptionArray);
            }

            if ( activeCities === true ) {
                await addActiveCity();
            }

            setCities([]);
        }
        setSavedLoading(false);
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
		}
	};

    return (
        <div className="grant-subscription-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            <div className="grant-subscription-inner-container">
                {
                    loading === true ?
                    <Loading />
                    :
                    null
                }
                {
                    enabled === true ?
                    <div className="grant-subscription-body-container relative-container">
                        <div className="grant-subscription-title-container margin-medium">
                            <h1 className="heading-large-semibold colour-primary text-align-center">
                                Grant a subscription
                            </h1>
                        </div>
                        {
                            savedLoading === true ?
                            <Loading />
                            :
                            null
                        }
                        <div className="grant-subscription-elements-container margin-x-large">
                            <div className="grant-subscription-element-container">
                                <FormInput
                                    value={userId}
                                    type="text"
                                    label="User ID"
                                    id="user-id-input"
                                    placeholder="User ID"
                                    onChange={(text) => setUserId(text.target.value)}
                                />
                            </div>
                            <div className="grant-subscription-element-container">
                                <FormInput
                                    value={price}
                                    type="text"
                                    label="Total Price (between counties)"
                                    id="price-input"
                                    placeholder="Price"
                                    onChange={(text) => setPrice(text.target.value)}
                                />
                            </div>
                            <div className="grant-subscription-element-container">
                                <FormInput
                                    value={paymentId}
                                    type="text"
                                    label="Payment ID"
                                    id="payment-id-input"
                                    placeholder="sub_12345"
                                    onChange={(text) => setPaymentId(text.target.value)}
                                />
                            </div>
                            <div className="grant-subscription-element-container">
                                <FormInput
                                    value={months}
                                    type="text"
                                    label="Months"
                                    id="months-input"
                                    placeholder="12"
                                    onChange={(text) => setMonths(text.target.value)}
                                />
                            </div>
                            <div className="grant-subscription-element-container">
                                <span className="body-semibold colour-primary block-text margin-x-small">
                                    County selection
                                </span>
                                <div className="relative-container margin-medium">
                                    <FilterCityInput
                                        value={county}
                                        type="text"
                                        id="upgrade-city-input"
                                        placeholder="Search for any county"
                                        onChange={(text) => changeCounty(text.target.value)}
                                        disabled={false}
                                    />
                                    <img
                                        src={county === "" ? Search : Exit}
                                        className={county === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                                        alt="Search"
                                    />
                                    {
                                        countyOptions.length > 0 && county !== "" ?
                                            <div className="city-filtering-outer-container">
                                                {
                                                    countyOptions.map((item, index) =>
                                                        <div 
                                                            className="city-filtering-element"
                                                            key={index}
                                                            onClick={() => selectCounty(item)}
                                                        >
                                                            <span className="body-regular colour-primary">
                                                                {item.county}{item.state === "LA" ? "" : " County"}, {item.state}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        :
                                        null
                                    }
                                    {
                                        countyLoading === true ?
                                        <Loading />
                                        :
                                        null
                                    }
                                </div>
                                {
                                    cities.map((item, index) =>
                                        <div
                                            className="grant-subscription-county-element margin-x-small"
                                            key={index}
                                        >
                                            <span className="body-regular colour-primary block-text margin-x-small">
                                                {item.msaTitle}
                                            </span>
                                            <span 
                                                className="label-regular colour-error cursor-pointer"
                                                onClick={() => deleteItem(item)}
                                            >
                                                Delete
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="grant-subscription-element-container">
                                <span className="body-semibold colour-primary block-text margin-x-small">
                                    Auto billing?
                                </span>
                                <Box>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="sort-select-label"
                                            id="auto-billing-select"
                                            value={autoBilling}
                                            label=""
                                            placeholder=""
                                            onChange={(event) => changeAutoBilling(event.target.value)}
                                            input={<CustomSelect />}
                                        >
                                            {
                                                autoBillingOptions.map((item, index) =>
                                                    <MenuItem 
                                                        key={index}
                                                        value={item.value}
                                                        style={styles.menuItem}
                                                    >
                                                        {item.label}
                                                    </MenuItem>	
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="grant-subscription-element-container">
                                <span className="body-semibold colour-primary block-text margin-x-small">
                                    Add to "Active Cities"
                                </span>
                                <Box>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="sort-select-label"
                                            id="add-active-cities-select"
                                            value={activeCities}
                                            label=""
                                            placeholder=""
                                            onChange={(event) => changeActiveCities(event.target.value)}
                                            input={<CustomSelect />}
                                        >
                                            {
                                                autoBillingOptions.map((item, index) =>
                                                    <MenuItem 
                                                        key={index}
                                                        value={item.value}
                                                        style={styles.menuItem}
                                                    >
                                                        {item.label}
                                                    </MenuItem>	
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="grant-subscription-element-container">
                                <span className="body-semibold colour-primary block-text margin-x-small">
                                    Creative?
                                </span>
                                <Box>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="sort-select-label"
                                            id="add-active-cities-select"
                                            value={creativeOption}
                                            label=""
                                            placeholder=""
                                            onChange={(event) => changeCreative(event.target.value)}
                                            input={<CustomSelect />}
                                        >
                                            {
                                                creativeOptions.map((item, index) =>
                                                    <MenuItem 
                                                        key={index}
                                                        value={item.value}
                                                        style={styles.menuItem}
                                                    >
                                                        {item.label}
                                                    </MenuItem>	
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>
                        <div className="grant-subscription-button-container">
                            <ActionButton
                                disabled={savedLoading}
                                onClick={() => complete()}
                            >
                                Grant subscription
                            </ActionButton>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            <Footer />
        </div>
    )
};

export default GrantSubscription;