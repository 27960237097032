import React from 'react';
import '../styles/PremiumModal.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { recordEvent } from '../functions';
import { ActionButton } from "../styles/GlobalStyles";

function PremiumModal(props) {
    const setPremiumModalOpen = props.setPremiumModalOpen;
    const location = useLocation();
    const state = props.state === null ? location.state : props.state;
    const navigate = useNavigate();

    const goToSearch = () => {
        navigate("/quick-report", {
            state: state
        });
    };

    const closeModal = () => {
        recordEvent("Premium modal closed", {});
        setPremiumModalOpen(false);
    };

    const upgrade = () => {
        recordEvent("Upgrade button clicked", {});
        navigate("/pricing", {
            state: state
        });
    };

    return (
        <div className="premium-modal-outer-container">
            <div className="premium-modal-inner-container relative-container">
                <div 
                    className="premium-modal-close-container cursor-pointer"
                    onClick={() => closeModal()}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M2.25732 0.419021C1.78932 -0.100741 0.988587 -0.142706 0.468826 0.32529C-0.0509363 0.793286 -0.092901 1.59402 0.375095 2.11378L9.29582 12.0213L0.41338 21.8862C-0.0546153 22.406 -0.0126517 23.2067 0.50711 23.6747C1.02687 24.1427 1.82761 24.1007 2.2956 23.581L10.9999 13.9138L19.7042 23.5809C20.1722 24.1007 20.9729 24.1427 21.4927 23.6747C22.0125 23.2067 22.0544 22.4059 21.5864 21.8862L12.704 12.0213L21.6247 2.11382C22.0927 1.59406 22.0507 0.793322 21.531 0.325326C21.0112 -0.142669 20.2105 -0.100704 19.7425 0.419057L10.9999 10.1287L2.25732 0.419021Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div>
                <div className="premium-modal-title-container margin-large">
                    <h2 className="heading-large-semibold colour-primary margin-medium">
                        Premium feature 👀
                    </h2>
                    <span className="body-regular colour-primary">
                        That’s a premium feature. 
                        You can unlock this (and much more!) by upgrading to a paid plan.
                    </span>
                </div>
                <ul className="premium-modal-list-container margin-large">
                    <li className="premium-modal-list-element margin-large">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" d="M15.4995 1.43765C16.1415 2.0449 16.1696 3.05758 15.5623 3.69952L6.22901 13.5662C5.93211 13.8801 5.52089 14.0605 5.08888 14.0665C4.65687 14.0725 4.2408 13.9035 3.93529 13.598L0.468629 10.1314C-0.15621 9.50653 -0.15621 8.49347 0.468629 7.86863C1.09347 7.24379 2.10653 7.24379 2.73137 7.86863L5.0348 10.1721L13.2376 1.50048C13.8449 0.858536 14.8576 0.830406 15.4995 1.43765Z" 
                                fill="#748EA0"
                            />
                        </svg>
                        <span className="body-regular colour-secondary">
                            Get <span className="body-semibold">daily</span> data updates
                        </span>
                    </li>
                    <li className="premium-modal-list-element margin-large">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" d="M15.4995 1.43765C16.1415 2.0449 16.1696 3.05758 15.5623 3.69952L6.22901 13.5662C5.93211 13.8801 5.52089 14.0605 5.08888 14.0665C4.65687 14.0725 4.2408 13.9035 3.93529 13.598L0.468629 10.1314C-0.15621 9.50653 -0.15621 8.49347 0.468629 7.86863C1.09347 7.24379 2.10653 7.24379 2.73137 7.86863L5.0348 10.1721L13.2376 1.50048C13.8449 0.858536 14.8576 0.830406 15.4995 1.43765Z" 
                                fill="#748EA0"
                            />
                        </svg>
                        <span className="body-regular colour-secondary">
                            Download properties into a CSV or PDF
                        </span>
                    </li>
                    <li className="premium-modal-list-element margin-large">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" d="M15.4995 1.43765C16.1415 2.0449 16.1696 3.05758 15.5623 3.69952L6.22901 13.5662C5.93211 13.8801 5.52089 14.0605 5.08888 14.0665C4.65687 14.0725 4.2408 13.9035 3.93529 13.598L0.468629 10.1314C-0.15621 9.50653 -0.15621 8.49347 0.468629 7.86863C1.09347 7.24379 2.10653 7.24379 2.73137 7.86863L5.0348 10.1721L13.2376 1.50048C13.8449 0.858536 14.8576 0.830406 15.4995 1.43765Z" 
                                fill="#748EA0"
                            />
                        </svg>
                        <span className="body-regular colour-secondary block-text subtext-margin-right-large">
                            <span className="body-semibold">Unlimited</span> property search credits
                        </span>
                        <span 
                            className="body-regular-underline colour-link"
                            onClick={() => goToSearch()}
                        >
                            Try a free search here
                        </span>
                    </li>
                    <li className="premium-modal-list-element margin-large">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" d="M15.4995 1.43765C16.1415 2.0449 16.1696 3.05758 15.5623 3.69952L6.22901 13.5662C5.93211 13.8801 5.52089 14.0605 5.08888 14.0665C4.65687 14.0725 4.2408 13.9035 3.93529 13.598L0.468629 10.1314C-0.15621 9.50653 -0.15621 8.49347 0.468629 7.86863C1.09347 7.24379 2.10653 7.24379 2.73137 7.86863L5.0348 10.1721L13.2376 1.50048C13.8449 0.858536 14.8576 0.830406 15.4995 1.43765Z" 
                                fill="#748EA0"
                            />
                        </svg>
                        <span className="body-regular colour-secondary">
                            <span className="body-semibold">Unlimited</span> buy box emails
                        </span>
                    </li>
                </ul>
                <div className="premium-modal-buttons-row">
                    <div 
                        className="text-button"
                        onClick={() => closeModal()}
                    >
                        <span className="body-regular colour-link">
                            Maybe later
                        </span>
                    </div>
                    <div className="premium-modal-button-container">
                        <ActionButton
                            onClick={() => upgrade()}
                        >
                            Upgrade now   
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PremiumModal;