import React from 'react';
import { SingleFamilySelected, MultiFamilySelected } from '../assets';
import { CustomSelect, colour, unitsRange } from '../styles/GlobalStyles';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function ChoosePropertyType(props) {
    const propertyTypes = props.propertyTypes;
    const setPropertyTypes = props.setPropertyTypes;
    const units = props.units;
    const setUnits = props.setUnits;

    const changePropertyTypes = (index) => {
        const cloneArray = [...propertyTypes];
        cloneArray[index].included = !propertyTypes[index].included;
        setPropertyTypes(cloneArray);
    };

    const checkMultiFamily = () => {
        const multiFamIndex = propertyTypes.findIndex(item => item.code === "MultiFamily");
        if ( multiFamIndex === -1 ) {
            return false;
        }
        else if ( propertyTypes[multiFamIndex].included === true ) {
            return true;
        }
        else {
            return false;
        }
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
    };

    return (
        <div className="buy-box-setup-step-container">
            <div className="buy-box-setup-step-row">
                {
                    propertyTypes.map((item, index) =>
                        <div 
                            className={"buy-box-property-type-element cursor-pointer " + (item.included === true ? "buy-box-property-type-element-selected" : "")}
                            key={index}
                            onClick={() => changePropertyTypes(index)}
                        >
                            <img
                                src={item.code === "SingleFamily" ? SingleFamilySelected : MultiFamilySelected}
                                className="buy-box-property-type-image margin-x-small block-text"
                                alt={item.property}
                            />
                            <span className="body-regular text-link block-text text-align-center">
                                {item.property}
                            </span>
                        </div>
                    )
                }
            </div>
            {
                checkMultiFamily() === true ?
                <div className="buy-box-property-type-units-selector">
                    <span className="body-semibold colour-primary block-text margin-x-small">
                        Multi-family units
                    </span>
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                labelId="units-select-label"
                                id="units-select"
                                value={units}
                                label=""
                                onChange={(event) => setUnits(event.target.value)}
                                input={<CustomSelect />}
                            >
                                {
                                    unitsRange.map((item, index) =>
                                        <MenuItem 
                                            key={index}
                                            value={item.value}
                                            style={styles.menuItem}
                                        >
                                            {item.label}
                                        </MenuItem>	
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                :
                null
            }
        </div> 
    )
};

export default ChoosePropertyType;