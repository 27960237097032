import React from 'react';
import '../styles/PropertyListButton.css';
import { colour } from '../styles/GlobalStyles';

function PropertyListButton(props) {
    const clickFunc = props.clickFunc;
    const overlay = props.overlay;
    return (
        <div 
            className={"property-list-button-container " + (overlay === true ? "property-list-small-button-container" : "")}
            onClick={() => clickFunc()}
        >
            <div className="button-row property-list-button-inner-container">
                {
                    overlay === false ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M7.26072 0.757335C7.46143 0.547925 7.7335 0.447681 8.00067 0.457716C8.26784 0.448127 8.53947 0.548371 8.73929 0.757446L15.2901 7.59883C15.6621 7.98732 15.6487 8.60373 15.2602 8.97571C14.8717 9.3477 14.2553 9.33432 13.8833 8.94583L8.00023 2.80136L2.11669 8.94572C1.7447 9.33421 1.12829 9.34759 0.739804 8.97571C0.351315 8.60373 0.337934 7.98721 0.70992 7.59883L7.26072 0.757335Z" fill={colour.blueBlue03}/>
                        <path d="M14.6268 9.47415C14.4703 9.47827 14.3133 9.44493 14.1701 9.37435V14.6301C14.1701 14.8823 13.9658 15.0868 13.7134 15.0868H13.2567V9.37769C13.2567 9.33064 13.2424 9.28693 13.2183 9.25069C13.1773 9.18958 13.1077 9.14933 13.0283 9.14933H2.9802C2.85397 9.14933 2.75183 9.25158 2.75183 9.37769V15.0868H2.2951C2.17423 15.0868 2.0645 15.04 1.98288 14.9634C1.9249 14.9089 1.88074 14.8394 1.85755 14.761C1.84506 14.7195 1.83837 14.6756 1.83837 14.6301V9.38394C1.6943 9.45073 1.5373 9.48073 1.38164 9.47337V14.6301C1.38164 15.1346 1.79064 15.5436 2.2951 15.5436H13.7134C14.2178 15.5436 14.6268 15.1346 14.6268 14.6301V9.47415Z" fill={colour.blueBlue03}/>
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M7.99996 16C7.99996 16 13.3333 8.27885 13.3333 5.33333C13.3333 2.38781 10.9455 0 7.99996 0C5.05444 0 2.66663 2.38781 2.66663 5.33333C2.66663 8.27885 7.99996 16 7.99996 16ZM8.00009 8.53334C9.7674 8.53334 11.2001 7.10065 11.2001 5.33334C11.2001 3.56603 9.7674 2.13334 8.00009 2.13334C6.23278 2.13334 4.80009 3.56603 4.80009 5.33334C4.80009 7.10065 6.23278 8.53334 8.00009 8.53334Z" 
                            fill="#2F4858"
                        />
                    </svg>
                }
                <span className="label-regular colour-link">
                    {
                        overlay === false ?
                        "Properties list"
                        :
                        "Map view"
                    }
                </span>
            </div>
        </div>
    );
};

export default PropertyListButton;