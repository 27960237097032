import React from 'react';
import '../styles/EditNeighbourhoodGrade.css';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomSelect, colour } from '../styles/GlobalStyles';
import { recordEvent } from '../functions';

function EditNeighbourhoodGrade(props) {
    const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
    const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
    const changeNeighbourhoodGrade = props.changeNeighbourhoodGrade;
    const filtersModal = props.filtersModal;

    const grades = [
        {
            label: "A Grade",
            value: 8
        },
        {
            label: "B+ Grade",
            value: 7
        },
        {
            label: "B Grade",
            value: 6
        },
        {
            label: "B- Grade",
            value: 5
        },
        {
            label: "C+ Grade",
            value: 4
        },
        {
            label: "C Grade",
            value: 3
        },
        {
            label: "C- Grade",
            value: 2
        },
        {
            label: "D Grade",
            value: 1
        }
    ];

    const learnMore = () => {
        recordEvent("Learn More - Neighbourhood Grade", {
            page: "edit-buy-box"
        });

        window.open("https://coffeeclozers.notion.site/Coffee-Clozers-Maps-1541acce14b642ee8791b94c6c1cfccf", "_blank");
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
    };

    return (
        <div className="edit-neighbourhood-grade-outer-container">
            <div className="edit-neighbourhood-grade-inner-container">
                {
                    filtersModal === false ?
                    <div className="edit-neighbourhood-grade-title-container margin-large">
                        <h3 className="heading-small-semibold colour-quaternary">
                            Location requirements
                        </h3>
                    </div>
                    :
                    null
                }
                <span className="body-semibold colour-primary block-text margin-x-small">
                    Neighborhood grade
                </span>
                <div className="edit-neighbourhood-row-container margin-medium">
                    <div className="edit-neighbourhood-grade-element">
                        <Box>
                            <FormControl fullWidth>
                                <Select
                                    labelId="max-select-label-neighbourhood"
                                    id="max-neighbourhood-select"
                                    value={neighbourhoodGradeMax}
                                    label=""
                                    onChange={(event) => changeNeighbourhoodGrade(event.target.value, 1)}
                                    input={<CustomSelect />}
                                >
                                    {
                                        grades.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={item.value}
                                                style={styles.menuItem}
                                                disabled={neighbourhoodGradeMin >= item.value ? true : false}
                                            >
                                                {item.label}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <span className="body-regular colour-quaternary margin-sides">
                        -
                    </span>
                    <div className="edit-neighbourhood-grade-element">
                        <Box>
                            <FormControl fullWidth>
                                <Select
                                    labelId="min-select-label-neighbourhood"
                                    id="min-neighbourhood-select"
                                    value={neighbourhoodGradeMin}
                                    label=""
                                    onChange={(event) => changeNeighbourhoodGrade(event.target.value, 0)}
                                    input={<CustomSelect />}
                                >
                                    {
                                        grades.map((item, index) =>
                                            <MenuItem 
                                                key={index}
                                                value={item.value}
                                                style={styles.menuItem}
                                                disabled={neighbourhoodGradeMax <= item.value ? true : false}
                                            >
                                                {item.label}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
                {
                    filtersModal === false ?
                    <span className="body-regular colour-secondary">
                        For more information on neighborhood grades, please see our{' '}
                        <span 
                            className="underline colour-link"
                            onClick={() => learnMore()}
                        >
                            documentation
                        </span>
                    </span>
                    :
                    null
                }
            </div>
        </div>
    )
};

export default EditNeighbourhoodGrade;