import React, { useState, useEffect, useRef } from 'react';
import { FilterCityInput, ActionButton } from '../styles/GlobalStyles';
import { Exit, Search } from '../assets';
import { numberFormatter } from '../styles/GlobalStyles';
import { sendCityIDErrorEmail, cloudFunctionV2, getCountySearch, formatCityNames } from '../functions';
import { LoadingStatic, UpgradeCityMap } from './';
import { counties } from '../counties';

function PickPlanCounty(props) {
    // const disabled = props.disabled;
    const setDisabled = props.setDisabled;
    const confirmCounty = props.confirmCounty;
    // const goToCheckout = props.goToCheckout;
    // const setSelectedCity = props.setSelectedCity;
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [city, setCity] = useState("");
    const [options, setOptions] = useState([]);
    const [chosenCity, setChosenCity] = useState("");
    const [loading, setLoading] = useState(false);
    const [cityBoundaries, setCityBoundaries] = useState([]);
    const [countyListings, setCountyListings] = useState([]);
    const [countyAreas, setCountyAreas] = useState([]);
    const [centre, setCentre] = useState({ lat: 39.841502, lng: -101.309177 });
    const [zoom, setZoom] = useState(4);
    const [cityError, setCityError] = useState(false);
    const [cityDetails, setCityDetails] = useState(null);
    const [mapLoading, setMapLoading] = useState(false);
    const [uniqueCityList, setUniqueCityList] = useState([]);
    const mapRef = useRef(null);
    const mapRefContainer = useRef(null);

    useEffect(() => {
        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};

		return () => {
		  window.addEventListener('resize', handleWindowResize);
		};
    }, [innerWidth]);

    const changeCity = async(val) => {
        setCity(val);

        const lowerCaseVal = val.toLowerCase();
        const countiesClone = [...counties];
        const newArray = [];
        for (let index = 0; index < countiesClone.length; index++) {
            const element = countiesClone[index];
            
            const fullName = `${element.county} County ${element.stateFull}`.toLowerCase();
            const fullNameComma = `${element.county} County, ${element.stateFull}`.toLowerCase();
            const abbreviatedName = `${element.county} County ${element.state}`.toLowerCase();
            const abbreviatedNameComma = `${element.county} County, ${element.state}`.toLowerCase();
            const justState = element.state.toLowerCase();
            const mainCity = element.mainCity.city === "" ? false : true;

            if ( justState.includes(lowerCaseVal) || fullName.includes(lowerCaseVal) || fullNameComma.includes(lowerCaseVal) || abbreviatedName.includes(lowerCaseVal) || abbreviatedNameComma.includes(lowerCaseVal) ) {
                newArray.push(counties[index]);
            }
            else if ( mainCity === true ) {
                const mainCityFullName = `${element.mainCity.city} ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityFullNameComma = `${element.mainCity.city}, ${element.stateFull}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedName = `${element.mainCity.city} ${element.state}`.replace(".", "").toLowerCase();
                const mainCityAbbreviatedNameComma = `${element.mainCity.city}, ${element.state}`.replace(".", "").toLowerCase();
                const noFullStopVal = lowerCaseVal.replace(".", "");

                if ( mainCityFullName.includes(noFullStopVal) || mainCityFullNameComma.includes(noFullStopVal) || mainCityAbbreviatedName.includes(noFullStopVal) || mainCityAbbreviatedNameComma.includes(noFullStopVal) ) {
                    newArray.push(counties[index]);
                }
            }
        };
        setOptions(newArray);
    };

    const onZoomChanged = () => {
        if ( mapRef.current !== null ) {
            setZoom(mapRef.current.getZoom());
        }
    };

    const resetCity = () => {
        changeCity("");
    };

    const scrollToMap = () => {
        if ( mapRefContainer !== null && innerWidth < 1000 && mapRefContainer.current !== null) {
            mapRefContainer.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };

    const selectCity = async(item) => {
        setLoading(true);
        setCountyAreas([]);
        setCountyListings([]);
        setCity("");
        setMapLoading(true);

        const boundariesURL = process.env.REACT_APP_BOUNDARIES_URL;
        const checkedState = item.state === "D.C." ? "DC" : item.state;
        const docCityName = "District of Columbia";

        const params = {
            city: item.state === "D.C." ? docCityName : item.county,
            state: checkedState,
            county: true,
            returnCityId: "True"
        };
        const fullCountyName = formatCityNames(item);
        setChosenCity(fullCountyName);
        const query = await cloudFunctionV2(boundariesURL, params);
        if ( query.status === 200 ) {
            setCentre(query.centre);
            setMapLoading(false);
            setCityBoundaries(query.data);
            if ( query.data.length > 0 ) {
                setZoom(8);
            }
        }

        const countyParams = {
            countyId: `CTY${item.code}`
        }
		const getCountyParams = await getCountySearch(countyParams);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "county",
			queryString: getCountyParams
		};
		const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getCityDetails.status === 200 ) {
            const body = getCityDetails.body;
            if ( body === "<countyId> does not exist. Enter valid <countyId>." ) {
                setDisabled(true);
                setCityError(true);

                const cityIdErrorPage = "Upgrade City Page";
                const apiResponse = `Error with citySearchLive query`;
                await sendCityIDErrorEmail(fullCountyName, params.state, cityIdErrorPage, apiResponse);
            }
            else {
                body.price = item.price;
                setCityError(false);
                
                if ( body.estTotalProperties !== null ) {
                    const newArray = [
                        {
                            label: "EST. TOTAL LISTINGS",
                            total: numberFormatter.format(Number(body.estTotalProperties))
                        }
                    ]
                    setCountyListings(newArray);
                }

                const newUniqueCityList = [];
                const msaCityList = [];
                const msaCityIds = [];
                for (let index = 0; index < body.msaCityList.length; index++) {
                    const element = body.msaCityList[index];
                    if ( element.city === null ) {
                        continue;
                    }

                    const newMSAObject = {
                        city: element.city,
                        state: element.state,
                        cityId: element.cityId
                    };
                    msaCityIds.push(element.cityId);
                    msaCityList.push(newMSAObject);

                    const cityIndex = newUniqueCityList.findIndex(e => e.city === element.city && e.state === element.state);
                    if ( cityIndex === -1 ) {
                        newUniqueCityList.push(element);
                    }
                };
                newUniqueCityList.sort((a, b) => a.city.localeCompare(b.city));
                body.msaCityIds = msaCityIds;
                body.metroCities = msaCityList;
                body.state = item.state;
                body.metroArea = true;
                setCityDetails(body);
                setUniqueCityList(newUniqueCityList);
                setDisabled(false);
            }
        }
        else {
            setDisabled(true);
            setCityError(true);

            const cityIdErrorPage = "Upgrade City Page";
            const apiResponse = `Error with citySearchLive query`;
            await sendCityIDErrorEmail(fullCountyName, params.state, cityIdErrorPage, apiResponse);
        }
        setLoading(false);
        scrollToMap();
    };

    const openWiki = () => {
        window.open("https://en.wikipedia.org/wiki/Principal_city", "_blank");
    };

    return (
        <div className="upgrade-city-body-container relative-container">
            <div className="pick-plan-county-left-container">
                <div className="pick-plan-input-container">
                    <div className="upgrade-city-inner-input-container relative-container margin-x-large">
                        <FilterCityInput
                            value={city}
                            type="text"
                            id="upgrade-city-input"
                            placeholder="Search for any county in the U.S..."
                            onChange={(text) => changeCity(text.target.value)}
                            disabled={false}
                        />
                        <img
                            src={city === "" ? Search : Exit}
                            className={city === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                            alt="Search"
                            onClick={() => city === "" ? null : resetCity()}
                        />
                        {
                            options.length > 0 && city !== "" ?
                                <div className="city-filtering-outer-container">
                                    {
                                        options.map((item, index) =>
                                            <div 
                                                className="city-filtering-element"
                                                key={index}
                                                onClick={() => selectCity(item)}
                                            >
                                                <span className="body-regular colour-primary">
                                                    {formatCityNames(item)}
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            :
                            null
                        }
                    </div>
                    <div className="upgrade-city-input-text-container">
                    {
                        chosenCity === "" ?
                        null
                        :
                        <div className="upgrade-city-details-container margin-medium">
                            <h3 className="heading-small-semibold colour-primary margin-large">
                                {chosenCity}
                            </h3>
                            {
                                loading === true ?
                                <div className="upgrade-city-loading-container">
                                    <LoadingStatic />
                                    <span className="body-semibold colour-primary block-text margin-top-small margin-x-small">
                                        Loading...
                                    </span>
                                    <span className="body-regular colour-secondary">
                                        Getting geography information
                                    </span>
                                </div>
                                :
                                cityError === true ?
                                <div className="upgrade-city-response-container">
                                    <span className="body-regular colour-error">
                                        We're afraid there was an error getting data for this county,
                                        please try another metro or contact us for more details
                                    </span>
                                </div>
                                :
                                <div className="upgrade-city-response-container">
                                    <div className="upgrade-city-listings-container">
                                        {
                                            countyListings.map((item, index) =>
                                                <div 
                                                    className="upgrade-city-listings-element"
                                                    key={index}
                                                >
                                                    <span className="label-regular-caps colour-secondary block-text margin-x-small">
                                                        {item.label}
                                                    </span>
                                                    <h3 className="heading-large-semibold colour-primary">
                                                        {item.total}
                                                    </h3>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="">
                                        <span className="label-regular colour-secondary text-italic">
                                            Property counts could be up to 1 week old
                                        </span>
                                    </div>
                                    <div className="upgrade-city-divider margin-tops">
                                    </div>
                                    <div className="upgrade-city-zips-container margin-medium">
                                        <span className="label-regular-caps colour-secondary block-text margin-x-small">
                                            CITIES
                                        </span>
                                        {
                                            cityDetails.msaCityList === undefined || cityDetails.msaCityList === null ?
                                            null
                                            :
                                            uniqueCityList.map((item, index) =>
                                                <div 
                                                    className="upgrade-city-category-zips-element margin-medium"
                                                    key={index}
                                                >
                                                    <span className="body-semibold colour-primary block-text margin-x-small">
                                                        {item.city}, {item.state}
                                                    </span>
                                                    {/* <div className="upgrade-city-zips-grid">
                                                        {
                                                            item.activeZipCodes !== undefined &&
                                                            item.activeZipCodes !== null &&
                                                            item.activeZipCodes.map((subItem, subIndex) =>
                                                                subIndex < (innerWidth < 1000 ? 4 : 5) ?
                                                                <div 
                                                                    className="upgrade-city-zips-element"
                                                                    key={subIndex}
                                                                >
                                                                    <span className="body-regular colour-primary">
                                                                        {subItem}
                                                                    </span>
                                                                </div>
                                                                :
                                                                null 
                                                            )
                                                        }
                                                    </div> */}
                                                    {/* {
                                                        item.activeZipCodes !== undefined &&
                                                        item.activeZipCodes !== null &&
                                                        item.activeZipCodes.length > (innerWidth < 1000 ? 4 : 5) ?
                                                        <Accordion 
                                                            expanded={index === zipAccordion ? true : false} 
                                                            onChange={() => zipAccordion === index ? setZipAccordion(null) : setZipAccordion(index)}
                                                            sx={{ boxShadow: 'none' }}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls={`panel-${index}-bh-content`}
                                                                id={`panel-${index}-bh-header`}
                                                            >
                                                                <span className={"body-regular colour-link underline block-text " + (zipAccordion === index ? "view-none" : "")}>
                                                                    Show all
                                                                </span>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <div className="upgrade-city-zips-grid">
                                                                    {
                                                                        item.activeZipCodes.map((subItem, subIndex) =>
                                                                            subIndex > (innerWidth < 1000 ? 3 : 4) ?
                                                                            <div 
                                                                                className="upgrade-city-zips-element"
                                                                                key={subIndex}
                                                                            >
                                                                                <span className="body-regular colour-primary">
                                                                                    {subItem}
                                                                                </span>
                                                                            </div>
                                                                            :
                                                                            null 
                                                                        )
                                                                    }
                                                                </div>
                                                                {
                                                                    zipAccordion === index ?
                                                                    <div
                                                                        onClick={() => setZipAccordion(null)}
                                                                    >
                                                                        <span className="body-regular colour-link underline block-text">
                                                                            Hide
                                                                        </span>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        :
                                                        null
                                                    } */}
                                                </div>
                                            )
                                        }
                                        <div className="margin-top-medium">
                                            <span className="body-regular colour-secondary text-italic">
                                                We only include <span className="underline cursor-pointer" onClick={() => openWiki()}>Principal Cities</span> within
                                                the Metropolitan area, with a population of at least 10,000
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                </div>
            </div>
            <div className="pick-plan-map-container">
                <UpgradeCityMap
                    cityBoundaries={cityBoundaries}
                    centre={centre}
                    zoom={zoom}
                    mapRef={mapRef}
                    onZoomChanged={onZoomChanged}
                    metroAreas={countyAreas}
                    upgradedMSA={true}
                    mapLoading={mapLoading}
                    mapRefContainer={mapRefContainer}
                />
                <div className="pick-plan-checkout-button-container margin-top-x-large">
                    <div className="pick-plan-checkout-button-element">
                        <ActionButton
                            disabled={cityDetails === null ? true : false}
                            onClick={() => confirmCounty(cityDetails)}
                        >
                            Confirm
                        </ActionButton>
                    </div>
                </div>
                {/* <div className="pick-plan-checkout-button-container margin-top-x-large">
                    {
                        cityDetails === null ?
                        <span className="body-regular colour-primary block-text margin-medium">
                            Subtotal --
                        </span>
                        :
                        <span className="body-regular colour-primary block-text margin-medium">
                            Subtotal <span className="body-semibold">{formatterLong.format(cityDetails.price).replace(".00", "")}</span> <span className="colour-secondary">/month</span>
                        </span>
                    }
                    <div className="pick-plan-checkout-button-element">
                        <ActionButton
                            disabled={disabled}
                            onClick={() => goToCheckout()}
                        >
                            Continue to checkout
                        </ActionButton>
                    </div>
                </div>
                <div className="margin-top-medium">
                    <span className="label-regular colour-secondary block-text text-align-right mobile-none">
                        Upon upgrade, it usually takes about 1 hour 
                        to upload the data for your county.
                    </span>
                </div> */}
            </div>
        </div>
    )
};

export default PickPlanCounty;