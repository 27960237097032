import React, { useState } from 'react';
import { UploadImage } from './';
import { ActionButton, FormInput } from '../styles/GlobalStyles';
import { checkUserId, getDocument, setData, updateUserEmail } from '../functions';
import { GoogleLogo } from '../assets';

function AccountSettings(props) {
    const accountInformation = props.accountInformation;
    const setAccountInformation = props.setAccountInformation;
    const uploadedImage = props.uploadedImage;
    const saveImageFile = props.saveImageFile;
    const userData = props.userData;
    const navigate = props.navigate;
    const state = props.state;
    const setLoading = props.setLoading;
    const emailChangeable = props.emailChangeable;
    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [email, setEmail] = useState(userData.email);
    const [emailError, setEmailError] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const editTrigger = (index) => {
        const newAccountInformation = accountInformation.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    edit: !item.edit
                };
            }
            return item;
        });
        setAccountInformation(newAccountInformation);
    };

    const onChangeFirstName = (value) => {
        setFirstName(value);
        if ( value === "" || lastName === "" || email === "" ) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    };

    const onChangeLastName = (value) => {
        setLastName(value);
        if ( value === "" || firstName === "" || email === "" ) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    };

    const onChangeEmail = (value) => {
        setEmail(value);
        if ( value === "" || firstName === "" || lastName === "" ) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    };

    const saveEdits = async(index) => {
        setDisabled(true);
        setLoading(true);
        const colRef = "Users";
        const user = await checkUserId();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const queryDocument = await getDocument(colRef, userId);
            if ( queryDocument.status === 200 ) {
                const newUserData = queryDocument.data.data;
                if ( index === 0 ) {
                    newUserData.firstName = firstName;
                    newUserData.lastName = lastName;
                }
                else if ( index === 1 ) {
                    newUserData.email = email;
                    const updateEmail = await updateUserEmail(email);
                    if ( updateEmail.status !== 200 ) {
                        let errorText = "";
                        if ( updateEmail.error.code === "auth/email-already-in-use" ) {
                            errorText = `${email} is already in use by another user`;
                        }
                        else if ( updateEmail.error.code === "auth/requires-recent-login" ) {
                            errorText = "Please sign out and sign back in to change your email";
                        }
                        else if ( updateEmail.error.code === "auth/invalid-email" ) {
                            errorText = "Please enter a valid email address";
                        }
                        setEmailError(errorText);
                        setLoading(false);
                        setDisabled(false);
                        return;
                    }
                    else {
                        setEmailError("");
                    }
                }
                await setData(colRef, userId, newUserData);

                const newAccountInformation = accountInformation.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            value: i === 0 ? `${firstName} ${lastName}` : email,
                            edit: false
                        };
                    }
                    return item;
                });
                setAccountInformation(newAccountInformation);
                setDisabled(false);
            }
        }
        else {
            setDisabled(false);
            navigate("/sign-up", {
                state: state
            });
        }
        setLoading(false);
    };

    return (
        <div className="profile-account-information-container">
            <div className="profile-inner-width-container">
                <div className="profile-subheading-container margin-x-large">
                    <h2 className="heading-small-semibold colour-quaternary">
                        Personal details
                    </h2>
                </div>
                <div className="profile-account-settings-profile-image-container margin-x-large">
                    <h3 className="body-semibold colour-primary margin-medium">
                        Profile photo
                    </h3>
                    <UploadImage
                        saveFile={saveImageFile}
                        uploadedImage={uploadedImage}
                        route="Profile Image"
                        subText={`${uploadedImage === "" ? "Add a" : "Change"} photo`}
                        showIcon={false}
                    />
                </div>
                <div className="profile-account-settings-body-container margin-large">
                    {
                        accountInformation.map((item, index) =>
                            <div 
                                className={"profile-element-container " + (index !== accountInformation.length - 1 ? "margin-x-large" : "")}
                                key={index}
                            >
                                <div className="profile-element-text-container">
                                    <h3 className="body-semibold colour-primary margin-x-small">
                                        {item.label}
                                    </h3>
                                    {
                                        index === 1 && emailChangeable === false ?
                                        <div className="button-row">
                                            <img
                                                src={GoogleLogo}
                                                className="profile-element-google-icon"
                                                alt="Google Logo"
                                            />
                                            <span className="body-regular colour-primary">
                                                {item.value}
                                            </span>
                                        </div>
                                        :
                                        item.edit === false ?
                                        <span className="body-regular colour-primary">
                                            {item.value}
                                        </span>
                                        :
                                        index === 0 ?
                                        <div className="profile-element-edit-row margin-medium">
                                            <div className="profile-element-edit-input-container subtext-margin-right-x-large">
                                                <FormInput
                                                    value={firstName}
                                                    type="text"
                                                    label="First name *"
                                                    id="first-name-input"
                                                    placeholder="First Name"
                                                    onChange={(text) => onChangeFirstName(text.target.value)}
                                                />
                                            </div>
                                            <div className="profile-element-edit-input-container">
                                                <FormInput
                                                    value={lastName}
                                                    type="text"
                                                    label="Last name *"
                                                    id="last-name-input"
                                                    placeholder="Last Name"
                                                    onChange={(text) => onChangeLastName(text.target.value)}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div className="profile-element-edit-row margin-medium">
                                            <div className="profile-element-full-edit-container">
                                                <FormInput
                                                    value={email}
                                                    type="text"
                                                    label="Email *"
                                                    id="email-input"
                                                    placeholder="Email"
                                                    onChange={(text) => onChangeEmail(text.target.value)}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        item.edit === false ?
                                        null
                                        :
                                        <div className="profile-account-settings-edit-save-container">
                                            <ActionButton
                                                disabled={disabled}
                                                onClick={() => saveEdits(index)}
                                            >
                                                Save
                                            </ActionButton>
                                        </div> 
                                    }
                                    {
                                        index === 1 && emailError !== "" ?
                                        <span className="body-regular colour-error block-text margin-top-small">
                                            {emailError}
                                        </span>
                                        :
                                        null
                                    }
                                </div>
                                {
                                    index === 1 && emailChangeable === false ?
                                    null
                                    :
                                    <div className="profile-element-edit-container">
                                        <span 
                                            className="body-regular-underline colour-link"
                                            onClick={() => editTrigger(index)}
                                        >
                                            {
                                                item.edit === true ? "Cancel" : "Edit"
                                            }
                                        </span>
                                    </div>
                                }
                            </div>	
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default AccountSettings;