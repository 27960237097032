import React from 'react';
import '../styles/OnboardingHover.css';
import { ChevronRight } from '../assets';
import { topPickText } from '../styles/GlobalStyles';

function OnboardingHoverTopPick({ setOnboardingStep, setOnboarding, userDetails }) {

	const complete = async() => {
		setOnboardingStep(2);
	};

	return (
		<div className="onboarding-hover-outer-container onboarding-hover-top-pick-outer-container">
			<div className="onboarding-hover-inner-container">
				<h3 className="onboarding-hover-title-text">
					🔥 Top Picks
				</h3>
				<p className="onboarding-hover-body-text">
					{topPickText}
				</p>
				<div className="onboarding-hover-button-container">
					<div
						className="onboarding-hover-back-element"
						onClick={() => setOnboardingStep(0)}
					>
						<img
							src={ChevronRight}
							className="onboarding-hover-button-chevron-back"
							alt="Back"
						/>
						<span className="onboarding-hover-button-back-text">
							Back
						</span>
					</div>
					<div 
						className="onboarding-hover-button-element"
						onClick={() => complete()}
					>
						<span className="onboarding-hover-button-text">
							Next
						</span>
						<img
							src={ChevronRight}
							className="onboarding-hover-button-chevron"
							alt="Next"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OnboardingHoverTopPick;