import React from 'react';
import '../styles/DeleteCardModal.css';
import { Amex, Visa, MasterCard, JCB, Discover } from '../assets/credit-cards';
import { ActionButton, WhiteActionButton } from '../styles/GlobalStyles';
import { NewExit } from '../assets';
import { recordEvent } from '../functions';

function DeleteCardModal(props) {
    const card = props.creditCard;
    const cardType = card.brand === "visa" ? Visa : card.brand === "mastercard" ? MasterCard: card.brand === "amex" ? Amex : card.brand === "jcb" ? JCB : card.brand === "discover" ? Discover : null;
    const deleteCard = props.deleteFunction;
    const setDeleteModal = props.setDeleteModal;

    const closeModal = () => {
        setDeleteModal(false);
        recordEvent("Close Delete Card Modal", {});
    };

    return (
        <div className="delete-card-modal-outer-container bg-colour-light">
            <div className="delete-card-modal-inner-container">
                <div className="delete-card-modal-exit-container">
                    <img
                        src={NewExit}
                        className="delete-card-modal-exit"
                        alt="Close modal"
                        onClick={() => closeModal()}
                    />
                </div>
                <h2 className="heading-large-semibold margin-large">
                    Are you sure?
                </h2>
                <span className="body-regular colour-primary block-text margin-large">
                    Are you sure you want to delete 
                    this card from your account?
                </span>
                <div className="delete-card-modal-body-container margin-x-x-large">
                    <div className="delete-card-modal-image-container">
                        <img
                            src={cardType}
                            className="credit-card-icon"
                            alt={card.brand}
                        />
                    </div>
                    <div className="delete-card-modal-text-container">
                        <h3 className="body-semibold colour-primary margin-x-small">
                            <span className="text-capitalise">
                                {card.brand}
                            </span>
                            {' '}ending in {card.last4}
                        </h3>
                        <span className="body-regular colour-primary block-text margin-x-small">
                            Expiration {card.exp_month < 10 ? "0" : null}{card.exp_month}/{card.exp_year}
                        </span>
                    </div>
                </div>
                <div className="delete-card-modal-buttons-container">
                    <div className="delete-card-modal-white-button">
                        <WhiteActionButton
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </WhiteActionButton>
                    </div>
                    <div className="delete-card-modal-action-button">
                        <ActionButton
                            onClick={() => deleteCard(card)}
                        >
                            Delete
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteCardModal;