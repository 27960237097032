import React from 'react';
import '../styles/OffMarketBadge.css';

function OffMarketBadge(props) {
    const emoji = props.emoji;
    const text = props.text;
    return (
        <div className="off-market-badge-container">
            <span className="off-market-badge-emoji">
                {emoji}
            </span>
            <span className="body-semibold colour-primary">
                {text}
            </span>
        </div>
    )
}

export default OffMarketBadge;