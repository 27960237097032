import React from 'react';
import '../styles/PropertyCardBadge.css';

function PropertyCardBadge(props) {
    const text = props.text;
    return (
        <div className="property-card-badge-outer-container">
            <div className="property-card-badge-container">
                <span className="label-semibold-caps colour-white">
                    {text}
                </span>
            </div>
        </div>
    )
};

export default PropertyCardBadge;