import React from 'react';
import '../styles/FixerUpperBadge.css';
import { BedroomBlack } from '../assets';
import { BadgeExplanation } from './';

function AddBedroomBadge() {
	return (
		<div className="fixer-upper-badge-outer-container">
			<img
				src={BedroomBlack}
				className="fixer-upper-badge-icon"
				alt="Fixer Upper"
			/>
			<span className="top-pick-badge-text">
				Add Bedroom
			</span>
			<BadgeExplanation
				title="Add Bedroom"
				description="There may be sufficient square footage in this property to add an extra bedroom."
			/>
		</div>
	)
}

export default AddBedroomBadge;