import React from 'react';
import '../styles/DefaultTooltip.css';
import { Beak, ChangeDot } from '../assets';

function DefaultTooltip(props) {
    const title = props.title;
    const description = props.description;
    const changeIcon = props.changeIcon;

    return (
        <div className="default-tooltip-container">
            <img
                src={Beak}
                className="default-tooltip-beak"
                alt="Beak"
            />
            {
                title === "" ?
                null
                :
                <h5 className="body-semibold margin-x-small colour-primary">
                    {
                        changeIcon === true ?
                        <img
                            src={ChangeDot}
                            className="chip-change-icon"
                            alt="Changes Made"
                        />
                        :
                        null
                    }
                    {title}
                </h5>
            }
            <span className="body-regular colour-primary">
                {description}
            </span>
        </div>
    )
}

export default DefaultTooltip;