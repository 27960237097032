import React from 'react';
import '../styles/DiscountCode.css';
import { ActionButton, FormInput } from '../styles/GlobalStyles';

function DiscountCode(props) {
    const discountCode = props.discountCode;
    const changeDiscountCode = props.changeDiscountCode;
    const submitDiscountCode = props.submitDiscountCode;
    const disabled = props.disabled;
    const discountError = props.discountError;
    const discountSuccess = props.discountSuccess;
    const discountApplied = props.discountApplied;
    const removeDiscountCode = props.removeDiscountCode;

    return (
        <div className="discount-code-outer-container">
            <div className="discount-code-inner-container">
                <div className="discount-code-input-container">
                    <FormInput
                        value={discountCode}
                        type="text"
                        id={`discount-code-input`}
                        placeholder="Enter promo code"
                        label="Discount code"
                        inputProps={{
                        }}
                        onChange={(text) => changeDiscountCode(text.target.value)}
                    />
                </div>
                <div className="discount-code-button-container">
                    <ActionButton
                        disabled={disabled}
                        onClick={() => submitDiscountCode()}
                    >
                        Apply
                    </ActionButton>
                </div>
            </div>
            {
                discountError !== "" ?
                <div className="margin-top-medium">
                    <span className="body-regular colour-error">
                        {discountError}
                    </span>
                </div>
                :
                discountSuccess !== "" ?
                <div className="discount-code-chip-container margin-top-medium bg-colour-archive-status relative-container">
                    <span className="body-regular colour-primary block-text text-align-center subtext-margin-right">
                        {discountApplied.label}
                    </span>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 16 16" 
                        fill="none"
                        className="cursor-pointer"
                        onClick={() => removeDiscountCode()}
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M6.28952 4.38176C5.86315 3.90822 5.13362 3.86998 4.66008 4.29636C4.18654 4.72274 4.14831 5.45227 4.57468 5.92581L6.4474 8.00567L4.5849 10.0742C4.15852 10.5477 4.19675 11.2773 4.67029 11.7036C5.14383 12.13 5.87336 12.0918 6.29974 11.6182L7.99995 9.72995L9.70014 11.6182C10.1265 12.0917 10.856 12.13 11.3296 11.7036C11.8031 11.2772 11.8414 10.5477 11.415 10.0741L9.55251 8.00567L11.4252 5.92584C11.8516 5.4523 11.8133 4.72277 11.3398 4.2964C10.8663 3.87002 10.1367 3.90825 9.71035 4.38179L7.99995 6.28138L6.28952 4.38176Z" 
                            fill="#292621"
                        />
                    </svg>
                </div>
                :
                null
            }
        </div>
    )
};

export default DiscountCode;