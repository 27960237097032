import React from 'react';
import '../styles/UnitLayout.css';
import { Units, Bedrooms, Bathrooms, SQFootage } from '../assets';
import { numberFormatter } from '../styles/GlobalStyles';

function UnitLayout(props) {
    const item = props.item;
    const property = props.property;
    const index = props.index;

    const letters = [
        "A",
        "B",
        "C",
        "D"
    ];

    return (
        <div className={"unit-layout-outer-container margin-large " + (property.totalUniqueUnits !== 1 ? "unit-layout-outer-container-margin-right" : "")}>
            <h3 className="body-semibold colour-primary margin-medium">
                Layout {property.totalUniqueUnits !== 1 ? letters[index] : null}
            </h3>
            <div className="unit-layout-inner-row margin-medium">
                <img
                    src={Units}
                    className="unit-layout-image subtext-margin-right-large"
                    alt="Unit Layout"
                />
                <span className="colour-regular colour-primary">
                    {
                        property.totalUniqueUnits === 1 ?
                        `${property.units} Units`
                        :
                        `${property.uniqueUnitPropertyDetails[index].frequency} Unit${property.uniqueUnitPropertyDetails[index].frequency === 1 || property.uniqueUnitPropertyDetails[index].frequency === "1" ? "" : "s"}`
                    }
                </span>
            </div>
            <div className="unit-layout-inner-row">
                {
                    item.bedrooms === null || item.bedrooms === undefined ?
                    null
                    :
                    <div className="unit-layout-inner-row subtext-margin-right-x-large">
                        <img
                            src={Bedrooms}
                            className="unit-sub-layout-image subtext-margin-right-large"
                            alt="Beds icon"
                        />
                        <span className="body-regular colour-primary">
                            {item.bedrooms}
                        </span>
                    </div>
                }
                {
                    item.bathrooms === null || item.bathrooms === undefined ?
                    null
                    :
                    <div className="unit-layout-inner-row subtext-margin-right-x-large">
                        <img
                            src={Bathrooms}
                            className="unit-sub-layout-image subtext-margin-right-large"
                            alt="Baths icon"
                        />
                        <span className="body-regular colour-primary">
                            {item.bathrooms}
                        </span>
                    </div>
                }
                {
                    item.sqft === null || item.sqft === undefined ?
                    null
                    :
                    <div className="unit-layout-inner-row">
                        <img
                            src={SQFootage}
                            className="unit-sub-layout-image subtext-margin-right-large"
                            alt="Baths icon"
                        />
                        <span className="body-regular colour-primary">
                            {numberFormatter.format(item.sqft)}
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

export default UnitLayout;