import React, { useState } from 'react';
import '../styles/Modal.css';
import { ActionButton, WhiteActionButton, formatterCompact } from '../styles/GlobalStyles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Chip } from './';

function OfferModal(props) {
    const property = props.offerProperty;
    const closeModal = props.closeModal;
    const terms = props.offerPropertyTerms;
    const userData = props.userData;
    const [copied, setCopied] = useState(false);
    const realtorEmail = property.listedBy !== undefined && property.listedBy !== null && property.listedBy.email !== undefined && property.listedBy.email !== null ? property.listedBy.email : property.attributionInfo !== undefined && property.attributionInfo !== null && property.attributionInfo.agentEmail !== undefined && property.attributionInfo.agentEmail !== null ? property.attributionInfo.agentEmail : "";
    const realtorPhoneNumber = property.listedBy !== undefined && property.listedBy !== null && property.listedBy.phone !== undefined && property.listedBy.phone !== null && property.listedBy.phone !== "" ? `(${property.listedBy.phone.areacode}) ${property.listedBy.phone.prefix}-${property.listedBy.phone.number}` : property.attributionInfo !== undefined && property.attributionInfo !== null && property.attributionInfo.agentPhoneNumber !== undefined && property.attributionInfo.agentPhoneNumber !== null ? property.attributionInfo.agentPhoneNumber : "";
    const ownerName = property.ownerAttributes.ownerName === null ? "" : property.ownerAttributes.ownerName.split(" ");
    const realtorName = property.fsbo === true ? ownerName[0] : property.listedBy !== undefined && property.listedBy !== null && property.listedBy.displayName !== undefined && property.listedBy.displayName !== null && !property.listedBy.displayName.includes("broker") ? property.listedBy.displayName.replace(/ .*/,'') : property.attributionInfo !== undefined && property.attributionInfo !== null && property.attributionInfo.agentName !== undefined && property.attributionInfo.agentName !== null && property.attributionInfo.agentName !== "" ? property.attributionInfo.agentName.replace(/ .*/,'') : "{REALTOR'S NAME}";
    const [selected, setSelected] = useState(realtorEmail === "" ? 1 : 0);
    const options = [
        "Email",
        "SMS"
    ];

    const toSentenceCase = (str) => {
        if (!str) return str;
        const lowerCaseStr = str.trim().toLowerCase();
        return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
    }

    const openClient = () => {
        const subject = `Inquiry - ${property.address.streetAddress}, ${property.address.city}`;
        const body = `Hi ${toSentenceCase(realtorName)}, \n\nI wanted to inquire about your listing at ${property.address.streetAddress}, ${property.address.city}, ${property.address.state}. \n\nIs your client open to a seller finance deal? I'm open to a down payment to make sure your broker fees are covered. I'd be open to around ${formatterCompact.format(terms.sellerCompensation)} (including interest). \n\nI attached a PDF with additional information on my offer. \n\nBest, \n${userData === null ? "{YOUR NAME}" : userData.firstName}`;
        const mailtoLink = `mailto:${realtorEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    const copyToClipboard = () => {
        setCopied(true);
    };

    const openSMS = () => {
        window.location.href=`sms:${realtorPhoneNumber}&body=Hey ${toSentenceCase(realtorName)}, I wanted to inquire about your listing at ${property.address.streetAddress}, ${property.address.city}, ${property.address.state}. Is your client open to a seller finance deal?`
    };

    const changeChip = (index) => {
        setSelected(index);
    };

    return (
        <div className="offer-modal-outer-container bg-colour-light">
            <div className="modal-inner-container">
                <div className="modal-body-container">
                    <div className="modal-title-container margin-medium">
                        <h2 className="heading-large-semibold colour-primary margin-x-small">
                            Make an offer
                        </h2>
                        <span className="body-regular colour-secondary">
                            Creative finance is a numbers game - the more offers you make the better!
                        </span>
                    </div>
                    <div className="button-row margin-large">
                        {
                            options.map((item, index) =>
                                <Chip
                                    label={item}
                                    func={changeChip}
                                    index={index}
                                    selected={selected}
                                    recommended={false}
                                    hover={null}
                                    leave={null}
                                    disabled={false}
                                    key={index}
                                />
                            )
                        }
                    </div>
                    {
                        selected === 0 ?
                        <div className="modal-description-container margin-medium">
                            <h3 className="body-semibold colour-primary margin-x-small">
                                <span className="text-regular">Hi</span> {' '}
                                {toSentenceCase(realtorName)},
                            </h3>
                            <span className="body-regular colour-primary block-text margin-medium">
                                I wanted to inquire about your listing at{' '}
                                {property.address.streetAddress}, {property.address.city}, {property.address.state}.
                                <br/>
                                <br/>
                                Is your client open to a seller finance deal? I'm open to a down payment to make sure your broker fees are covered.
                                I'd be open to around <span className="body-semibold underline">{formatterCompact.format(terms.sellerCompensation)}</span> (including interest).
                            </span>
                            <span className="body-regular colour-primary block-text margin-medium">
                                I attached a PDF with additional information on my offer.
                            </span>
                            <span className="body-regular colour-primary block-text">
                                Best,
                                <br/>
                                {
                                    userData === null ?
                                    "{YOUR NAME}"
                                    :
                                    userData.firstName
                                }
                            </span>
                        </div>
                        :
                        <div className="modal-description-container margin-medium">
                            <h3 className="body-semibold colour-primary margin-x-small">
                                <span className="text-regular">Hey</span> {' '}
                                {toSentenceCase(realtorName)},
                            </h3>
                            <span className="body-regular colour-primary block-text margin-medium">
                                I wanted to inquire about your listing at{' '}
                                {property.address.streetAddress}, {property.address.city}, {property.address.state}.
                                <br/>
                                <br/>
                                Is your client open to a seller finance deal? I'm open to a down payment to make sure your broker fees are covered.
                                I'd be open to around <span className="body-semibold underline">{formatterCompact.format(terms.sellerCompensation)}</span> (including interest).
                            </span>
                            <span className="body-regular colour-primary block-text margin-medium">
                                Let me know when you're available to chat.
                            </span>
                            <span className="body-regular colour-primary block-text">
                                {
                                    userData === null ?
                                    "{YOUR NAME}"
                                    :
                                    userData.firstName
                                }
                            </span>
                        </div>
                    }
                    {
                        (realtorEmail === "" && selected === 0) || (realtorPhoneNumber === "" && selected === 1) ?
                        null
                        :
                        <CopyToClipboard 
                            text={selected === 0 ? realtorEmail : realtorPhoneNumber}
                            onCopy={() => copyToClipboard()}
                        >
                            <div className="text-button">
                                <span className="body-regular text-link">
                                    {
                                        copied === false ?
                                        `Copy ${property.fsbo === true ? "owner" : "realtor"} ${selected === 0 ? "email" : "phone number"}`
                                        :
                                        `${selected === 0 ? "Email" : "Phone number"} copied`
                                    }
                                </span>
                            </div>
                        </CopyToClipboard>
                    }
                </div>
                <div className="modal-buttons-container">
                    <div className="modal-close-button-container">
                        <WhiteActionButton
                            onClick={() => closeModal()}
                        >
                            Close
                        </WhiteActionButton>
                    </div>
                    {
                        realtorPhoneNumber === "" && selected === 1 ?
                        <div className="">
                            <span className="body-regular colour-error">
                                No phone number found 😔
                            </span>
                        </div>
                        :
                        <div className="modal-save-button-container">
                            <ActionButton
                                onClick={() => selected === 0 ? openClient() : openSMS()}
                            >
                                Open {selected === 0 ? "Email Client" : "Messages"}
                            </ActionButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default OfferModal;