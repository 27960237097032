import React from 'react';
import '../styles/EmergingAreaModal.css';
import { recordEvent } from '../functions';
import { ActionButton } from '../styles/GlobalStyles';

function EmergingAreaModal(props) {
    const setEmergingAreaModal = props.setEmergingAreaModal;
    const setMapSidePanelOpen = props.setMapSidePanelOpen;
    const setChosenTract = props.setChosenTract;
    const emergingAreaTract = props.emergingAreaTract;
    const setEmergingAreaTract = props.setEmergingAreaTract;
    const setClickedTract = props.setClickedTract;

    const closeModal = () => {
        recordEvent("Emerging Area Modal Close", {});
        setEmergingAreaModal(false);
    };

    const cta = () => {
        setMapSidePanelOpen(true);
        setChosenTract(emergingAreaTract);
        setClickedTract(emergingAreaTract.region.tractId);
        setEmergingAreaTract(null);
        recordEvent("Emerging Area Modal CTA", {});
        setEmergingAreaModal(false);
    };

    return (
        <div className="emerging-area-modal-outer-container">
            <div className="emerging-area-modal-inner-container relative-container">
                <div className="emerging-area-modal-exit-container">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="22" 
                        height="24" 
                        viewBox="0 0 22 24" 
                        fill="none"
                        onClick={() => closeModal()}
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M2.25732 0.419021C1.78932 -0.100741 0.988587 -0.142706 0.468826 0.32529C-0.0509363 0.793286 -0.092901 1.59402 0.375095 2.11378L9.29582 12.0213L0.41338 21.8862C-0.0546153 22.406 -0.0126517 23.2067 0.50711 23.6747C1.02687 24.1427 1.82761 24.1007 2.2956 23.581L10.9999 13.9138L19.7042 23.5809C20.1722 24.1007 20.9729 24.1427 21.4927 23.6747C22.0125 23.2067 22.0544 22.4059 21.5864 21.8862L12.704 12.0213L21.6247 2.11382C22.0927 1.59406 22.0507 0.793322 21.531 0.325326C21.0112 -0.142669 20.2105 -0.100704 19.7425 0.419057L10.9999 10.1287L2.25732 0.419021Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div>
                <div className="margin-x-large">
                    <h2 className="heading-large-semibold colour-primary">
                        🌱 Emerging area detected
                    </h2>
                </div>
                <div className="margin-x-large">
                    <p className="body-regular colour-primary">
                        You’ve found an emerging neighborhood! This neighborhood is a liferaft in a sea of other 
                        neighborhoods that has seen recent, rapid growth. It could be an indicator that this 
                        area is up and coming! Continue to see this neighborhood’s stats.
                    </p>
                </div>
                <div className="emerging-area-modal-buttons-container">
                    <div className="emerging-areas-modal-buttons-inner-container">
                        <div 
                            className="text-button"
                            onClick={() => closeModal()}
                        >
                            <span className="body-regular colour-link">
                                Cancel
                            </span>
                        </div>
                        <div className="emerging-area-modal-action-button-container">
                            <ActionButton
                                onClick={() => cta()}
                            >
                                See neighborhood stats
                            </ActionButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EmergingAreaModal;