import React from 'react';
import './Buttons.css';

function PrimaryButton(props) {
    const text = props.text;
    const cta = props.cta;
    const disabled = props.disabled;
    const leftIcon = props.leftIcon;
    const leftIconClass = props.leftIconClass;
    const rightIcon = props.rightIcon;
    const rightIconClass = props.rightIconClass;
    const alt = props.alt;
    const size = props.size;
    const sizeClass = size === "medium" ? "size-medium" : "size-small";
    const textSize = size === "medium" ? "body-semibold" : "label-semibold";

    return (
        <div 
            className={(sizeClass) + " primary-button button-radius-normal cursor-pointer " + (disabled === true ? "button-disabled" : "")}
            onClick={disabled === true ? null : cta}
        >
            <div className={`${sizeClass}-padding`}>
                {
                    leftIcon === null ?
                    null
                    :
                    <img
                        src={leftIcon}
                        className={leftIconClass}
                        alt={alt}
                    />
                }
                <span className={(textSize) + " colour-white block-text text-align-center"}>
                    {text}
                </span>
                {
                    rightIcon === null ?
                    null
                    :
                    <img
                        src={rightIcon}
                        className={rightIconClass}
                        alt={alt}
                    />
                }
            </div>
        </div>
    )
};

export default PrimaryButton;